import React from "react";

import { Popup as SemanticPopup } from "semantic-ui-react";

export interface TooltipWrapperProps {
  disabled?: boolean;
  tooltip?: string;
  children: React.ReactNode;
}

/**
 * A simple wrapper around semantic ui's {@link SemanticPopup} component, providing behaviour that is expected of
 * hover-over tooltips. Note that components wrapped by this are wrapped in an extra div when the tooltip is enabled.
 * This is required because not all components would always render the popup. For example, disabled buttons would fail
 * to trigger the tooltip popup (this seems to be intentional behaviour from semantic ui).
 */
export const TooltipWrapper = ({ disabled, tooltip, children }: TooltipWrapperProps) => {
  if (disabled || tooltip === undefined || tooltip === "") {
    return <>{children}</>;
  }

  return (
    <SemanticPopup
      on="hover"
      wide="very"
      disabled={disabled || tooltip === undefined || tooltip === ""}
      mouseEnterDelay={500}
      trigger={<div>{children}</div>}
    >
      {tooltip ?? ""}
    </SemanticPopup>
  );
};
