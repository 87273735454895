/**
 * Returns a locale string (like "en-US") from the given `language`.
 * @param language
 */
export const languageToLocale = (language: string): string => {
  switch (language) {
    case "fi":
      return "fi-FI";
    default:
      return "en-US";
  }
};
