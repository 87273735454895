import { TranslateFunction } from "./config";
import { useTranslation } from "react-i18next";

/**
 * A utility wrapper around react-18next's useTranslation-hook. Adds some arguments common to the entire application,
 * and unwraps the translate function from the original response, allowing a view to more conveniently use multiple
 * different translate functions (such as one for its own translations and one for common messages).
 */
export const useTranslator = (keyPrefix: string): TranslateFunction => {
  const { t } = useTranslation("translations", { keyPrefix });
  return t;
};
