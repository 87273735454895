import { CSSProperties } from "react";
import styles from "./resolveClassName.module.css";
import { AbakusCOLORS, colorMapping } from "./colors";
import { resolveSize, Size } from "./sizing";

export type ConditionalStyle<T> = [T | undefined, (value: T) => [string, string]];

/**
 * A type that components can extend from to include all props in the {@link StandardStylingProps} to their own props,
 * excluding the internal values intended for the component itself.
 */
export type StandardComponentProps = Omit<StandardStylingProps, "conditionalStyles" | "conditionalClassNames">;

/**
 * A common super-interface for styling props available for many components in this library.
 */
export interface StandardStylingProps {
  /**
   * A convenience property for applying a value to all other margin-properties. A side-specific property will overwrite
   * this for its own side, but not any others.
   */
  margin?: number | Size;
  /**
   * Adds a left-margin to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  marginLeft?: number | Size;
  /**
   * Adds a right-margin to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  marginRight?: number | Size;
  /**
   * Adds a top-margin to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  marginTop?: number | Size;
  /**
   * Adds a bottom-margin to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  marginBottom?: number | Size;
  /**
   * A convenience property for applying a value to all other padding-properties. A side-specific property will
   * overwrite this for its own side, but not any others.
   */
  padding?: number | Size;
  /**
   * Adds a left-padding to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  paddingLeft?: number | Size;
  /**
   * Adds a right-padding to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  paddingRight?: number | Size;
  /**
   * Adds a top-padding to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  paddingTop?: number | Size;
  /**
   * Adds a bottom-padding to this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   */
  paddingBottom?: number | Size;
  /**
   * If true, then makes this element grow in size to fill up any remaining space in its (flex-based) container. If a
   * number, then the behaviour is identical but is used as a weight for distributing the remaining space amongst this
   * and other elements with a grow property.
   */
  grow?: boolean | number;
  /**
   * The minimum width of this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   * If no value is supplied but a {@link grow} is given, this is automatically set to 0.
   */
  minWidth?: number | Size;
  /**
   * The maximum height of this component. The actual value is obtained by calling {@link resolveSize} with the given
   * value.
   */
  maxHeight?: number | Size;
  /**
   * The flex-basis of this element. The actual value is obtained by calling {@link resolveSize} with the given value.
   * If no value is supplied but a {@link grow} is given, this is automatically set to 0.
   */
  basis?: number;
  /**
   * If true, this element is given a semi-transparent look.
   */
  faded?: boolean;
  /**
   * Additional CSS classes can be supplied using this argument.
   */
  className?: string;
  /**
   * Additional CSS inline styles can be supplied using this argument.
   */
  style?: CSSProperties;
  height?: number | "fill" | Size;
  width?: number | "fill" | Size;
  alignSelf?: "center" | "start" | "end" | "stretch";
  /**
   * An internal variable intended for components extending this prop set. Used to pass classnames that are conditional
   * based on an attribute of the element.
   */
  conditionalClassNames?: [boolean | undefined, string][];
  /**
   * An internal variable intended for components extending this prop set. Used to pass style attributes that are
   * conditional based on some attributes of the element.
   */
  conditionalStyles?: ConditionalStyle<any>[];
  /**
   * The background color used. Setting this to something will also set the text color to white unless the `color`
   * property is also set.
   */
  backgroundColor?: AbakusCOLORS;
  /**
   * The text color used. Setting this overrides the white text color that `backgroundColor` sets.
   */
  color?: AbakusCOLORS;
  /**
   * If true, then the fade-out animation has no transition. Mostly useful for disabling the transition related to
   * transitions altogether for components not requiring transitions.
   */
  instantFadeTransition?: boolean;
}

/**
 * Resolves the given {@link StandardStylingProps} and returns as { className, style } object, containing a classname
 * attribute and CSS-styles that can be applied directly to a component.
 */
export const resolveStandardStyling = ({
  margin,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  height,
  width,
  grow,
  faded,
  className,
  style,
  conditionalClassNames,
  alignSelf,
  basis,
  minWidth,
  maxHeight,
  conditionalStyles,
  backgroundColor,
  instantFadeTransition,
  color,
}: StandardStylingProps): { className: string; style: CSSProperties } => {
  // Currently this only supports string based properties. The typescript compiler seems to get confused when attempting
  // to do something more complex here..
  let resultStyle: { [key: string]: string } = (style as { [key: string]: string }) ?? {};

  const addStyle = (key: string, value: string) => {
    if (!(key in resultStyle)) {
      resultStyle[key] = value;
    }
  };

  if (marginLeft !== undefined || margin !== undefined) {
    addStyle("marginLeft", resolveSize(marginLeft ?? margin ?? 0));
  }
  if (marginRight !== undefined || margin !== undefined) {
    addStyle("marginRight", resolveSize(marginRight ?? margin ?? 0));
  }
  if (marginTop !== undefined || margin !== undefined) {
    addStyle("marginTop", resolveSize(marginTop ?? margin ?? 0));
  }
  if (marginBottom !== undefined || margin !== undefined) {
    addStyle("marginBottom", resolveSize(marginBottom ?? margin ?? 0));
  }

  if (paddingLeft !== undefined || padding !== undefined) {
    addStyle("paddingLeft", resolveSize(paddingLeft ?? padding ?? 0));
  }
  if (paddingRight !== undefined || padding !== undefined) {
    addStyle("paddingRight", resolveSize(paddingRight ?? padding ?? 0));
  }
  if (paddingTop !== undefined || padding !== undefined) {
    addStyle("paddingTop", resolveSize(paddingTop ?? padding ?? 0));
  }
  if (paddingBottom !== undefined || padding !== undefined) {
    addStyle("paddingBottom", resolveSize(paddingBottom ?? padding ?? 0));
  }
  if (alignSelf !== undefined) {
    addStyle("alignSelf", alignSelf);
  }
  if (color !== undefined) {
    addStyle("color", `${colorMapping[color]}`);
  }
  if (backgroundColor !== undefined) {
    addStyle("backgroundColor", `${colorMapping[backgroundColor]}`);
    addStyle("color", "#FFFFFF");
  }

  let hasGrow = false;

  if (typeof grow === "boolean" && grow) {
    addStyle("flexGrow", "1");
    hasGrow = true;
  } else if (typeof grow === "number") {
    addStyle("flexGrow", grow.toString());
    hasGrow = true;
  }

  if (hasGrow || basis !== undefined) {
    addStyle("flexBasis", resolveSize(basis ?? 0));
  }
  // Flex items have a default min-width of auto. This can cause grow-type items to take up more space than whats
  // available. We'll fix this by automatically giving an item a min-width of 0 if it is a grow-item.
  if (hasGrow || minWidth !== undefined) {
    addStyle("minWidth", resolveSize(minWidth ?? 0));
  }
  if (maxHeight !== undefined) {
    addStyle("maxHeight", resolveSize(maxHeight ?? 0));
  }

  if (height !== undefined) {
    addStyle("height", resolveSize(height));
  }
  if (width !== undefined) {
    addStyle("width", resolveSize(width));
  }

  if (conditionalStyles) {
    conditionalStyles.forEach((conditionalStyle) => {
      if (conditionalStyle[0] !== undefined) {
        const [key, value] = conditionalStyle[1](conditionalStyle[0]);
        addStyle(key, value);
      }
    });
  }

  return {
    className: resolveConditionalClasses(
      (conditionalClassNames ?? ([] as [boolean | undefined, string?][]))
        .concat(className ? [[true, className]] : [])
        .concat([
          [faded, styles.faded],
          // We need a class for the 'not faded' even though it is the default behaviour so that the transition anim
          // correctly applies when the value is changed
          [!faded, styles.notFaded],
          [!instantFadeTransition, styles.fadeTransition],
        ])
    ),
    style: resultStyle,
  };
};

/**
 * A utility function for component implementations. Takes the given list of [boolean, string] pairs, and produces a
 * concatenated list of all second elements from the list where the first value is true and the second value is defined,
 * separated by spaces.
 */
export const resolveConditionalClasses = (classes: [boolean | undefined, string?][]): string => {
  return classes
    .map(([condition, posClass]) => {
      if (condition === true) {
        return posClass;
      } else {
        return undefined;
      }
    })
    .filter((className) => className !== undefined)
    .join(" ");
};
