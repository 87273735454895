import { LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { isArray } from "lodash";

/**
 * Maps the given object into a string array, flattening each nested property/object into a single string value. Each
 * leaf-property in the object (that is, a property of the object or some child-object nested in it that is not itself
 * an object) results in one row in the result array, where the first value on the row is the property-path for the
 * property, and the second value is the property's value serialized as string. For example, for a simple to-string
 * serializer and object `{ prop: { otherProp: 123 } }`, this function would return an array with a single row of
 * `["prop.otherProp", "123"]`.
 *
 * Properties with an undefined value are ignored.
 *
 * @see unflattenToObject for converting an array cretaed by this function back into a object.
 */
export const flattenToStringArray = (
  object: any,
  serializer: (path: string, value: any) => string,
  keyPrefix?: string
): [string, string][] => {
  keyPrefix = keyPrefix ?? "";
  return Object.entries(object)
    .filter(([, value]) => (!isObject(value) && value !== "" && value !== undefined) || isObject(value))
    .flatMap(([key, value]) => {
      if (value === undefined) {
        return [];
      } else if (isArray(value) || !isObject(value)) {
        const path = keyPrefix + key;
        return [[path, serializer(path, value)]];
      } else {
        return flattenToStringArray(value, serializer, keyPrefix + key + ".");
      }
    });
};

/**
 * Returns an array based on the given `object` so that each defined property of the object is converted into one or
 * more rows in the array. Each row of the returned array is an array itself with two elements: property key and a
 * string representation of a primitive value. If a property's value is an object itself (as determined by
 * {@link isObject} function), that property will be recursively processed into one or more rows in the array so that
 * each row's key is the "path" to the object's primitive value, and the value is the value of the primitive value in
 * the "path".
 * @param object
 * @param keyPrefix
 */
export const objectToArray = (object: any, keyPrefix?: string): [string, any][] => {
  keyPrefix = keyPrefix ?? "";
  return Object.entries(object)
    .filter(([, value]) => (!isObject(value) && value !== "" && value !== undefined) || isObject(value))
    .flatMap(([key, value]) => {
      if (isArray(value)) {
        return value.map((element) => [keyPrefix + key, element]);
      } else if (isObject(value)) {
        return objectToArray(value, keyPrefix + key + ".");
      } else {
        return [[keyPrefix + key, value]] as any;
      }
    });
};

/**
 * The reverse pair of {@link flattenToStringArray}. Takes an array produced by the function and a deserializer-function
 * converting strings back to their original values, and outputs an object constructed from the arrays values. With
 * lossless serializer & deserializer implementations, the object produced from this function should be identical to the
 * object giben to {@link flattenToStringArray}, with the exception of undefined values being removed in the process.
 */
export const unflattenToObject = (
  array: [string, string][],
  deserializer: (path: string, value: string) => any
): any => {
  let object: { [key: string]: any } = {};

  array.forEach(([path, value]) => {
    let parts = path.split(".");
    let propertyName = parts.pop()!!;

    let currentObject = object;
    parts.forEach((part) => {
      if (!(part in currentObject)) {
        currentObject[part] = {};
      }
      currentObject = currentObject[part];
    });

    currentObject[propertyName] = deserializer(path, value);
  });

  return object;
};

/**
 * Returns `true` if the given `object` is an object. `LocalDate`, `LocalDateTime`, and `LocalTime` are not considered
 * objects by this method.
 * @param object
 */
const isObject = (object?: any): boolean => {
  const type = typeof object;
  return (
    (type === "function" || type === "object") &&
    !!object &&
    !(object instanceof LocalDateTime || object instanceof LocalDate || object instanceof LocalTime)
  );
};
