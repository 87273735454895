import { LocalDateTime } from "@js-joda/core";
import { ProxyTarget, ProxyTargetAccessToken, ProxyTargetLicenseKey, ProxyTargetQuery } from "../model/ProxyTarget";
import {
  PagedResults,
  RequestGenerator,
  RequestGeneratorProps,
} from "../../ts-commons/commons-react-hooks/request/models";
import { makeRequest, makeURL } from "../../ts-commons/commons-react-hooks/request/makeRequest";

export const listProxyTargets: RequestGenerator<ProxyTargetQuery, PagedResults<ProxyTarget>, void> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: makeURL("proxy_targets", params),
      jsonReviver: proxyTargetReviver,
      props,
    });
  };
};

/**
 * Queries all proxy targets from the server. This is a different and more effective call than just performing
 * a [listProxyTargets]-call with unbounded page size, since it uses the in-memory cache on the server side.
 */
export const listAllProxyTargets: RequestGenerator<{}, ProxyTarget[], void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: makeURL("proxy_targets/all", params),
      jsonReviver: proxyTargetReviver,
      props,
    });
  };
};

export const getProxyTargetById: RequestGenerator<{ id: number }, ProxyTarget, void> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: `proxy_targets/${params.id}`,
      jsonReviver: proxyTargetReviver,
      props,
    });
  };
};

export const getProxyTargetToken: RequestGenerator<{ id: number }, ProxyTargetAccessToken, void> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: `proxy_targets/${params.id}/token`,
      props,
    });
  };
};

export const createProxyTarget: RequestGenerator<
  { target: ProxyTarget },
  StoreProxyTargetSuccess,
  StoreProxyTargetError
> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "POST",
      body: params.target,
      url: "proxy_targets",
      jsonReviver: proxyTargetReviver,
      props,
    });
  };
};

export const updateProxyTarget: RequestGenerator<
  { id: number; target: ProxyTarget },
  StoreProxyTargetSuccess,
  StoreProxyTargetError
> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "PUT",
      body: params.target,
      url: `proxy_targets/${params.id}`,
      jsonReviver: proxyTargetReviver,
      props,
    });
  };
};

export const updateProxyTargetLicenseKey: RequestGenerator<
  { id: number; data: ProxyTargetLicenseKey },
  UpdateProxyTargetLicenseKeySuccess,
  UpdateProxyTargetLicenseKeyError
> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "PUT",
      body: params.data,
      url: `proxy_targets/${params.id}/license_key`,
      props,
    });
  };
};

export const deleteProxyTarget: RequestGenerator<{ id: number }, void, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "DELETE",
      url: `proxy_targets/${params.id}`,
      props,
    });
  };
};

export const undeleteProxyTarget: RequestGenerator<{ id: number }, void, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "PATCH",
      url: `proxy_targets/undelete/${params.id}`,
      props,
    });
  };
};

const proxyTargetReviver = (key: string, value: any): any => {
  switch (key) {
    case "created":
    case "deleted":
      if (typeof value === "string") {
        return LocalDateTime.parse(value);
      } else {
        return undefined;
      }
    default:
      return value;
  }
};

export type StoreProxyTargetErrorCode =
  | "NAME_RESERVED"
  | "INVALID_NAME"
  | "INVALID_LOGIN_PATH"
  | "INVALID_TARGET_URL"
  | "INVALID_LICENSE_KEY"
  | "INVALID_ACCESS_CONTROL_LIST";

export interface StoreProxyTargetError {
  type: "error";
  errorDescription: string;
  errorCode: StoreProxyTargetErrorCode;
}

export interface StoreProxyTargetSuccess {
  type: "success";
  storedTarget: ProxyTarget;
}

export interface UpdateProxyTargetLicenseKeySuccess {
  type: "success";
}

export interface UpdateProxyTargetLicenseKeyError {
  type: "error";
}
