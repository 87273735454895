import { Input as SemanticInput, SemanticICONS } from "semantic-ui-react";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import styles from "./Input.module.css";

export interface InputProps extends StandardComponentProps {
  /**
   * The id attribute for this element.
   */
  id?: string;
  /**
   * The type-attribute for the underlying <input> element.
   */
  type?: string;
  /**
   * The current value of this input.
   */
  value?: string;
  /**
   * The callback invoked when the current value in this input changes.
   */
  onChange?: (newValue: string) => void;
  /**
   * If `true`, then this input is made read only.
   */
  readOnly?: boolean;
  /**
   * If `true`, then this input is set into "view mode". In view mode, the input is read only and does not display its
   * borders.
   */
  viewMode?: boolean;
  /**
   * If `true`, then this input becomes read only and faded.
   */
  disabled?: boolean;
  /**
   * If `true`, then this input will be highlighted in red to indicate an error.
   */
  error?: boolean;
  /**
   * An icon to be displayed inside this input.
   */
  icon?: SemanticICONS;
  /**
   * A placeholder string that is shown in the inptu field if there is no user-inputted value.
   */
  placeholder?: string;
}

export const Input = ({
  id,
  icon,
  type,
  value,
  onChange,
  readOnly: readOnlyProp,
  error,
  viewMode,
  disabled,
  placeholder,
  ...standardProps
}: InputProps) => {
  const { className, style } = resolveStandardStyling({
    ...standardProps,
    conditionalClassNames: [
      [viewMode, styles.viewMode],
      [error, styles.hasError],
    ],
  });

  let readOnly = viewMode ?? readOnlyProp;

  return (
    <SemanticInput
      id={id}
      type={type}
      value={value}
      readOnly={readOnly}
      icon={icon}
      disabled={disabled}
      placeholder={placeholder}
      onChange={
        onChange
          ? (event) => {
              onChange(event.target.value);
            }
          : undefined
      }
      className={className}
      style={style}
    />
  );
};
