import React, { PropsWithChildren, useContext, useMemo, useState } from "react";
import { RequestFunction, RequestGenerator, RequestGeneratorAdditionalProps } from "./models";

export type GenerateRequest = <P, T, E>(
  generator: RequestGenerator<P, T, E>,
  additionalProps: RequestGeneratorAdditionalProps | undefined
) => RequestFunction<P, T, E>;

/**
 * The properties-definition for the {@link RequestContext} provider-component. Requires specifying a single function
 * for generating requests out of their definitions. This needs to be always defined by the application code as it needs
 * to specify things like the base URL for the backend, and add handling for specific error codes (such as the server
 * returning HTTP Unauthorized).
 */
export interface RequestContextProps {
  generateRequest: GenerateRequest;
  /**
   * Should this context be memoized so that it's only changed when one the dependencies listed in `memoizationDeps` is
   * changed. This can be useful if there are a component that uses this context, and it appears multiple times on a
   * page. This can cause performance issues which memoizing this context may solve.
   */
  memoized?: boolean;
  /**
   * When setting `memoized` as `true`, list the `generateRequest`s dependencies so this context is changed when needed.
   */
  memoizationDeps?: any[];
}

const RequestContextInternal = React.createContext<Partial<RequestContextProps>>({});

/**
 * A React-context provider component that is required in order to use the {@link useRequest}-hook, and any other custom
 * hook that utilises this hook. See {@link RequestContextProps} and {@link GenerateRequest} for how this context needs
 * to be parameterized.
 */
export const RequestContext = (props: PropsWithChildren<RequestContextProps>) => {
  if (props.memoized === true) {
    return <MemoizedRequestContext {...props} />;
  } else {
    return <RequestContextInternal.Provider value={props}>{props.children}</RequestContextInternal.Provider>;
  }
};

// Disable hook dependency lint warning because we want to pass the dependencies ourselves.
/* eslint-disable react-hooks/exhaustive-deps */
const MemoizedRequestContext = (props: PropsWithChildren<RequestContextProps>) => {
  const memoizedProps = useMemo(() => props, props.memoizationDeps ?? []);
  return <RequestContextInternal.Provider value={memoizedProps}>{props.children}</RequestContextInternal.Provider>;
};
/* eslint-enable */

/**
 * Accepts a request definition in the form of a {@link RequestGenerator}, and outputs a callable function that can be
 * used to perform the request. Invoking the returned function makes a network call matching with the definition, and
 * returns a Promise that will eventually resolve either into the request's response or an error.
 */
export const useRequest: <P, T, E>(
  generator: RequestGenerator<P, T, E>,
  additionalProps?: RequestGeneratorAdditionalProps
) => RequestFunction<P, T, E> = (generator, additionalProps) => {
  let { generateRequest } = useContext(RequestContextInternal);
  if (!generateRequest) {
    throw Error(
      "No request generator found. The component using this hook must be a child (direct or indirect) of " +
        "a RequestContextProvider-element."
    );
  }
  return generateRequest(generator, additionalProps);
};

/**
 * Like {@link useRequest}, but returns a pair where the first value is the request returned by {@link useRequest},
 * and the second value is a boolean flag that is true if and only if there is currently a request in-flight created by
 * calling the request-function returned from this hook.
 */
export const useRequestWithState = <P, T, E>(
  generator: RequestGenerator<P, T, E>
): [RequestFunction<P, T, E>, boolean] => {
  const [loadCounter, setLoadCounter] = useState(0);
  const request = useRequest(generator);

  const performRequest: RequestFunction<P, T, E> = (params: P) => {
    setLoadCounter((counter) => counter + 1);
    return request(params).then(
      (success) => {
        setLoadCounter((counter) => counter - 1);
        return success;
      },
      (error) => {
        setLoadCounter((counter) => counter - 1);
        return error;
      }
    );
  };

  return [performRequest, loadCounter > 0];
};
