import { Modal as SemanticModal } from "semantic-ui-react";
import { SemanticShorthandItem } from "semantic-ui-react/dist/commonjs/generic";
import * as React from "react";
import { ModalDimmerProps } from "semantic-ui-react/dist/commonjs/modules/Modal/ModalDimmer";

export interface ModalProps {
  /** An element type to render as (string or function). */
  as?: any;

  /** A Modal can reduce its complexity */
  basic?: boolean;

  /** A modal can be vertically centered in the viewport. */
  centered?: boolean;

  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;

  /** Icon. */
  closeIcon?: any;

  /** Whether or not the Modal should close when the dimmer is clicked. */
  closeOnDimmerClick?: boolean;

  /** Whether or not the Modal should close when the document is clicked. */
  closeOnDocumentClick?: boolean;

  /** Initial value of open. */
  defaultOpen?: boolean;

  /** A modal can appear in a dimmer. */
  dimmer?: true | "blurring" | "inverted" | SemanticShorthandItem<ModalDimmerProps>;

  /** Event pool namespace that is used to handle component events */
  eventPool?: string;

  /** The node where the modal should mount. Defaults to document.body. */
  mountNode?: any;

  /**
   * Action onClick handler when using shorthand `actions`.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onActionClick?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void;

  /**
   * Called when a close event happens.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onClose?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void;

  /**
   * Called when the portal is mounted on the DOM.
   *
   * @param {null}
   * @param {object} data - All props.
   */
  onMount?: (nothing: null, data: ModalProps) => void;

  /**
   * Called when an open event happens.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onOpen?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void;

  /**
   * Called when the portal is unmounted from the DOM.
   *
   * @param {null}
   * @param {object} data - All props.
   */
  onUnmount?: (nothing: null, data: ModalProps) => void;

  /** Controls whether or not the Modal is displayed. */
  open?: boolean;

  /** A modal can vary in size. */
  size?: "mini" | "tiny" | "small" | "large" | "fullscreen";

  /** Custom styles. */
  style?: React.CSSProperties;

  /** Element to be rendered in-place where the portal is defined. */
  trigger?: React.ReactNode;
}

/**
 * A component that displays a popup and blocks interactions with the main view of a page. Currently, this is mostly
 * the same as Semantic UI modal but actions are put inside a `Flex` so the actions part of the modal work better with
 * our own buttons.
 *
 * Use {@link ModalActions}, {@link ModalContent}, {@link ModalDescription}, {@link ModalDimmer},
 * and {@link ModalHeader} as children of this component.
 */
export const Modal = (props: ModalProps) => {
  return (
    <SemanticModal
      as={props.as}
      basic={props.basic}
      centered={props.centered}
      className={props.className}
      closeIcon={props.closeIcon}
      closeOnDimmerClick={props.closeOnDimmerClick}
      closeOnDocumentClick={props.closeOnDocumentClick}
      defaultOpen={props.defaultOpen}
      dimmer={props.dimmer}
      mountNode={props.mountNode}
      onActionClick={props.onActionClick}
      onClose={props.onClose}
      onMount={props.onMount}
      onOpen={props.onOpen}
      onUnmount={props.onUnmount}
      open={props.open}
      size={props.size}
      style={props.style}
      trigger={props.trigger}
      children={props.children}
    />
  );
};
