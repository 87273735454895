import { useState } from "react";
import { useCommonsTranslate } from "../message-context/MessageContext";
import { Modal } from "./Modal";
import { ModalHeader } from "./ModalHeader";
import { ModalContent } from "./ModalContent";
import { ModalActions } from "./ModalActions";
import { Button } from "../button/Button";

export interface UseCancelModalReturn {
  /**
   * The Modal-dialog component that is displayed to the user. This should be added somewhere to the JSX tree in the
   * component that uses the modal.
   */
  CancelModal: () => JSX.Element;
  /**
   * Whether the modal dialog is currently open or not.
   */
  cancelModalOpen: boolean;
  /**
   * A setter that can be used to toggle the open-ness of the dialog outside. Use this to show the confirmation to the
   * user.
   */
  setCancelModalOpen: (open: boolean) => void;
}

/**
 * This utility hook can be used to define a common cancel-confirmation modal dialog in a view page, that is a dialog
 * the user needs to interact with if they wish the cancel editing an entity after they've made changes
 * @param onConfirm the callback function that is invoked if the user confirms the cancellation.
 * @return a {@link UseCancelModalReturn} object, containing a React component that should be used to render the
 *         component, as well as information and a setter for the open-ness of the dialog.
 */
export const useCancelModal = (onConfirm: () => void): UseCancelModalReturn => {
  const t = useCommonsTranslate("modal");
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const CancelModal = () => (
    <Modal open={cancelModalOpen}>
      <ModalHeader>{t("cancelConfirmation.header")}</ModalHeader>
      <ModalContent>{t("cancelConfirmation.message")}</ModalContent>
      <ModalActions gap={1}>
        <Button text={t("cancelConfirmation.cancelButton")} onClick={() => setCancelModalOpen(false)} />
        <Button
          negative
          text={t("cancelConfirmation.confirmButton")}
          onClick={() => {
            onConfirm();
            setCancelModalOpen(false);
          }}
        />
      </ModalActions>
    </Modal>
  );

  return {
    CancelModal,
    cancelModalOpen,
    setCancelModalOpen,
  };
};
