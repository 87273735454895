import { Icon, Menu, Popup, SemanticICONS } from "semantic-ui-react";
import { Link, To } from "react-router-dom";
import { Button, ButtonProps } from "./Button";

/**
 * A link-type item in a {@link PopupMenuButton}. Clicking on this item will navigate the user to the given
 * {@link to}-address.
 */
export interface PopupMenuLinkItem {
  to: To;
  icon: SemanticICONS;
  label: string;
  disabled?: boolean;
}

/**
 * A button-type item in a {@link PopupMenuButton}. Clicking on this item will trigger the {@link onClick} callback.
 */
export interface PopupMenuButtonItem {
  onClick: () => void;
  icon: SemanticICONS;
  label: string;
  disabled?: boolean;
}

export type PopupMenuButtonProps = Omit<ButtonProps, "onClick"> & {
  popupPosition?:
    | "top left"
    | "top right"
    | "bottom right"
    | "bottom left"
    | "right center"
    | "left center"
    | "top center"
    | "bottom center";
  items: (PopupMenuButtonItem | PopupMenuLinkItem)[];
  /**
   * Make the popup this button opens controlled: the popup is open when this is `true` and closed when this is `false`.
   * If this is defined, `setPopupOpen` must also be defined. That function will be called when this button is clicked
   * and a negation of the current value of this property will be passed as an argument.
   */
  popupOpen?: boolean;
  /**
   * This function is called when this button's popup is controlled (i.e. `popupOpen` is defined) and the button is
   * clicked or the user clicks outside the popup. Negation of the current `popupOpen` will be passed as a parameter.
   */
  setPopupOpen?: (popupOpen: boolean) => void;
};

/**
 * This component acts as a customized button that, instead of performing an action when clicked, will show a popup menu
 * of items to select. It is recommended that for selections, a dropdown is used instead of this, as this component does
 * not offer any built-in way for an "active" selection.
 */
export const PopupMenuButton = ({
  popupPosition,
  items,
  basic,
  popupOpen,
  setPopupOpen,
  ...buttonProps
}: PopupMenuButtonProps) => {
  let propsWithDefaults: ButtonProps = buttonProps;

  let hasIcon = ["icon", "leftIcon", "rightIcon"].some((prop) => prop in buttonProps);
  if (!hasIcon) {
    propsWithDefaults = {
      ...propsWithDefaults,
      icon: "ellipsis vertical",
    };
  }

  if (!("basic" in propsWithDefaults)) {
    propsWithDefaults = {
      ...propsWithDefaults,
      basic: true,
    };
  }

  return (
    <Popup
      position={popupPosition}
      open={popupOpen}
      onClose={setPopupOpen !== undefined ? () => setPopupOpen(false) : undefined}
      trigger={
        <Button
          onClick={popupOpen !== undefined ? () => setPopupOpen!!(!popupOpen) : undefined}
          {...propsWithDefaults}
        />
      }
    >
      <Menu vertical>
        {items.map((item, index) => (
          <Menu.Item
            key={`menuItem_${index}`}
            as={"to" in item ? Link : undefined}
            to={"to" in item ? item.to : undefined}
            onClick={(event) => {
              if ("onClick" in item) {
                item.onClick();
              }
              event.stopPropagation();
            }}
            disabled={item.disabled}
          >
            <Icon name={item.icon} />
            {item.label}
          </Menu.Item>
        ))}
      </Menu>
    </Popup>
  );
};
