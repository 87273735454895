/**
 * A type representing a size in the browser.
 */
export type Size =
  | {
      /**
       * The unit of the size.
       * - "px" for pixels,
       * - "em" for ephemeral units, that is the current font size,
       * - "pct" for percentage units,
       * - "std" for the 'standard' way of resolving spacing, and
       * - "fr" for "weighted" rows or columns in a grid layout only.
       */
      unit: "px" | "em" | "std" | "fr" | "pct";
      value: number;
    }
  | {
      /**
       * Sizes of type "raw" are raw CSS expressions that are given as is to the style attribute.
       */
      unit: "raw";
      value: string;
    };

/**
 * Resolves the given size as a string that can be passed to a CSS property such as `margin` or `width`. If the `size`
 * is `"auto"`, returns `"auto"`. A size with a unit of "std" is treated as a "standard size
 * multiplier" of the given size-value. The idea with these is that components can specify their relative sizes for
 * margins, paddings etc. using this function, and it is then easy to add theming support to make the UI more
 * dense or spacious.
 */
export const resolveSize = (size: number | Size | "fill" | "auto"): string => {
  if (size === "fill") {
    return "100%";
  } else if (typeof size === "number") {
    return resolveSize({ value: size, unit: "std" });
  } else if (size === "auto") {
    return "auto";
  } else {
    if (size.unit === "px") {
      return `${size.value}px`;
    } else if (size.unit === "em") {
      return `${size.value}em`;
    } else if (size.unit === "fr") {
      return `${size.value}fr`;
    } else if (size.unit === "pct") {
      return `${size.value}%`;
    } else if (size.unit === "raw") {
      return size.value;
    } else {
      return `${size.value * 0.5}em`;
    }
  }
};
