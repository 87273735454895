import React, { useContext } from "react";
import { FormProps } from "semantic-ui-react";
import { FormInput, FormInputProps } from "./FormInput";
import { FormErrors, FormErrorsProps } from "./FormErrors";

import { FormRadioButtons, FormRadioButtonsProps } from "./FormRadioButtons";
import { FormDropdown, FormDropdownProps } from "./FormDropdown";
import { FormTextArea, FormTextAreaProps } from "./FormTextArea";
import { FormMultiInput, FormMultiInputProps } from "./FormMultiInput";
import { FormCustomField, FormCustomFieldProps } from "./FormCustomField";
import { isPartialEqual } from "../../commons-ts-utils/utils/equalityUtils";
import { FormDateTimeInput, FormDateTimeInputProps } from "./FormDateTimeInput";
import { FormToggle, FormToggleProps } from "./FormToggle";
import { Flex } from "../flex/Flex";

export type FormMode = "view" | "edit" | "submit";

export interface CustomFormProps<Fields, ErrorCode> {
  className?: string;
  state: Fields;
  setState: (state: Fields) => void;
  errors: [ErrorCode, string][];
  mode: FormMode;
  children: React.ReactNode;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>, data: FormProps) => void;
}

export type FormFieldComponent<Props> = (props: Props) => JSX.Element;

export interface FormComponent<Fields, Error> extends React.FunctionComponent<CustomFormProps<Fields, Error>> {
  Input: FormFieldComponent<FormInputProps<Fields, Error>>;
  RadioButtons: FormFieldComponent<FormRadioButtonsProps<Fields, Error>>;
  Errors: FormFieldComponent<FormErrorsProps<Error>>;
  Dropdown: FormFieldComponent<FormDropdownProps<Fields, Error>>;
  TextArea: FormFieldComponent<FormTextAreaProps<Fields, Error>>;
  MultiInput: FormFieldComponent<FormMultiInputProps<Fields, Error>>;
  DateTimeInput: FormFieldComponent<FormDateTimeInputProps<Fields, Error>>;
  CustomField: FormFieldComponent<FormCustomFieldProps<Error>>;
  Toggle: FormFieldComponent<FormToggleProps<Fields, Error>>;
}

export interface TypedFormContextData {
  state: any;
  mode: FormMode;
  onInputChange: (name: string, value: any) => void;
  errors: [any, string][];
}

export const FormContext = React.createContext<TypedFormContextData>({
  state: {},
  mode: "view",
  onInputChange: () => {},
  errors: [],
});

/**
 * This function initializes a new form-component to use. This component uses two type parameters to offer compile-time
 * validations for its use. These type variables are `Fields`, a type variable for the data type containing the form's
 * data, and `ErrorCode`, the datatype for an enum/string-union for any possible error that is shown in the form.
 * The form is created using this function instead of being used as a normal react component due to normal use requiring
 * the use of the type parameters everywhere, which would make use of the form awkward. An example of how the typed
 * form can be used is shown below.
 *
 * <pre>
 *   interface FormFields {
 *     foo: string;
 *     bar: number;
 *   };
 *   type FormErrorCode = "invalidFoo" | "invalidBar";
 *
 *   const Form = createTypedForm<FormFields, FormErrorCode>();
 *
 *   // ...
 *
 *   return (
 *     <Form ...props>
 *       <Form.Input ...inputProps>
 *       <Form.Errors ...errorProps>
 *     </Form>
 *   )
 * </pre>
 *
 * The props for various components are omitted for simplicity. However, the example showcases how the function is to be
 * used: the function is invoked to create a new component with the type parameterse baked into it, and the fields of
 * the forms are created using `Form.<sub-component>`.
 */
export function createTypedForm<Fields, Error>(): FormComponent<Fields, Error> {
  let component = ((props: CustomFormProps<Fields, Error>) => {
    const handleInputChange = (name: string, value: any) => {
      props.setState({
        ...props.state,
        [name]: value,
      });
    };

    return (
      <Flex column grow className={`${props.className ?? ""}`} gap={2} alignItems="stretch">
        <FormContext.Provider
          value={{
            state: props.state,
            mode: props.mode,
            onInputChange: handleInputChange,
            errors: props.errors,
          }}
        >
          {props.children}
        </FormContext.Provider>
      </Flex>
    );
  }) as FormComponent<Fields, Error>;

  /**
   * A typesafe alias for the component defined in `FormInput.tsx`. See said file for more documentation.
   */
  component.Input = (props: FormInputProps<Fields, Error>) => {
    return <FormInput<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormRadioButtons.tsx`. See said file for more documentation.
   */
  component.RadioButtons = (props: FormRadioButtonsProps<Fields, Error>) => {
    return <FormRadioButtons<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormErrors.tsx`. See said file for more documentation.
   */
  component.Errors = (props: FormErrorsProps<Error>) => {
    return <FormErrors<Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormDropdown.tsx`. See said file for more documentation.
   */
  component.Dropdown = (props: FormDropdownProps<Fields, Error>) => {
    return <FormDropdown<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormTextArea.tsx`. See said file for more documentation.
   */
  component.TextArea = (props: FormTextAreaProps<Fields, Error>) => {
    return <FormTextArea<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormMultiInput.tsx`. See said file for more documentation.
   */
  component.MultiInput = (props: FormMultiInputProps<Fields, Error>) => {
    return <FormMultiInput<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormCustomField.tsx`. See said file for more documentation.
   */
  component.CustomField = (props: FormCustomFieldProps<Error>) => {
    return <FormCustomField<Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormDateTimeInput.tsx`. See said file for more documentation.
   */
  component.DateTimeInput = (props: FormDateTimeInputProps<Fields, Error>) => {
    return <FormDateTimeInput<Fields, Error> {...props} />;
  };

  /**
   * A typesafe alias for the component defined in `FormToggle.tsx`. See said file for more documentation.
   */
  component.Toggle = (props: FormToggleProps<Fields, Error>) => {
    return <FormToggle<Fields, Error> {...props} />;
  };

  return component;
}

/**
 * Returns all the 'relevant' errors from the current form context. An error is relevant if the relevantErrors-parameter
 * list contains an error that is a sub-object of an error object in the forms errors.
 */
export const useRelevantErrors = <Error,>(relatedErrors: Error[]): [Error, string][] => {
  const formContext = useContext(FormContext);

  const relevantErrors = formContext.errors.filter(([error, _]) => {
    return relatedErrors.some((relatedError) => {
      if (typeof relatedError === "string" || typeof relatedError === "number") {
        return relatedError === error;
      } else {
        return isPartialEqual(error as {}, relatedError as {});
      }
    });
  });

  let errorStringIndices: { [msg: string]: number } = {};
  relevantErrors.forEach(([_, message], index) => {
    if (!(message in errorStringIndices)) {
      errorStringIndices[message] = index;
    }
  });

  // Return only the first error-instance of each unique string
  return relevantErrors.filter(([_, message], index) => errorStringIndices[message] === index);
};
