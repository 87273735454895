import React from "react";
import { DateTimeFormatter } from "@js-joda/core";
import { Link } from "react-router-dom";
import { useTranslator } from "../../../../i18n/hooks";
import {
  ProxyTarget,
  ProxyTargetQuery,
  proxyTargetQueryDeserializer,
  proxyTargetQuerySerializer,
  ProxyTargetStatus,
} from "../../../../api/model/ProxyTarget";
import { listProxyTargets } from "../../../../api/services/proxyTargets";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { useSubmittableSearch } from "../../../../ts-commons/commons-react-hooks/request/useSubmittableSearch";
import {
  ResultsTable,
  ResultsContainer,
} from "../../../../ts-commons/commons-react-components/results-table/ResultsTable";
import {
  createSingleChoiceFilter,
  createTextInputFilter,
} from "../../../../ts-commons/commons-react-components/results-table/tableFilters";
import { PagedResults } from "../../../../ts-commons/commons-react-hooks/request/models";
import { useDebounce } from "../../../../ts-commons/commons-react-hooks/util/useDebounce";
import { StandardBrowsePageHeader } from "../../../../ts-commons/commons-react-components/results-table/StandardBrowsePageHeader";

const BrowseTargetsScreen = () => {
  const t = useTranslator("targets");
  const ct = useTranslator("common");

  // Use a submittable search so a user can set all search parameters before a search is done. Otherwise, this might
  // make a too demanding query
  const request = useRequest(listProxyTargets);
  const [searchState, updateSearchState, performSearch, searchResults, resetSearch] = useSubmittableSearch<
    ProxyTargetQuery,
    ProxyTargetQuery,
    PagedResults<ProxyTarget>
  >({
    defaultState: {
      page: 1,
      pageSize: 50,
      deleted: false,
      sort: "created desc, id desc",
    },
    searchFunction: request,
    stateSerializer: proxyTargetQuerySerializer,
    stateDeserializer: proxyTargetQueryDeserializer,
  });
  const triggerDebounce = useDebounce(performSearch, searchState);
  const submitSearch = (searchState: Partial<ProxyTargetQuery>) => {
    updateSearchState(searchState);
    triggerDebounce();
  };

  return (
    <ResultsContainer>
      <StandardBrowsePageHeader
        type="title"
        refresh={triggerDebounce}
        additionalFilters={[]}
        resetAllFilters={() => resetSearch("sort", "page")}
        title={t("browse.title")}
      />
      <ResultsTable<ProxyTarget>
        noResultsInfo={ct("search.noResultsInfo")}
        items={searchResults.result?.pageElements}
        pagination={searchResults.result}
        columns={[
          {
            header: t("browse.columnCreated"),
            width: { type: "weighted", value: 2 },
            renderCell: (target) => target.created?.format(DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")),
            ascending: "created, id",
            descending: "created desc, id desc",
          },
          {
            header: t("browse.columnName"),
            width: { type: "weighted", value: 2 },
            renderCell: (target) => target.name,
            ascending: "name, created, id",
            descending: "name desc, created desc, id desc",
            filter: createTextInputFilter({
              currentValue: searchState.name,
              update: (newValue) => updateSearchState({ name: newValue }),
            }),
          },
          {
            header: t("browse.columnTargetUrl"),
            width: { type: "weighted", value: 5 },
            renderCell: (target) => target.targetUrl,
            ascending: "targetUrl, created, id",
            descending: "targetUrl desc, created desc, id desc",
            filter: createTextInputFilter({
              currentValue: searchState.targetUrl,
              update: (newValue) => updateSearchState({ targetUrl: newValue }),
            }),
          },
          {
            header: t("browse.columnStatus"),
            width: { type: "weighted", value: 2 },
            renderCell: (target) => t("status." + target.status),
            ascending: "status, created, id",
            descending: "status desc, created desc, id desc",
            filter: createSingleChoiceFilter({
              type: "list",
              options: ["", "ACTIVE", "TRANSIENT", "BLOCKED"],
              defaultChoice: "",
              choiceToLabel: (choice) => {
                if (choice === "") {
                  return t("status.any");
                } else {
                  return t(`status.${choice}`);
                }
              },
              currentChoice: searchState.status ?? "",
              updateChoice: (choice) => {
                if (choice === "") {
                  updateSearchState({ status: undefined });
                } else {
                  updateSearchState({ status: choice as ProxyTargetStatus });
                }
              },
            }),
          },
          {
            header: "",
            width: { type: "static-em", value: 5 },
            textAlign: "right",
            renderCell: (target) => {
              return <Link to={`/targets/${target.id}`}>{ct("browse.viewLink")}</Link>;
            },
          },
        ]}
        onSort={(newSort) => {
          submitSearch({ sort: newSort });
        }}
        currentSort={searchState.sort}
        onPageChanged={(newActivePage) => {
          submitSearch({ page: newActivePage });
        }}
      />
    </ResultsContainer>
  );
};

export default BrowseTargetsScreen;
