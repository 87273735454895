import { DateTimeFormatter, LocalDateTime } from "@js-joda/core";

/**
 * Serializes the given array into a string: each element is mapped by the given mapper, wrapped by quotes and separated
 * with commas.
 *
 * @see deserializeArray
 */
export const serializeArray = <T>(value: T[], mapper?: (element: T) => string): string => {
  let stringArray = value.map((element) => {
    if (mapper) {
      return mapper(element);
    } else {
      return `${element}`;
    }
  });
  let json = JSON.stringify(stringArray);

  // Drop [ ] as they are redundant
  return json.slice(1, json.length - 1);
};

/**
 * Deserializes the given string into an array of type `T` objects. The string is first unwrapped into individual
 * string-elements, and then each such element is mapped into an instance of `T` using the given mapper.
 *
 * @see serializeArray
 */
export const deserializeArray = <T>(value: string, mapper?: (element: any) => T): string => {
  let stringArray = JSON.parse(`[${value}]`);
  return stringArray.map((element: any) => {
    if (mapper) {
      return mapper(element);
    } else {
      return element as T;
    }
  });
};

/**
 * Serializes the given {@link LocalDateTime} value into a string that can be deserialized using
 * {@link deserializeDateTime}. This function accepts a value of type {@link any} for convenience reasons: the intended
 * use case for this function is to be used inside a query serializer, which does not have access to the type of the
 * values it serializes. If the given parameter is not of correct type, this function will throw an error.
 */
export const serializeDateTime = (value: any): string => {
  return (value as LocalDateTime).format(DateTimeFormatter.ISO_LOCAL_DATE_TIME);
};

/**
 * The counter-pair for {@link serializeDateTime}. Deserializes a string value created using the aforementioned function
 * back into a {@link LocalDateTime} object.
 */
export const deserializeDateTime = (value: string): LocalDateTime => {
  return LocalDateTime.parse(value, DateTimeFormatter.ISO_LOCAL_DATE_TIME);
};

export const serializeBoolean = (value: any): string => {
  return value.toString();
};

export const deserializeBoolean = (value: string): boolean => {
  return value === "true";
};
