import { FocusEventHandler, forwardRef, LegacyRef, MouseEventHandler, ReactNode } from "react";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import styles from "./Flex.module.css";
import { resolveSize } from "../../commons-ts-utils/utils/sizing";

export interface FlexProps extends StandardComponentProps {
  id?: string;
  /**
   * Child elements rendered inside this element.
   */
  children?: ReactNode;
  /**
   * If true, the flex-direction is set to column, that is along the y-axis.
   */
  column?: boolean;
  /**
   * If true, the flex-direction of this element is reversed and becomes either row-reverse or
   * column-reverse, depending on the value of {@link column}.
   */
  reverse?: boolean;
  /**
   * If true, this element is styled interactable, gaining a highlight when hovered and a different
   * highlight when clicked. The stylign is currently CSS based and thus does not notice if hovering
   * over an interactable child element.
   */
  interactable?: boolean;
  /**
   * A callback invoked when this element is clicked. Clicks to interactable elements inside this
   * element (like buttons) do not fall through, that is do not invoke this callback.
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * The align-items flex-property for this container. That is, positioning of the items on
   * the cross-axis.
   */
  alignItems?: "center" | "start" | "end" | "stretch" | "baseline";
  /**
   * The justify-content flex-proprety for the element. This is, positioning of the items on the
   * main axes.
   */
  justifyContent?: "center" | "start" | "end" | "space-between";
  /**
   * A convenience proprety for applying both {@link rowGap} and {@link columnGap} for this element.
   */
  gap?: number;
  /**
   * The spacing between elements on different rows inside this container. The unit is "standard
   * spacing units", that is values obtained from {@link getSize}.
   */
  rowGap?: number;
  /**
   * The spacing between elements on the same row inside this container. The unit is "standard
   * spacing units", that is values obtained from {@link getSize}.
   */
  columnGap?: number;
  /**
   * If true, then the elements automatically wrap inside this container, that is flow to the next
   * row when there isn't sufficient space.
   */
  wrap?: boolean;
  /**
   * Whether the flex instance should be focusable or not (i.e. can it be cycled to using the tab key). If unspecified,
   * then defaults to `false`, unless {@link interactable} is `true`.
   */
  focusable?: boolean;
  /**
   * Invoked when this component obtains focus. Should generally only be used when this is also {@link focusable}.
   */
  onFocus?: FocusEventHandler;
  /**
   * Invoked when this component loses focus. Should generally only be used when this is also {@link focusable}.
   */
  onBlur?: FocusEventHandler;
  /**
   * A dictionary of custom attributes that are passed to the underlying <div> element.
   */
  customAttributes?: { [name: string]: string };
}

/**
 * This component is a `<div>` with display: flex and some additional added behavior available.
 * See {@link FlexProps} for attributes related specifically to this component, and
 * {@link StandardComponentProps} for standard-props available to this and many other components.
 */
export const Flex = forwardRef(
  (
    {
      id,
      column,
      reverse,
      children,
      gap,
      rowGap,
      columnGap,
      focusable,
      interactable,
      onClick,
      alignItems,
      justifyContent,
      wrap,
      onFocus,
      onBlur,
      customAttributes,
      ...other
    }: FlexProps,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const { className, style } = resolveStandardStyling({
      ...other,
      conditionalClassNames: [
        [true, styles.base],
        [interactable, styles.interactable],
        [column && reverse, styles.columnReverse],
        [column && !reverse, styles.column],
        [!column && reverse, styles.reverse],
        [wrap, styles.wrap],
      ],
      conditionalStyles: [
        [gap, (gap) => ["gap", resolveSize(gap)]],
        [rowGap, (rowGap) => ["rowGap", resolveSize(rowGap)]],
        [columnGap, (columnGap) => ["columnGap", resolveSize(columnGap)]],
        [alignItems, (alignItems) => ["alignItems", alignItems]],
        [justifyContent, (justifyContent) => ["justifyContent", justifyContent]],
      ],
    });

    return (
      <div
        {...customAttributes}
        id={id}
        onFocus={onFocus}
        onBlur={onBlur}
        tabIndex={focusable === false || (focusable === undefined && !interactable) ? -1 : 0}
        ref={ref}
        className={className}
        style={style}
        onClick={
          onClick !== undefined
            ? (event) => {
                onClick(event);
                event.stopPropagation();
                event.preventDefault();
              }
            : undefined
        }
      >
        {children}
      </div>
    );
  }
);
