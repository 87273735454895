import React from "react";
import logo from "./logoWithName.png";
import { useLocation } from "react-router";
import { useTranslator } from "../../i18n/hooks";
import { getCurrentUser } from "../../api/services/users";
import { logoutRequest } from "../../api/services/authentication";
import { useResult } from "../../ts-commons/commons-react-hooks/request/useResult";
import { useRequest } from "../../ts-commons/commons-react-hooks/request/RequestContext";
import { createBreadcrumbs, UrlHierarchy } from "../../ts-commons/commons-react-components/topbar/UrlHierarchy";
import { StandardTopBar } from "../../ts-commons/commons-react-components/topbar/StandardTopBar";

/**
 * This defines the path hierarchy for the displayed breadcrumbs, but routes are currently defined elsewhere. When
 * adding a new page or modifying an existing one, make sure to also update the routes found in
 * /view/core/frame/AppFrame.tsx.
 */
const URL_HIERARCHY: UrlHierarchy = {
  rootLabel: "breadcrumbs.home",
  items: [
    {
      matches: /users/,
      label: "breadcrumbs.users",
      children: [
        {
          matches: /create/,
          label: "breadcrumbs.genericCreate",
        },
        {
          matches: /.+/,
        },
      ],
    },
    {
      label: "breadcrumbs.monitor",
      matches: /monitor/,
      children: [
        {
          matches: /.+/,
        },
      ],
    },
    {
      label: "breadcrumbs.targets",
      matches: /targets/,
      children: [
        {
          matches: /create/,
          label: "breadcrumbs.genericCreate",
        },
        {
          matches: /.+/,
        },
      ],
    },
    {
      matches: /change_password/,
      label: "breadcrumbs.changePassword",
    },
  ],
};

const AppTopBar: React.FC = () => {
  const t = useTranslator("topBar");
  const { pathname } = useLocation();
  const breadcrumbs = createBreadcrumbs(pathname, URL_HIERARCHY, t);

  const [currentUser] = useResult(getCurrentUser, {});
  const currentUserId = currentUser.status === "ready" ? currentUser.value.id : "";
  const logout = useRequest(logoutRequest);

  return (
    <StandardTopBar
      mode="desktop"
      breadcrumbs={breadcrumbs}
      logo={logo}
      dropdown={{
        displayText: currentUser.status === "ready" ? currentUser.value.loginName : "",
        items: [
          {
            type: "link",
            icon: "user",
            label: t("profileLink"),
            to: `/users/${currentUserId}`,
          },
          {
            type: "link",
            icon: "key",
            label: t("changePassword"),
            to: `/change_password`,
          },
          {
            type: "button",
            icon: "sign-out",
            label: t("logout"),
            onClick: logout,
          },
        ],
      }}
    />
  );
};

export default AppTopBar;
