import React, { useContext } from "react";
import { Button, Icon, Popup, SemanticCOLORS } from "semantic-ui-react";
import styles from "./FormRadioButtons.module.css";
import { FormContext, useRelevantErrors } from "./TypedForm";
import { HelpText } from "../help/HelpText";
import { Flex } from "../flex/Flex";

export interface RadioButtonChoice {
  /**
   * The value of the code: this will be written to the form state when this choice is selected.
   */
  value: string;
  /**
   * A user-visible label to show on the button.
   */
  label: string;
  /**
   * A user-visible tooltip that will be shown if a mouse is hovered over the choice.
   */
  tooltip?: string;
  /**
   * A color for the radio button. Only displayed if the button is currently active -- disabled buttons will be colored
   * gray.
   */
  color?: SemanticCOLORS;
}

export interface FormRadioButtonsProps<Fields, Error> {
  formField: keyof Fields & string;
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  choices: RadioButtonChoice[];
  helpText?: string;
}

/**
 * This component can be used to add a radio-button group to a form. This can be used to display a small number of
 * choices to the user when only one choice can be active at a given time. The component is controlled by the following
 * properties.
 * - formField: the field of the form's state that this component uses, i.e. which member of the form state dictates
 *              the currently-active choice and is modified when a user interacts with the buttons.
 * - label: a label to display on top of the field, to inform the user what the use for this widget is.
 * - disabled: whether the buttons should be disabled, that is non-interactable for the user.
 * - relatedErrors: which errors of the form state relate to this particular widget. If the form state contains one of
 *                  the errors listed here, they will be displayed above the radio buttons. The component tries to
 *                  display the errors so that its size will not change, but this may cause certain errors to be hidden
 *                  if there are a large number of them, or they have very long explanations.
 * - choices: A list of choices for this button group. Controls what options the user can choose from for the selection.
 * - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *              this text as a popup.
 */
export const FormRadioButtons = <Fields, Error>(props: FormRadioButtonsProps<Fields, Error>) => {
  const formContext = useContext(FormContext);
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  return (
    <Flex column alignItems="start">
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText!!} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <Button.Group>
        {props.choices.map((choice) => {
          const isActive = formContext.state
            ? (formContext.state[props.formField] as unknown as string) === choice.value
            : false;
          const tooltip = choice.tooltip ? choice.tooltip : "";
          return (
            <Popup
              content={tooltip}
              disabled={choice.tooltip === undefined}
              trigger={
                <Button
                  className={`${styles.radioButton} ${isActive ? "" : styles.inactiveButton}`}
                  color={choice.color}
                  disabled={props.disabled ? props.disabled : formContext.mode !== "edit"}
                  onClick={() => formContext.onInputChange(props.formField, choice.value)}
                >
                  {choice.label}
                </Button>
              }
            />
          );
        })}
      </Button.Group>
    </Flex>
  );
};
