import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Card, Form, Icon } from "semantic-ui-react";

import { loginRequest } from "../../../api/services/authentication";

import styles from "./LoginScreen.module.css";
import packageJson from "./../../../../package.json";
import logo from "./logo.png";
import { useRequest } from "../../../ts-commons/commons-react-hooks/request/RequestContext";

interface LoginState {
  username: string;
  password: string;
  status: "initial" | "authenticating";
  error?: string;
}

const LoginScreen: React.FC = () => {
  const { t } = useTranslation("translations", { keyPrefix: "login" });
  const navigate = useNavigate();
  const authenticate = useRequest(loginRequest);
  const currentLocation = useLocation();

  const [state, setState] = useState<LoginState>({
    username: "",
    password: "",
    status: "initial",
  });

  const submitLogin = () => {
    setState({
      ...state,
      status: "authenticating",
      error: undefined,
    });

    if (!state.username) {
      setState({
        ...state,
        status: "initial",
        error: t("error.usernameRequired"),
      });
    } else if (!state.password) {
      setState({
        ...state,
        status: "initial",
        error: t("error.passwordRequired"),
      });
    } else {
      authenticate({
        username: state.username,
        password: state.password,
      }).then(({ body: { successful } }) => {
        if (successful) {
          let navState = currentLocation?.state as any | undefined;
          navigate(navState?.returnUrl ?? "/");
        } else {
          setState({
            ...state,
            status: "initial",
            error: t("error.invalidCredentials"),
          });
        }
      });
    }
  };

  return (
    <div className={styles.container}>
      <Card className={styles.card}>
        <Card.Content className={styles.loginHeader}>
          <Card.Header>
            {" "}
            <img alt="" src={logo} /> {t("cardHeader")}
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Form error={state.error !== undefined}>
            <Form.Field>
              <label>{t("usernameLabel")}</label>
              <input
                id="usernameInput"
                value={state.username}
                onChange={(event) => setState({ ...state, username: event.target.value })}
              />
            </Form.Field>
            <Form.Field>
              <label>{t("passwordLabel")}</label>
              <input
                id="passwordInput"
                value={state.password}
                type="password"
                onChange={(event) => setState({ ...state, password: event.target.value })}
              />
            </Form.Field>
            {state.error && (
              <div className={styles.formError} id="loginErrorLabel">
                {" "}
                <Icon className={"exclamation circle"} />
                {state.error}
              </div>
            )}
            <Button
              primary
              fluid
              disabled={state.status === "authenticating"}
              onClick={submitLogin}
              type="submit"
              id="loginButton"
            >
              {t("loginButton")}
            </Button>
          </Form>
        </Card.Content>
      </Card>
      <Card.Content className={styles.versionNumber}>{t("version", { number: packageJson.version })}</Card.Content>
    </div>
  );
};

export default LoginScreen;
