import { RequestEvent, RequestEventQuery } from "../model/RequestEvent";
import { LocalDateTime } from "@js-joda/core";
import {
  PagedResults,
  RequestGenerator,
  RequestGeneratorProps,
} from "../../ts-commons/commons-react-hooks/request/models";
import { makeRequest, makeURL } from "../../ts-commons/commons-react-hooks/request/makeRequest";

export const listRequestEvents: RequestGenerator<RequestEventQuery, PagedResults<RequestEvent>, void> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: makeURL("request_events", params),
      jsonReviver: requestEventReviver,
      props,
    });
  };
};

export const getRequestEventById: RequestGenerator<{ id: number }, RequestEvent, void> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: `request_events/${params.id}`,
      jsonReviver: requestEventReviver,
      props,
    });
  };
};

const requestEventReviver = (key: string, value: any): any => {
  switch (key) {
    case "created":
    case "completed":
    case "requestSent":
      if (typeof value === "string") {
        return LocalDateTime.parse(value);
      } else {
        return undefined;
      }
    default:
      return value;
  }
};
