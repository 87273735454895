import { Checkbox as SemanticCheckbox } from "semantic-ui-react";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";

export interface CheckboxProps extends StandardComponentProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  toggle?: boolean;
  label?: string;
  disabled?: boolean;
}

export const Checkbox = ({ checked, toggle, disabled, label, onChange, ...other }: CheckboxProps) => {
  const { className, style } = resolveStandardStyling(other);

  return (
    <SemanticCheckbox
      className={className}
      style={style}
      checked={checked}
      toggle={toggle}
      label={label}
      disabled={disabled}
      onChange={(event, data) => onChange(data.checked ?? false)}
    />
  );
};
