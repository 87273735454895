import { updateProxyTargetLicenseKey } from "../../../../api/services/proxyTargets";
import { ReactElement, useState } from "react";
import { useTranslator } from "../../../../i18n/hooks";
import { Button, Modal } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { toNumber } from "lodash";
import { createTypedForm } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";

export interface UpdateTargetLicenseModalProps {
  trigger: ReactElement;
}

interface UpdateProxyTargetLicenseKeyFields {
  licenseKey: string;
}

type UpdateLicenseKeyErrors = "invalidLicenseKey";

const Form = createTypedForm<UpdateProxyTargetLicenseKeyFields, UpdateLicenseKeyErrors>();

const UpdateTargetLicenseModal = ({ trigger }: UpdateTargetLicenseModalProps) => {
  const { targetId } = useParams();
  const t = useTranslator("targets.licenseModal");
  const ct = useTranslator("common.modal");
  const updateLicenseKey = useRequest(updateProxyTargetLicenseKey);

  const [open, setOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState<[UpdateLicenseKeyErrors, string][]>([]);
  const [formState, setFormState] = useState<UpdateProxyTargetLicenseKeyFields>({
    licenseKey: "",
  });

  const onUpdateLicenseKey = () => {
    setSubmitting(true);
    setErrors([]);
    updateLicenseKey({ id: toNumber(targetId), data: { licenseKey: formState.licenseKey } }).then(
      (response) => {
        if (response.status === 200) {
          setOpen(false);
        } else if (response.status === 400) {
          setErrors([["invalidLicenseKey", t("error.invalidLicenseKey")]]);
        } else {
          setOpen(false);
        }
        setSubmitting(false);
      },
      () => {
        setSubmitting(false);
        setOpen(false);
      }
    );
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => {
        setFormState({ licenseKey: "" });
        setErrors([]);
        setOpen(true);
      }}
      open={open}
      trigger={trigger}
    >
      <Modal.Header>{t("title")}</Modal.Header>
      <Modal.Content>
        <Form state={formState} setState={setFormState} mode={isSubmitting ? "submit" : "edit"} errors={errors}>
          <Form.Input formField="licenseKey" relatedErrors={["invalidLicenseKey"]} label={t("newLicenseLabel")} />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button basic onClick={() => setOpen(false)}>
          {ct("cancelButton")}
        </Button>
        <Button primary onClick={onUpdateLicenseKey} disabled={isSubmitting}>
          {t("submitButton")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default UpdateTargetLicenseModal;
