import React from "react";
import { Route, Routes } from "react-router";
import LoginScreen from "./view/screen/login/LoginScreen";
import AppFrame from "./view/frame/AppFrame";
import { useAuthReducer } from "./reducer/authReducer";

import "./App.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { ToastContainer } from "react-toastify";
import SplashScreen from "./view/frame/SplashScreen";
import { MessageContext } from "./ts-commons/commons-react-components/message-context/MessageContext";
import { useTranslator } from "./i18n/hooks";

/**
 * The wrapper component for the entire application. Contains essentially a selection between showing the login screen,
 * or the main application frame. This component will also automatically redirect the URL back to the login page if it
 * notices that there is no authenticated user.
 */
const App: React.FC = () => {
  const [authState] = useAuthReducer();
  const commonsTranslator = useTranslator("tsCommons");

  return (
    <div className="App">
      <ToastContainer />
      <MessageContext translateMessage={commonsTranslator}>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/*" element={authState.authenticatedUser ? <AppFrame /> : <SplashScreen />} />
        </Routes>
      </MessageContext>
    </div>
  );
};

export default App;
