import { Container } from "semantic-ui-react";
import styles from "./ChangePasswordScreen.module.css";
import React, { useState } from "react";
import { useTranslator } from "../../../../i18n/hooks";
import { changePasswordRequest } from "../../../../api/services/users";
import { createTypedForm } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { Title } from "../../../../ts-commons/commons-react-components/title/Title";

interface ChangePasswordFormFields {
  oldPassword: string;
  newPassword: string;
  passwordConfirm: string;
}
type ChangePasswordErrorCode =
  | "EMPTY_OLD_PASSWORD"
  | "EMPTY_NEW_PASSWORD"
  | "UNEXPECTED"
  | "PASSWORD_NOT_MATCH"
  | "WRONG_PASSWORD";
const Form = createTypedForm<ChangePasswordFormFields, ChangePasswordErrorCode>();

const ChangePasswordScreen = () => {
  const t = useTranslator("changePassword");
  const changePassword = useRequest(changePasswordRequest);
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [errors, setErrors] = useState<[ChangePasswordErrorCode, string][]>([]);
  const [formState, setFormState] = useState<ChangePasswordFormFields>({
    oldPassword: "",
    newPassword: "",
    passwordConfirm: "",
  });
  const [successful, setSuccessful] = useState<boolean>(false);

  const onSavePassword = () => {
    setSubmitting(true);
    setErrors([]);
    if (!formState.oldPassword) {
      setSubmitting(false);
      setErrors([["EMPTY_OLD_PASSWORD", t("error.passwordNotEmpty")]]);
      setSuccessful(false);
    } else if (!formState.newPassword) {
      setSubmitting(false);
      setErrors([["EMPTY_NEW_PASSWORD", t("error.passwordNotEmpty")]]);
      setSuccessful(false);
    } else if (formState.newPassword !== formState.passwordConfirm) {
      setSubmitting(false);
      setErrors([["PASSWORD_NOT_MATCH", t("error.passwordNotMatch")]]);
      setSuccessful(false);
    } else {
      changePassword({ oldPassword: formState.oldPassword, newPassword: formState.newPassword }).then((response) => {
        setSubmitting(false);
        if (response.status === 200) {
          setErrors([]);
          setFormState({
            oldPassword: "",
            newPassword: "",
            passwordConfirm: "",
          });
          setSuccessful(true);
        } else {
          setSuccessful(false);
          if (response.body.code === "WRONG_PASSWORD") {
            setErrors([["WRONG_PASSWORD", t("error.wrongPassword")]]);
          } else {
            setErrors([["UNEXPECTED", t("error.unexpected")]]);
          }
        }
      });
    }
  };
  return (
    <Container>
      <Title
        submitting={isSubmitting}
        icon="key"
        title={t("title")}
        primaryActions={[
          {
            label: t("saveButton"),
            type: "button",
            icon: "save",
            disabled: isSubmitting,
            submitting: isSubmitting,
            onClick: onSavePassword,
          },
        ]}
      />
      <Form state={formState} setState={setFormState} errors={errors} mode={"edit"}>
        <Form.Input
          formField="oldPassword"
          type={"password"}
          relatedErrors={["EMPTY_OLD_PASSWORD", "WRONG_PASSWORD", "UNEXPECTED"]}
          label={t("oldPasswordLabel")}
        />
        <Form.Input
          formField="newPassword"
          type={"password"}
          relatedErrors={["EMPTY_NEW_PASSWORD", "UNEXPECTED", "PASSWORD_NOT_MATCH"]}
          label={t("newPasswordLabel")}
        />
        <Form.Input
          formField="passwordConfirm"
          type={"password"}
          relatedErrors={["PASSWORD_NOT_MATCH"]}
          label={t("passwordConfirmLabel")}
        />
      </Form>
      {successful && <div className={styles.formSuccess}>{t("succeeded")}</div>}
    </Container>
  );
};

export default ChangePasswordScreen;
