import React from "react";
import { Dropdown, DropdownProps, SemanticICONS } from "semantic-ui-react";
import { Link, To } from "react-router-dom";

/**
 * A link-type item for the dropdown, that is an item which when clicked navigates the user to some page of the system.
 * It is generally recommended that the links in this component are context independent, that is the current page does
 * not change what options are available in the dropdown or how they behave.
 */
export interface DropdownLinkItem {
  type: "link";
  icon: SemanticICONS;
  label: string;
  to: To;
}

/**
 * A button-type item for the dropdown, that is a non-link item with a callback. Do not use this if you wish to link the
 * user to another page: calling navigation in the `onClick` callback works when navigating in one tab, but makes it
 * impossible to use the link to another tab. Only use this for items where the action is not a navigation action, or
 * does not make sense to be opened in a new tab. An example of such an action is logging out.
 */
export interface DropdownButtonItem {
  type: "button";
  icon: SemanticICONS;
  label: string;
  onClick: () => void;
}

export type DropdownItem = DropdownLinkItem | DropdownButtonItem;

export type TopBarDropdownProps = {
  /**
   * The text that is always displayed in the dropdown.
   */
  displayText: string;
  /**
   * The list of items that are displayed in this dropdown when clicked.
   */
  items: DropdownItem[];
};

/**
 * A dropdown component designed to be used in a {@link StandardTopBar}. The dropdown does not have an active selection
 * that the user can change, but instead always displays a static text and provides a list of navigation options when
 * clicked. See the {@link TopBarDropdownProps} for how to configure this component.
 */
export const TopBarDropdown = (props: Omit<DropdownProps, "text" | "children"> & TopBarDropdownProps) => {
  let { displayText, ...otherProps } = props;
  return (
    <Dropdown text={displayText} {...otherProps}>
      <Dropdown.Menu>
        {props.items.map((item, index) => {
          if (item.type === "link") {
            return (
              <Dropdown.Item
                icon={item.icon}
                text={item.label}
                as={Link}
                to={item.to}
                key={`topbar_dropdown_item_${index}`}
              />
            );
          } else if (item.type === "button") {
            return (
              <Dropdown.Item
                icon={item.icon}
                text={item.label}
                onClick={() => item.onClick()}
                key={`topbar_dropdown_item_${index}`}
              />
            );
          } else {
            return undefined;
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};
