import { getAuthDispatch, getNavigate } from "../AppRequestContext";
import { RequestGenerator, RequestGeneratorProps } from "../../ts-commons/commons-react-hooks/request/models";
import { makeRequest } from "../../ts-commons/commons-react-hooks/request/makeRequest";

export interface AuthRequest {
  username: string;
  password: string;
}
export interface AuthResponse {
  successful: boolean;
}

export const loginRequest: RequestGenerator<AuthRequest, AuthResponse, AuthResponse> = (
  props: RequestGeneratorProps
) => {
  const authDispatch = getAuthDispatch(props);

  return (requestBody: AuthRequest) => {
    return makeRequest({
      method: "POST",
      url: "login",
      body: requestBody,
      props,
    }).then(
      (response) => {
        if (response.status === 200) {
          authDispatch({
            type: "authenticate",
            user: requestBody.username,
          });
          return {
            status: 200,
            body: {
              successful: true,
            },
          };
        } else {
          return {
            status: response.status,
            body: {
              successful: false,
            },
          };
        }
      },
      (rejection) => {
        return Promise.resolve({
          status: 400,
          body: {
            successful: false,
          },
        });
      }
    );
  };
};

export const logoutRequest: RequestGenerator<void, {}, {}> = (props: RequestGeneratorProps) => {
  return () => {
    return makeRequest<{}, {}>({
      method: "POST",
      url: "logout",
      props,
    }).then((response) => {
      const authDispatch = getAuthDispatch(props);
      const navigate = getNavigate(props);

      navigate("/login");
      authDispatch({ type: "reset" });
      return response;
    });
  };
};
