import React, { useEffect, useState } from "react";
import { Label } from "semantic-ui-react";
import styles from "./CopyButton.module.css";
import { ButtonProps, Button } from "../button/Button";

export type CopyButtonProps = ButtonProps & {
  dataToCopy: string | (() => string);
  afterCopyLabel?: string;
};

/**
 * This component is a button that copies the `dataToCopy` from the properties to the computer clipboard when the
 * button is clicked. After the data has been copied text from `afterCopyLabel` will be displayed next to the button
 * for one second.
 */
export const CopyButton = ({ dataToCopy, afterCopyLabel, ...buttonProps }: CopyButtonProps) => {
  const [copyLabelVisible, setCopyLabelVisible] = useState(false);

  const copyData = () => {
    if (dataToCopy !== undefined && navigator.clipboard) {
      let data: string;
      if (typeof dataToCopy === "string") {
        data = dataToCopy;
      } else {
        data = dataToCopy();
      }
      navigator.clipboard.writeText(data).then(() => setCopyLabelVisible(true));
    }
  };

  useEffect(() => {
    let interval: any = null;
    if (copyLabelVisible) {
      interval = setInterval(() => {
        setCopyLabelVisible(false);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [copyLabelVisible]);

  const customizedProps = {
    onClick: copyData,
    basic: true,
    className: styles.copyButton,
    icon: "copy outline",
    ...buttonProps,
  };

  return (
    <React.Fragment>
      <Button {...customizedProps} />
      {copyLabelVisible && afterCopyLabel ? (
        <Label basic pointing="left">
          {afterCopyLabel}
        </Label>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};
