import { Grid, Icon, Loader, Popup, Table } from "semantic-ui-react";
import React, { memo, ReactNode, useCallback, useMemo, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import styles from "./ResultsTable.module.css";
import { EllipsizableText } from "../ellipsize/EllipsizableText";
import { FilterInfo } from "./tableFilters";
import { useCommonsTranslate } from "../message-context/MessageContext";
import { Button } from "../button/Button";
import { Pagination } from "./Pagination";
import { Flex } from "../flex/Flex";
import { AbakusCOLORS, colorMapping } from "../../commons-ts-utils/utils/colors";

export type PaginationInfo = {
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalElements: number;
};

export type ColumnWidth = {
  type: "weighted" | "static-px" | "static-em";
  value: number;
};

/**
 * Properties that define one column in a {@link ResultsTable}.
 */
export interface ColumnDefinition<T> {
  /**
   * The header of the column.
   */
  header: string;
  /**
   * Sort string when sorting in ascending order.
   */
  ascending?: string;
  /**
   * Sort string when sorting in descending order.
   */
  descending?: string;
  /**
   * The width of this column. It can either be fixed (defined with em or px units) or weighted. If one column has a
   * weighted width, all the other columns also must have either weighted or fixed width.
   */
  width?: ColumnWidth;
  /**
   * How to align the contents of this column's cells.
   */
  textAlign?: "center" | "left" | "right";
  /**
   * A function to render the column. The function is called for each item in {@link ResultsTableProps#items} and it
   * should return what is displayed for that item. The returned value can be a React node or a string.
   * @param item
   */
  renderCell: (item: T) => ReactNode | string;
  /**
   * A {@link FilterInfo} object for this column. If defined, causes a filter-button to appear in the column header,
   * which can be clicked to view/modify filters. This is an alternate approach to a search form on top of the view.
   * If the value of this is "loading", then the button for showing the filter is disabled and will instead display
   * a loading icon in its place. This can be used when the filter depends on fetching some set of reference entities
   * from the server.
   */
  filter?: FilterInfo | "loading";
  /**
   * A unique identifier for this column. This is needed for React to efficiently render a list of
   * items. If left undefined, a key is automatically assigned based on the index.
   */
  columnKey?: string;
  /**
   * A function that returns the status of the cell. Can be one of: "error", "negative", "warning", "positive" or
   * undefined. If the status is not undefined, the background color of the cell changes according to the status.
   * @param item
   */
  cellStatus?: (item: T) => "error" | "negative" | "warning" | "positive" | undefined;
  /**
   * A function to resolve the background color of the cell. The function will not be called for headers.
   * @param item
   */
  backgroundColor?: (item: T) => AbakusCOLORS | undefined;
}

/**
 * The properties that control what and how {@link ResultsTable} is rendered.
 */
export interface ResultsTableProps<T> {
  /**
   * A html element id that is given to the table-element and used in the creation of the
   * table-header element ids.
   */
  id?: string;
  /**
   * The items that are rendered in a {@link ResultsTable}.
   */
  items?: T[];

  /**
   * Information about pagination, used to render the pagination controls at the footer.
   */
  pagination?: PaginationInfo;

  /**
   * A list of column definitions that are rendered.
   */
  columns: ColumnDefinition<T>[] | (() => ColumnDefinition<T>[]);

  /**
   * A function to populate the "end" of the footer. If this function returns something, that element will be placed
   * at the end of the footer, after pagination control. One third of the footer is reserved for this.
   */
  renderFooter?: () => ReactNode | string;

  /**
   * This function is called when a user clicks a table header of a column that can be used for sorting. The new string
   * that defines the sorting is given as a parameter to this function. A sortable column is such that has both
   * {@link ColumnDefinition#ascending} and {@link ColumnDefinition#descending} specified.
   * @param newSort
   */
  onSort: (newSort?: string) => void;

  /**
   * The sort string that will be used when {@link ResultsTable} is rendered.
   */
  currentSort: string;

  /**
   * This function is called when a user changes the page with the pagination controls in the footer. The "user" of the
   * {@link ResultsTable} component should update the <code>items</code> with data from the new page.
   * @param currentPage
   */
  onPageChanged: (currentPage: number) => void;

  /**
   * A function that returns an unique identifier for an item. This is needed for React to efficiently render a list
   * of items. If the function returns `undefined` for an item (or the whole function is undefined), the item's index is
   * used as the key.
   */
  itemKey?: (item: T) => string | undefined;

  /**
   * Informational text that is displayed instead of results if nothing is found.
   */
  noResultsInfo?: string;

  /**
   * An informational text that is displayed instead of results if nothing is found on this page, but there would be
   * results on an earlier page. This can happen if the query parameters are changed without resetting the page, which
   * can easily happen for a debounced query.
   */
  noResultsOnThisPageInfo?: string;
  /**
   * This function is called when the body of this table is scrolled in either direction.
   * @param container
   */
  onBodyScrolled?: (container: HTMLElement) => void;
  /**
   * Should the rows in this component be memoized so that the rows are only rendered when the items of this table are
   * changed. This is useful e.g. when this component is inside a parent that is rendered often (there's e.g. a text
   * input where the user types something) as rendering the rows may be quite heavy and cause noticeable lag on a page.
   *
   * When using memoization with this property as `true`, the `columns` property must be given as a function!
   *
   * Also note that all the function properties will be wrapped in `useMemo` or `useCallback` so preferably the
   * functions should not have any dependencies. If a function must have a dependency, it must be listed in
   * {@link memoizationDeps}, otherwise this results table may work in a very weird way.
   */
  memoizeRows?: boolean;
  /**
   * When using a memoized results table with {@link memoizeRows}, list the dependencies of the function properties here.
   */
  memoizationDeps?: any[];
}

const HeaderFilterButton = ({ filter }: { filter: FilterInfo | "loading" }) => {
  const t = useCommonsTranslate();
  const [isOpen, setOpen] = useState(false);

  return (
    <Popup
      on="click"
      open={isOpen}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      trigger={
        <Button
          loading={filter === "loading"}
          disabled={filter === "loading"}
          basic={filter === "loading" || !filter.hasActiveFilters}
          color={filter !== "loading" && filter.hasActiveFilters ? "blue" : undefined}
          className={styles.headerFilterButton}
          compact
          size="tiny"
          icon="filter"
        />
      }
    >
      {filter !== "loading" ? filter?.renderSelection(t, () => setOpen(false)) : undefined}
    </Popup>
  );
};

/**
 * A component to render a list of items in a table. The table is rendered based on the {@link ResultsTableProps} given
 * for this component. This component will create a table that has static header and a footer (i.e. the content is shown
 * between the header and the footer and only the content can be scrolled). The footer will automatically be populated
 * with pagination controls. For the table styling to work correctly, this component should be placed inside
 * {@link ResultsContainer}.
 */
export const ResultsTable = <T,>({
  id,
  items,
  pagination,
  columns,
  renderFooter,
  onSort,
  currentSort,
  onPageChanged,
  itemKey,
  noResultsInfo,
  noResultsOnThisPageInfo,
  onBodyScrolled,
  memoizeRows,
  memoizationDeps,
}: ResultsTableProps<T>) => {
  // Store the pagination data to a local "variable" so the pagination bar can display number while the items are
  // loading
  const localPaginationData = useRef({
    totalPages: 0,
    currentPage: 1,
    pageSize: 0,
    totalElements: 0,
  });

  const handleHeaderClick = (clickedColumn: ColumnDefinition<T>) => {
    if (clickedColumn.ascending === currentSort) {
      onSort(clickedColumn.descending ?? "");
    } else {
      onSort(clickedColumn.ascending ?? "");
    }
  };

  const handlePaginationChange = (newPage: number) => {
    localPaginationData.current = {
      totalPages: pagination?.totalPages ?? 0,
      currentPage: newPage,
      pageSize: pagination?.pageSize ?? 0,
      totalElements: pagination?.totalElements ?? 0,
    };
    onPageChanged(newPage);
  };

  let columnsArray: ColumnDefinition<T>[];
  if (Array.isArray(columns)) {
    columnsArray = columns;
  } else {
    columnsArray = columns();
  }

  const totalColumnWidthWeight = columnsArray
    .filter((column) => column.width !== undefined && column.width.type === "weighted")
    .reduce((sum, column) => sum + (column.width?.value ?? 0), 0);

  return (
    <div className={styles.tableContainer}>
      <Table id={id} celled compact="very" singleLine striped className={styles.table}>
        <Table.Header fullWidth>
          <Table.Row>
            {columnsArray.map((column, index) => {
              const columnKey = `column_${column.columnKey ?? index.toString()}`;
              const columnId = id ? `${id}-${columnKey}` : undefined;

              return (
                <Table.HeaderCell
                  id={columnId}
                  key={columnKey}
                  style={createColumnStyle(column, undefined, totalColumnWidthWeight)}
                  className={styles.tableHeader}
                >
                  <Flex>
                    <Flex grow>
                      <Flex minWidth={0}>
                        {column.ascending && column.descending ? (
                          <EllipsizableText
                            onClick={() => handleHeaderClick(column)}
                            content={column.header}
                            renderAs="a"
                          />
                        ) : (
                          <EllipsizableText content={column.header} renderAs="span" />
                        )}
                      </Flex>
                      {column.ascending === currentSort ? (
                        <Icon name="caret up" />
                      ) : column.descending === currentSort ? (
                        <Icon name="caret down" />
                      ) : (
                        ""
                      )}
                    </Flex>
                    {column.filter ? <HeaderFilterButton filter={column.filter} /> : undefined}
                  </Flex>
                </Table.HeaderCell>
              );
            })}
          </Table.Row>
        </Table.Header>

        {memoizeRows ? (
          <MemoizedResultsTableRows
            id={id}
            items={items}
            pagination={pagination}
            columns={columns}
            itemKey={itemKey}
            noResultsInfo={noResultsInfo}
            noResultsOnThisPageInfo={noResultsOnThisPageInfo}
            onBodyScrolled={onBodyScrolled}
            handlePaginationChange={handlePaginationChange}
            totalColumnWidthWeight={totalColumnWidthWeight}
            memoizationDeps={memoizationDeps}
          />
        ) : (
          <ResultsTableRows
            id={id}
            items={items}
            pagination={pagination}
            columns={columns}
            itemKey={itemKey}
            noResultsInfo={noResultsInfo}
            noResultsOnThisPageInfo={noResultsOnThisPageInfo}
            onBodyScrolled={onBodyScrolled}
            handlePaginationChange={handlePaginationChange}
            totalColumnWidthWeight={totalColumnWidthWeight}
          />
        )}

        <Table.Footer fullWidth>
          <Table.Row>
            <Table.HeaderCell colSpan={columns.length}>
              <Grid columns="3">
                <Grid.Column width="3" verticalAlign="middle">
                  {currentPageElementInfo(pagination ?? localPaginationData.current)}
                </Grid.Column>
                <Grid.Column key="pagination" textAlign="center" width="10">
                  <Pagination
                    onPageChanged={handlePaginationChange}
                    surroundingPages={3}
                    currentPage={pagination?.currentPage ?? localPaginationData.current.currentPage}
                    lastPage={pagination?.totalPages ?? localPaginationData.current.totalPages}
                    firstPage={1}
                  />
                </Grid.Column>
                <Grid.Column key="custom" width="3">
                  {renderFooter !== undefined ? renderFooter() : ""}
                </Grid.Column>
              </Grid>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </div>
  );
};

interface ResultsTableRowsProps<T> {
  id?: string;
  items?: T[];
  pagination?: PaginationInfo;
  columns: ColumnDefinition<T>[] | (() => ColumnDefinition<T>[]);
  itemKey?: (item: T) => string | undefined;
  noResultsInfo?: string;
  noResultsOnThisPageInfo?: string;
  onBodyScrolled?: (container: HTMLElement) => void;
  handlePaginationChange: (newPage: number) => void;
  totalColumnWidthWeight: number;
  memoizationDeps?: any[];
}

const ResultsTableRows = <T,>({
  id,
  items,
  pagination,
  columns,
  itemKey,
  noResultsInfo,
  noResultsOnThisPageInfo,
  onBodyScrolled,
  handlePaginationChange,
  totalColumnWidthWeight,
}: ResultsTableRowsProps<T>) => {
  const t = useCommonsTranslate();

  const renderCell = (column: ColumnDefinition<T>, item: T) => {
    let content = column.renderCell(item);
    if (typeof content === "string" || typeof content === "number" || typeof content === "boolean") {
      return <EllipsizableText content={content.toString()} renderAs="span" />;
    } else {
      return content;
    }
  };

  let columnsArray: ColumnDefinition<T>[];
  if (Array.isArray(columns)) {
    columnsArray = columns;
  } else {
    columnsArray = columns();
  }

  return (
    <PerfectScrollbar component="tbody" onScrollY={onBodyScrolled}>
      {items?.length === 0 ? (
        <Table.Row className={styles.fullHeight} id={id ? `${id}-noResultsRow` : undefined}>
          <Table.Cell colSpan={columns.length} textAlign="center">
            <Flex column alignItems="center" justifyContent="center">
              <p>
                <Icon name="warning sign" size="big" />
              </p>
              {(pagination?.totalElements ?? 0) > 0 ? (
                <>
                  <p>{noResultsOnThisPageInfo ?? t("resultsTable.noResultsOnThisPageInfo")}</p>
                  <Button
                    basic
                    text={t("resultsTable.buttonGoToFirstPage")}
                    icon="arrow left"
                    onClick={() => handlePaginationChange(1)}
                  />
                </>
              ) : (
                <p>{noResultsInfo ?? t("resultsTable.noResultsInfo")}</p>
              )}
            </Flex>
          </Table.Cell>
        </Table.Row>
      ) : (
        items?.map((item, itemIndex) => {
          const key = itemKey !== undefined ? itemKey(item) : undefined;
          const rowKey = `row_${key ?? itemIndex.toString()}`;
          const rowId = id ? `${id}-${rowKey}` : undefined;

          return (
            <Table.Row id={rowId} key={rowKey} className="">
              {columnsArray.map((column, columnIndex) => {
                const columnKey = `column_${column.columnKey ?? columnIndex.toString()}`;
                const cellKey = `cell-${columnKey}-${rowKey}`;
                const cellId = id ? `${id}-${cellKey}` : undefined;

                return (
                  <Table.Cell
                    key={cellKey}
                    id={cellId}
                    style={createColumnStyle(column, item, totalColumnWidthWeight)}
                    textAlign={column.textAlign}
                    error={column.cellStatus && column.cellStatus(item) === "error"}
                    warning={column.cellStatus && column.cellStatus(item) === "warning"}
                    negative={column.cellStatus && column.cellStatus(item) === "negative"}
                    positive={column.cellStatus && column.cellStatus(item) === "positive"}
                  >
                    {renderCell(column, item)}
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        }) ?? ( // If the items are undefined, assume that they are loading and display a loader
          <Table.Row className={styles.fullHeight} id={id ? `${id}-loaderRow` : undefined}>
            <Table.Cell colSpan={columns.length}>
              <Loader active size="big" />
            </Table.Cell>
          </Table.Row>
        )
      )}
    </PerfectScrollbar>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
/**
 * An internal component that prevents the result table rows from being rendered when `columns`, `itemKey`,
 * `onBodyScrolled`, or `handlePaginationChange` (from `props`) are changed. Normally these are changed every time e.g.
 * the parent of the results table is rendered so this component wraps them in `useMemo` and `useMemo` to prevent the
 * renders and improve performance.
 *
 * react-hooks/exhaustive-deps lint warning is disabled since we don't want to pass the memo or callback dependencies.
 * This would cause the re-renders that we want to avoid with this component.
 */
const MemoizedResultsTableRows = <T,>(props: ResultsTableRowsProps<T>) => {
  if (typeof props.columns !== "function") {
    throw Error("columns must be a function with memoized ResultsTable!");
  }
  const { columns, itemKey, onBodyScrolled, handlePaginationChange, ...otherProps } = props;
  const memoizationDeps = (props.memoizationDeps ?? []).concat([props.items]);
  return (
    <MemoizedResultsTableRowsInternal
      columns={useMemo(columns, memoizationDeps)}
      itemKey={useCallback((item: any) => (itemKey !== undefined ? itemKey(item) : undefined), memoizationDeps)}
      handlePaginationChange={useCallback(handlePaginationChange, memoizationDeps)}
      onBodyScrolled={useCallback((container: any) => {
        if (onBodyScrolled !== undefined) {
          onBodyScrolled(container);
        }
      }, memoizationDeps)}
      {...otherProps}
    />
  );
};
/* eslint-enable */

const MemoizedResultsTableRowsInternal = memo<ResultsTableRowsProps<any>>((props: ResultsTableRowsProps<any>) => (
  <ResultsTableRows<any> {...props} />
));

/**
 * A simple container that makes sure that styling {@link ResultsTable} works correctly. This will just wrap its
 * children inside a div that has styling that works with {@link ResultsTable}.
 */
export const ResultsContainer = (props: { children: ReactNode }) => {
  return <div className={styles.resultsContent}>{props.children}</div>;
};

function currentPageElementInfo(pagination: PaginationInfo): string {
  const firstElement = (pagination.currentPage - 1) * pagination.pageSize + 1;
  const lastElement = pagination.currentPage * pagination.pageSize;
  return "" + firstElement + " - " + Math.min(lastElement, pagination.totalElements) + " / " + pagination.totalElements;
}

const createColumnStyle = <T,>(column: ColumnDefinition<T>, item: T | undefined, totalColumnWidthWeight: number) => {
  let style: { [key: string]: string } = {};
  if (column.width !== undefined) {
    if (column.width.type === "weighted") {
      style["width"] = ((100 * column.width.value) / totalColumnWidthWeight).toFixed(4) + "%";
    } else if (column.width.type === "static-em") {
      style["width"] = column.width.value + "em";
    } else if (column.width.type === "static-px") {
      style["width"] = column.width.value + "px";
    }
  }
  const backgroundColor = column.backgroundColor && item ? column.backgroundColor(item) : undefined;
  if (backgroundColor !== undefined) {
    style["backgroundColor"] = colorMapping[backgroundColor];
    style["color"] = "#FFFFFF";
  }
  return style;
};
