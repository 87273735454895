/**
 * This type defines the names of the colors that can be used in this component.
 */
export type AbakusCOLORS =
  | "red"
  | "orange"
  | "yellow"
  | "olive"
  | "green"
  | "teal"
  | "blue"
  | "violet"
  | "purple"
  | "pink"
  | "brown"
  | "grey"
  | "black"
  | "transparent"
  | "light green";

/**
 * A mapping from a {@link AbakusCOLORS} to the hex value of the color.
 */
export const colorMapping: { [color in AbakusCOLORS]: string } = {
  red: "#DB2828",
  orange: "#F2711C",
  yellow: "#FBBD08",
  olive: "#B5CC18",
  green: "#21BA45",
  teal: "#00B5AD",
  blue: "#2185D0",
  violet: "#6435C9",
  purple: "#A333C8",
  pink: "#E03997",
  brown: "#A5673F",
  grey: "#767676",
  black: "#1B1C1D",
  "light green": "#52e073",
  transparent: "transparent",
};
