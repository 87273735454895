import React from "react";

import { useNavigate } from "react-router-dom";
import { createUser, StoreUserErrorCode } from "../../../../api/services/users";
import { Container } from "semantic-ui-react";
import { useTranslator } from "../../../../i18n/hooks";
import { UserForm, UserFormFields } from "./UserForm";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { Title } from "../../../../ts-commons/commons-react-components/title/Title";

const CreateUserScreen: React.FC = () => {
  const navigate = useNavigate();
  const t = useTranslator("users.view");
  const ct = useTranslator("common");

  const saveUser = useRequest(createUser);

  const [isSubmitting, setSubmitting] = React.useState(false);
  const [formState, setFormState] = React.useState<UserFormFields>({
    loginName: "",
    password: "",
  });
  const [errors, setErrors] = React.useState<[StoreUserErrorCode, string][]>([]);

  const errorMapping = {
    INVALID_PASSWORD: "error.invalidPassword",
    INVALID_LOGIN_NAME: "error.invalidLoginName",
    LOGIN_NAME_RESERVED: "error.loginNameReserved",
  };

  const onSaveUser = () => {
    setSubmitting(true);
    setErrors([]);

    saveUser({ loginName: formState.loginName, password: formState.password }).then(
      (response) => {
        if (response.status === 200) {
          navigate(`/users/${response.body.user.id}`);
        } else {
          const errorCode = response.body.code;
          setErrors([[errorCode, t(errorMapping[errorCode])]]);
        }
        setSubmitting(false);
      },
      () => {
        setSubmitting(false);
      }
    );
  };

  return (
    <Container>
      <Title
        submitting={isSubmitting}
        icon="user"
        title={t("titleCreate")}
        primaryActions={[
          {
            label: ct("title.saveButton"),
            type: "button",
            icon: "save",
            disabled: isSubmitting,
            submitting: isSubmitting,
            onClick: onSaveUser,
          },
          {
            label: ct("title.cancelButton"),
            type: "button",
            icon: "undo",
            onClick: () => {
              navigate(`/users`);
            },
          },
        ]}
      />
      <UserForm
        enablePassword
        formState={formState}
        setFormState={setFormState}
        errors={errors}
        mode={isSubmitting ? "submit" : "edit"}
      />
    </Container>
  );
};

export default CreateUserScreen;
