import React from "react";
import { useTranslation } from "react-i18next";
import { Header, Segment } from "semantic-ui-react";
import AppSideBar from "./AppSideBar";
import { Navigate, Route, Routes } from "react-router";
import BrowseUsersScreen from "../screen/users/browse/BrowseUsersScreen";
import BrowseRequestEventsScreen from "../screen/monitor/browse/BrowseRequestEventsScreen";
import styles from "./AppFrame.module.css";
import AppTopBar from "./AppTopBar";
import ViewUserScreen from "../screen/users/view/ViewUserScreen";
import CreateUserScreen from "../screen/users/view/CreateUserScreen";
import ViewRequestEventScreen from "../screen/monitor/view/ViewRequestEventScreen";
import BrowseTargetsScreen from "../screen/targets/browse/BrowseTargetsScreen";
import ViewTargetScreen from "../screen/targets/view/ViewTargetScreen";
import ChangePasswordScreen from "../screen/users/view/ChangePasswordScreen";

/**
 * This component is the main "frame" of the application, containing the navigation bars and the content area,
 * displaying the currently select page's content.
 */
const AppFrame: React.FC = () => {
  const { t } = useTranslation("translations", { keyPrefix: "appFrame" });

  return (
    <div className={styles.appFrame}>
      <div className={styles.topBar}>
        <AppTopBar />
      </div>
      <div className={styles.mainArea}>
        <AppSideBar>
          <Segment basic className={styles.contentArea}>
            {
              // If you modify the routes listed here, make sure you also modify the hierarchy mapping in
              // /view/core/frame/AppTopBar.tsx, as otherwise the top bar's breadcrumbs may display incorrectly
              // or lack localization.
            }
            <Routes>
              <Route path="/" element={<Navigate replace to={"/monitor"} />} />

              <Route path="/monitor" element={<BrowseRequestEventsScreen />} />
              <Route path="/monitor/:requestEventId" element={<ViewRequestEventScreen />} />

              <Route path="/targets" element={<BrowseTargetsScreen />} />
              <Route path="/targets/:targetId" element={<ViewTargetScreen />} />

              <Route path="/users" element={<BrowseUsersScreen />} />
              <Route path="/users/create" element={<CreateUserScreen />} />
              <Route path="/users/:userId" element={<ViewUserScreen />} />
              <Route path="/change_password" element={<ChangePasswordScreen />} />

              <Route path="/*" element={<Header>{t("pageNotFound")}</Header>} />
            </Routes>
          </Segment>
        </AppSideBar>
      </div>
    </div>
  );
};

export default AppFrame;
