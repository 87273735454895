import { Header, Image, Loader } from "semantic-ui-react";
import { getCurrentUser } from "../../api/services/users";
import { useAuthReducer } from "../../reducer/authReducer";
import { useTranslation } from "react-i18next";
import styles from "./SplashScreen.module.css";
import logo from "./logoWithName.png";
import React from "react";
import { useResult } from "../../ts-commons/commons-react-hooks/request/useResult";

const SplashScreen = () => {
  const [currentUser] = useResult(getCurrentUser, {});
  const [, authDispatch] = useAuthReducer();
  const { t } = useTranslation("translations", { keyPrefix: "common" });

  if (currentUser.status === "ready") {
    authDispatch({
      type: "authenticate",
      user: currentUser.value.loginName,
    });
  }
  return (
    <div className={styles.splashScreen}>
      <Image src={logo} size={"small"} />
      <Header>{t("loading")}</Header>
      <Loader active inline size={"huge"} />
    </div>
  );
};

export default SplashScreen;
