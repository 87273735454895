import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FetchResult } from "../request/models";
import { isEqual } from "lodash";

export interface UseFormStateReturn<F, E> {
  /**
   * The current values for the form state. This is the value that is intended to be used in the displayed form.
   */
  formState: F;
  /**
   * A setter for updating {@link formState}.
   */
  setFormState: Dispatch<SetStateAction<F>>;
  /**
   * The default/initial value for the form state. This is what the {@link formState} will be reset to when
   * {@link resetForm} is called.
   */
  formResetState: F;
  /**
   * A setter for updating {@link formResetState}. The reset state should be updated when the underlying entity is
   * updated, i.e. the user saves their edited results.
   */
  setFormResetState: Dispatch<SetStateAction<F>>;
  /**
   * The list of errors in this form. Managing this list is mostly left to the view page, but {@link resetForm}
   * will also reset this to an empty array.
   */
  formErrors: [E, string][];
  /**
   * A setter for {@link formErrors}.
   */
  setFormErrors: Dispatch<SetStateAction<[E, string][]>>;
  /**
   * A convenience function for resetting {@link formState} back to the values of {@link formResetState}.
   */
  resetForm: () => void;
  /**
   * A convenience function that returns `true` if {@link formState} differs from {@link formResetState}. This works by
   * using lodash's {@link isEqual}, which is a recursive property-based equals check. That is, two primitives are equal
   * if their values are equal, and two objects are equal if all of their properties are equal according to the same
   * check.
   */
  isFormDirty: () => boolean;
  /**
   * A convenience setter that sets both {@link formState} and {@link formResetState}.
   */
  setBothFormStates: Dispatch<SetStateAction<F>>;
}

/**
 * A utility hook for initializing a typical form state for edit-view pages. The common features of a view/edit page are
 * the following:
 *
 * - The page should load some entity upon being opened, and populate the page's form based on the result.
 * - The page should remember the initial/default state for the form, so that it can reset itself if the user cancels
 *   the current edit.
 * - The page should be able to update the default state when, for example, the edited entity is saved.
 *
 * The goal of this hook is to accommodate the above needs in a generic way. It requires three generic arguments:
 *
 * - F: The type for the form state/form fields used on the page
 * - L: The type for the entity that is fetched for the page.
 * - E The type of the form's error code.
 *
 * And accepts three parameters.
 *
 * @param initialState The initial state of the form's state, before the entity is even available.
 * @param entityResult A return value from this module's useResult call for the entity that is being viewed/edited.
 * @param stateMapper A pure mapper function from the entity to a (partial) form state. Note that this function must be
 *                    either defined outside of the component or memoized with something like React's useCallback. This
 *                    is because this hook needs to pass it as a dependency to the {@link useEffect} that sets the
 *                    default values, and passing a changing reference as the stateMapper would cause the form state to
 *                    constantly reset to its default values.
 * @return a {@link UseFormStateReturn} object, containing the resulting properties. See the type's documentation for
 *         more details.
 */
export const useFormState = <F, L, E>(
  initialState: F,
  entityResult: FetchResult<L>,
  stateMapper: (entity: L) => Partial<F>
): UseFormStateReturn<F, E> => {
  const [formErrors, setFormErrors] = useState<[E, string][]>([]);
  const [formState, setFormState] = useState<F>(initialState);
  const [defaultFormState, setDefaultFormState] = useState<F>(initialState);

  // Set default values for form fields after the entity has been loaded
  useEffect(() => {
    if (entityResult.status === "ready") {
      setDefaultFormState((current) => {
        return {
          ...current,
          ...stateMapper(entityResult.value),
        };
      });
      setFormErrors([]);
      setFormState((current) => {
        return {
          ...current,
          ...stateMapper(entityResult.value),
        };
      });
    }
  }, [stateMapper, entityResult]);

  const resetForm = () => {
    if (entityResult.status === "ready") {
      setFormErrors([]);
      setFormState(defaultFormState);
    }
  };

  const isFormDirty = () => {
    return !isEqual(formState, defaultFormState);
  };

  const setBothFormStates = (newFormState: SetStateAction<F>) => {
    setFormState(newFormState);
    setDefaultFormState(newFormState);
  };

  return {
    formState,
    setFormState,
    formResetState: defaultFormState,
    setFormResetState: setDefaultFormState,
    formErrors,
    setFormErrors,
    resetForm,
    isFormDirty,
    setBothFormStates,
  };
};
