import { dropWhile } from "lodash";

export interface UrlHierarchy {
  rootLabel: string;
  items: HierarchyItem[];
}

export interface HierarchyItem {
  matches: RegExp;
  label?: string;
  children?: HierarchyItem[];
  /**
   * If true, then this part does not appear in the top bar. It exists only as a part of the URL, grouping together
   * child sections.
   */
  invisible?: boolean;
}

export interface BreadcrumbSection {
  label: string;
  link: string;
}

/**
 * Creates a list of currently active breadcrumbs based on the current application URL path, a definition for the page
 * hierarchy, and a mapper that produces user-friendly labels for hierarchy items. The page hierarchy is a nested
 * structure consisting of nodes (defined by {@link HierarchyItem}). Each node can have any number of child-nodes, and
 * defines what kind of URLs it matches. Matching is performed by splitting the current url path on every slash, and
 * then matching each part against a level of nodes: first the function looks for a match in the list of items defined
 * in the items of the url hierarchy. Then, assuming a match is found and there are further parts to process, it looks
 * for a match for the next part in the children of the matched item. This process is then continued recursively until
 * no more path parts remain or no more matches are found.
 *
 * Note that the matching greedily chooses the first item that matches the url. Therefore, if for some reasons your
 * application has sibling URLs where one is a prefix of the other, you'll need to either write the matcher regexes so
 * that they correctly contain start- and end-string characters, or order the items so that the correct match is always
 * found.
 */
export const createBreadcrumbs: (
  currentPath: string,
  urlHierarchy: UrlHierarchy,
  labelMapper?: (from: string) => string
) => BreadcrumbSection[] = (currentPath, urlHierarchy, labelMapper) => {
  let pathParts = dropWhile(currentPath.split("/"), (part) => part === "");
  let parsedParts: BreadcrumbSection[] = [
    {
      label: labelMapper ? labelMapper(urlHierarchy.rootLabel) : urlHierarchy.rootLabel,
      link: "/",
    },
  ];
  let nextMatches: HierarchyItem[] = urlHierarchy.items;

  // We need the full path for each item, so incrementally build it from nothing with each processed part using this
  // variable
  let path = "";

  for (let pathPart of pathParts) {
    path += "/" + pathPart;
    let matchingPart = nextMatches.find((potentialMatch) => potentialMatch.matches.exec(pathPart));
    if (matchingPart) {
      let label = pathPart;
      if (matchingPart.label && labelMapper) {
        label = labelMapper(matchingPart.label);
      } else if (matchingPart.label) {
        label = matchingPart.label;
      }
      if (matchingPart.invisible !== true) {
        parsedParts.push({
          label: label,
          link: path,
        });
      }

      if (matchingPart.children) {
        nextMatches = matchingPart.children;
      } else {
        nextMatches = [];
      }
    } else {
      console.warn(`Could not find a match for url part '${pathPart}`);
    }
  }

  return parsedParts;
};

export {};
