import React, { CSSProperties, ReactNode } from "react";
import { Icon } from "semantic-ui-react";
import styles from "./FormCustomField.module.css";
import { useRelevantErrors } from "./TypedForm";
import { HelpText } from "../help/HelpText";
import { Flex } from "../flex/Flex";

export interface FormCustomFieldProps<Error> {
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  helpText?: string;
  children?: ReactNode;
  style?: CSSProperties;
}

/**
 * A field inside a typed form that allows displaying a label and errors in the usual way, and then any custom content
 * underneath it.
 */
export const FormCustomField = <Error,>(props: FormCustomFieldProps<Error>) => {
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  return (
    <Flex column style={props.style}>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      {props.children}
    </Flex>
  );
};
