import { IntRange } from "../../commons-ts-utils/utils/types";

/**
 * An either-type for the response of a request made with `makeRequest`. The two generic arguments, `T` and `E`,
 * specify the return body for a successful (http status 200) and unsuccessful response.
 */
export type CustomResponse<T, E> =
  | {
      status: 200;
      body: T;
    }
  | {
      status: IntRange<400, 600>;
      body: E;
    };

/**
 * A request generator receives these props when the request function is generated. Applications may use these to
 * attach additional logic to request execution, such as performing operations on failed requests.
 */
export interface RequestGeneratorProps {
  /**
   * Invoked when a request fails due to being considered unauthenticated (HTTP 401 by default). Can be used to, for
   * example, redirect the user to login screen due to an expired session. If [onPerformReauthenticate] is defined, then
   * that is invoked first before this.
   */
  onForbiddenRequest: () => void;
  /**
   * An optional function that, if defined, is invoked when a request fails due to the user not being authenticated.
   * This is invoked before [onForbiddenRequest], but only once per request. Subsequent unauthenticated-failures cause
   * the [onForbiddenRequest]-function to be invoked.
   */
  onPerformReauthenticate?: () => Promise<boolean>;
  /**
   * Invoked when a request fails due to an unexpected error, such as HTTP 500 or a network problem. If the unexpected
   * error is an unexpected http status, then the status is passed as the first argument.
   */
  onUnexpectedError: (httpStatus?: number) => void;
  /**
   * Invoked when a request fails due to a conflict, i.e. HTTP status 409. E.g. if editing some resource causes an
   * optimistic locking failure.
   */
  onConflict: () => void;
  /**
   * The default service used when no service is specifically defined.
   */
  defaultService: string;
  /**
   * An object that defines which base URL is used with each service used.
   */
  serviceUrlMapping: Record<string, string>;
  /**
   * An optional function that can be given to create the Promise-object for the request. The default implementation,
   * which is used if this property is undefined, will create the promise using `fetch`.
   */
  createRequestPromise?: CreateRequestPromise;
}

/**
 * The properties that can be passed in the {@link useRequest} hook to override properties given to the request
 * generator.
 */
export type RequestGeneratorAdditionalProps = RequestGeneratorProps;

export type CreateRequestPromise = (
  url: string,
  method: "GET" | "PUT" | "PATCH" | "POST" | "DELETE",
  body: string | undefined
) => Promise<Response>;

/**
 * A typealias for a request function, that is a function that when given some params as an argument, performs an
 * asynchronous request and returns a Promise that will yield the response to the request.
 * @template P the type for the parameters accepted by the request function, that is the values that the function uses
 *           to create the search url, request body, and other such values.
 * @template T the return type from the request for successful calls.
 * @template E the return type from the request for failed calls.
 */
export type RequestFunction<P, T, E> = (params: P) => Promise<CustomResponse<T, E>>;

/**
 * A typealias for a function returning a request function. This function is essentially the definition of a call, which
 * the `useRequest` hook (among others) uses to create a callable, Promise-returning request.
 */
export type RequestGenerator<P, T, E> = (props: RequestGeneratorProps) => RequestFunction<P, T, E>;

export type FetchResult<T> =
  | {
      status: "fetching" | "error";
    }
  | {
      value: T;
      status: "ready";
    };

/**
 * A wrapper type for creating query parameters. Automatically adds properties for page, pageSize, and sort to the given
 * type `P`.
 */
export interface Query {
  page?: number;
  pageSize?: number;
  sort: string;
}

export const defaultQuerySerializer = (key: string, value: any): any => {
  switch (key) {
    case "deleted":
      return value.toString();
    default:
      return value;
  }
};

export const defaultQueryDeserializer = (key: string, value: string): any => {
  switch (key) {
    case "page":
    case "pageSize":
      return parseInt(value);
    case "deleted":
      return value === "true";
    default:
      return value;
  }
};

/**
 * A common supertype for paged search results. The `pageElements` contain the results for the current page, and the
 * other props signal information about the pages of the entire result set.
 */
export type PagedResults<R> = {
  pageElements: R[];
  totalElements: number;
  pageSize: number;
  currentPage: number;
  totalPages: number;
  firstResult: number;
  lastResult: number;
};
