import * as React from "react";
import { ModalContent as SemanticModalContent } from "semantic-ui-react";
import { Flex, FlexProps } from "../flex/Flex";

export interface ModalContentProps extends Omit<FlexProps, "width"> {
  /** An element type to render as (string or function). */
  as?: any;

  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;

  /** A modal can contain image content. */
  image?: boolean;

  /** A modal can use the entire size of the screen. */
  scrolling?: boolean;
}

/**
 * The content of a {@link Modal}. Currently, just a wrapper around Semantic UI `ModalContent` but so that the children
 * are wrapped inside a {@link Flex} which will receive any props from {@link ModalContentProps} that are defined in
 * {@link FlexProps} (except `width` which will always be `"fill"`). By default, the {@link Flex}'s direction is set to
 * `column`.
 *
 * Use this as a child of a {@link Modal}.
 * @param props
 * @constructor
 */
export const ModalContent = (props: ModalContentProps) => {
  const { as, children, className, image, scrolling, column, ...flexProps } = props;
  return (
    <SemanticModalContent as={as} className={className} image={image} scrolling={scrolling}>
      <Flex width="fill" column={column ?? true} {...flexProps}>
        {children}
      </Flex>
    </SemanticModalContent>
  );
};
