/**
 * The definition for a GET request. Similar to other requests, except that we don't allow a body.
 */
import { CustomResponse, RequestGeneratorProps } from "./models";
import { objectToArray } from "./objectProcessingUtils";

export interface GetRequestParameters {
  method: "GET";
}

/**
 * The definition for non-GET requests used by the application.
 */
export interface OtherRequestParameters {
  method: "DELETE" | "PATCH" | "POST" | "PUT";
  body?: any;
}

/**
 * Common request parameters for all requests.
 */
export interface CommonRequestParameters<T, E> {
  url: string;
  props: RequestGeneratorProps;
  /**
   * An optional property to customize how the request JSON is parsed. If this is defined, this function will be passed
   * on to {@link JSON#parse} which will call it for every key-value pair that is parsed.
   * @param key
   * @param value
   */
  jsonReviver?: (key: string, value: any) => any;
  /**
   * If this is defined, this request connects to this service instead of `props.defaultService`.
   */
  service?: string;
  /**
   * If this is defined, the response from the server to this request will be processed with this function. If this
   * function returns {@link ConflictResponse}, {@link UnknownErrorResponse}, or {@link UnauthorizedResponse}, the
   * default callbacks for these responses will be called. If this function returns a {@link AcceptedResponse}, the
   * {@link CustomResponse} inside it is passed to the code that is using the request, and it can handle the response.
   * @param response
   */
  processResponse?: (response: Response) => ProcessedResponse<T, E>;
}

export type AcceptedResponse<T, E> = {
  type: "accepted";
  response: CustomResponse<T, E>;
};

/**
 * This type indicates that a conflict (HTTP status 409) happened when trying to make a HTTP request.
 * {@link RequestGeneratorProps.onConflict} will be called.
 */
export type ConflictResponse = {
  type: "conflict";
};

/**
 * This type indicates that an HTTP request returned an unknown HTTP status.
 * {@link RequestGeneratorProps.onUnexpectedError} will be called.
 */
export type UnknownErrorResponse = {
  type: "unknownError";
  status: number;
};

/**
 * This type indicates that an HTTP request failed because the user is not authorized.
 * {@link RequestGeneratorProps.onForbiddenRequest} will be called which usually means that the user will be logged out.
 */
export type UnauthorizedResponse = {
  type: "unauthorized";
};

export type ProcessedResponse<T, E> =
  | AcceptedResponse<T, E>
  | ConflictResponse
  | UnknownErrorResponse
  | UnauthorizedResponse;

export type MakeRequestParameters<T, E> = CommonRequestParameters<T, E> &
  (GetRequestParameters | OtherRequestParameters) & {
    jsonReplacer?: (key: string, value: any) => any;
  };

/**
 * Creates a request based on the given `params`. The return value is a promise returning a `CustomResponse` with the
 * supplied generic return types, `T` for a successful call and `E` for a failed call. Unless
 * {@link MakeRequestParameters.processResponse} is defined, certain HTTP status responses will trigger callbacks:
 * - 401: {@link RequestGeneratorProps.onForbiddenRequest} which usually means that the user will be logged out.
 * - 409: {@link RequestGeneratorProps.onConflict} will be called.
 * - Any other status code except 200, 400, or 404: {@link RequestGeneratorProps.onUnexpectedError} will be called.
 */
export function makeRequest<T, E>(params: MakeRequestParameters<T, E>): Promise<CustomResponse<T, E>> {
  const service = params.service ?? params.props.defaultService;
  const baseUrl = params.props.serviceUrlMapping[service];
  const processResponse =
    params.processResponse !== undefined
      ? async (response: Response) => {
          return params.processResponse!!(response);
        }
      : async (response: Response) => {
          if (response.status === 401) {
            return { type: "unauthorized" };
          }
          if (response.status === 200 || response.status === 400 || response.status === 404) {
            let httpStatus = response.status;
            const responseBody = await response.text();
            let jsonBody = undefined;
            if (responseBody) {
              jsonBody = JSON.parse(responseBody, params.jsonReviver);
            }
            return {
              type: "accepted",
              response: {
                status: httpStatus,
                body: jsonBody,
              },
            };
          } else if (response.status === 409) {
            return {
              type: "conflict",
            };
          } else {
            return {
              type: "unknownError",
              status: response.status,
            };
          }
        };

  if (baseUrl === undefined || baseUrl === null || baseUrl.trim() === "") {
    throw Error(`No URL mapping found for service '${service}'!`);
  }

  const url = `${baseUrl}/${params.url}`;

  const createPromise = () => {
    let body = undefined;
    if (params.method !== "GET" && params.body !== undefined) {
      body = JSON.stringify(params.body, params.jsonReplacer);
    }

    let promise;
    if (params.props.createRequestPromise) {
      promise = params.props.createRequestPromise(url, params.method, body);
    } else {
      promise = fetch(url, {
        method: params.method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });
    }

    return promise.then(
      async (response) => {
        const processedResponse = await processResponse(response);
        if (processedResponse.type === "accepted") {
          return Promise.resolve(processedResponse);
        } else {
          return Promise.reject(processedResponse);
        }
      },
      (rejection) => {
        params.props.onUnexpectedError();
        return rejection;
      }
    );
  };

  return createPromise()
    .then(
      (response) => response,
      (rejection: ProcessedResponse<T, E>) => {
        if (rejection.type === "unauthorized" && params.props.onPerformReauthenticate !== undefined) {
          return params.props.onPerformReauthenticate().then(
            (authOk) => {
              if (authOk) {
                return createPromise();
              } else {
                // If the reauthentication did not succeed, then proceed with the original response
                return Promise.reject(rejection);
              }
            },
            () => {
              // An unexpected error also means that the reauth did not succeed
              return Promise.reject(rejection);
            }
          );
        } else {
          // If the error was something other than unauth (or we do not have a reauth-handler defined), then just
          // return the rejection as it is
          return Promise.reject(rejection);
        }
      }
    )
    .then(
      (acceptedResponse) => {
        return acceptedResponse.response;
      },
      (rejectedResponse: ProcessedResponse<T, E>) => {
        if (rejectedResponse.type === "unauthorized") {
          params.props.onForbiddenRequest();
          return Promise.reject("Request was forbidden");
        } else if (rejectedResponse.type === "conflict") {
          params.props.onConflict();
          return Promise.reject("Request failed because of a conflict.");
        } else if (rejectedResponse.type === "unknownError") {
          params.props.onUnexpectedError(rejectedResponse.status);
          return Promise.reject(`Unhandled http status code: ${rejectedResponse.status}`);
        } else {
          params.props.onUnexpectedError();
          return Promise.reject(`Unknown processed response type: ${rejectedResponse.type}`);
        }
      }
    );
}

/**
 * A helper function that generates a URL with the given queryParameters encoded after it. Any empty or undefined values
 * from the `queryParams` will not be added to the URL.
 *
 * If the `queryParams` contains nested objects, the objects will be reduced to primitive values so that each key is a
 * path to a primitive value.
 *
 * Note: nested objects in arrays are not currently supported by this function.
 */
export const makeURL = (url: string, queryParams: any) => {
  let filteredParams: [string, any][];
  if (queryParams) {
    filteredParams = objectToArray(queryParams);
  } else {
    filteredParams = [];
  }

  if (filteredParams && filteredParams.length > 0) {
    return `${url}?${new URLSearchParams(filteredParams).toString()}`;
  } else {
    return url;
  }
};
