import { Icon, Label as SemanticLabel, SemanticCOLORS, SemanticICONS, SemanticSIZES } from "semantic-ui-react";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import styles from "./Label.module.css";
import { Flex } from "../flex/Flex";

export interface LabelProps extends StandardComponentProps {
  text?: string;
  circular?: boolean;
  /**
   * If true, then text inside this label is allowed to wrap on multiple lines
   */
  wrap?: boolean;
  /**
   * A callback thats invoked when the icon of this label is clicked.
   */
  onIconClick?: () => void;
  /**
   * An icon that is displayed after the text inside this label.
   */
  icon?: SemanticICONS;
  size?: SemanticSIZES;
  interactable?: boolean;
}

export const Label = ({ text, size, wrap, circular, onIconClick, interactable, icon, color, ...other }: LabelProps) => {
  const { className, style } = resolveStandardStyling({
    margin: 0,
    conditionalClassNames: [
      [!wrap, styles.noWrap],
      [interactable, styles.interactable],
    ],
    ...other,
  });

  // TODO support all colors?
  let semanticColor: SemanticCOLORS | undefined = undefined;
  if (color !== "transparent" && color !== "light green") {
    semanticColor = color;
  }

  return (
    <SemanticLabel
      size={size}
      empty={text === undefined}
      circular={circular}
      color={semanticColor}
      className={className}
      style={style}
    >
      <Flex alignItems="center" justifyContent={icon === undefined ? "center" : undefined} gap={1}>
        {text}
        {icon !== undefined ? (
          <Icon
            name={icon}
            onClick={
              onIconClick && interactable
                ? (event: MouseEvent) => {
                    event.stopPropagation();
                    onIconClick();
                  }
                : undefined
            }
            style={{ margin: 0, cursor: onIconClick && interactable ? "pointer" : undefined }}
          />
        ) : (
          ""
        )}
      </Flex>
    </SemanticLabel>
  );
};
