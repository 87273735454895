import React from "react";
import {
  Button as SemanticButton,
  ButtonGroup as SemanticButtonGroup,
  Icon,
  SemanticCOLORS,
  SemanticFLOATS,
  SemanticICONS,
  SemanticSIZES,
} from "semantic-ui-react";
import { TooltipWrapper } from "../tooltip/TooltipWrapper";
import styles from "./Button.module.css";
import { FormMode } from "../form/TypedForm";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { TextDisplay, TextSize } from "../text/TextDisplay";

interface CommonProps extends StandardComponentProps {
  /**
   * The HTML id-attribute for this element.
   */
  id?: string;
  /**
   * A floating tooltip displayed when this button is hovered.
   */
  tooltip?: string;
  /**
   * An optional prop that can be used to hide the button. A hidden element takes up space but is not displayed or
   * interactable.
   */
  hidden?: boolean;
  /**
   * An optional prop that can be used to disable the button. A disabled element is grayed out and is not interactable.
   */
  disabled?: boolean;
  /**
   * An optional prop that can be passed to control the behaviour of the button according to a page's {@link FormMode}.
   * Sets the correct values for {@link hidden} and {@link disabled}, unless non-undefined values are passed in those
   * props.
   */
  formMode?: FormMode;
  /**
   * An optional prop that can be used to make the element "basic", giving it a less pronounced look.
   */
  basic?: boolean;
  /**
   * An optional prop that can be used to make the element "primary", giving it a more accented look.
   */
  primary?: boolean;
  /**
   * An optional prop that can be used to make the element "secondary", giving it a more accented look that differs from
   * 'primary'.
   */
  secondary?: boolean;
  /**
   * An optional prop that can be used to give the element a "positive" look, indicating a positive action (such as a
   * confirmation) of some sort.
   */
  positive?: boolean;
  /**
   * An optional prop that can be used to make the element "negative", giving it a certain kind of accented look.
   */
  negative?: boolean;
  /**
   * An optional prop that can be used to make the element slightly smaller.
   */
  compact?: boolean;
  /**
   * The type of button that this button is. If not specified, defaults to "button".
   */
  type?: "button" | "submit" | "reset";
  /**
   * User-visible text displayed in this button.
   */
  text?: string;
  /**
   * The size of the text displayed in this button.
   */
  textSize?: TextSize;
  /**
   * Should the text displayed in this button be emphasized.
   */
  emphasizeText?: boolean;
  /**
   * The callback invoked when this button is clicked.
   */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * Controls the size of the button.
   */
  size?: SemanticSIZES;
  /**
   * Controls the color of the button.
   */
  color?: SemanticCOLORS;
  /**
   * Indicates that the button is currently an active selection in a group.
   */
  active?: boolean;
  /**
   * A property that can be used to render the button as some other component, such as a Link.
   */
  as?: {
    component: any;
    props: { [key: string]: any };
  };
  /**
   * An optional property that can be used to float the button to a side of its container.
   */
  floated?: SemanticFLOATS;
  /**
   * An optional property that, when true, will replace the button's contents with a loader spinner.
   */
  loading?: boolean;
  /**
   * Inverts the button's colors if true.
   */
  inverted?: boolean;
}

/**
 * Props for button icons. Icons can either be specified in a simple format, giving only their name, or a more complex
 * format with more attributes such as the color of the icon.
 */
export type IconProps =
  | SemanticICONS
  | {
      name: SemanticICONS;
      color?: SemanticCOLORS;
      inverted?: boolean;
    };

const resolveIconName = (icon: IconProps | undefined): SemanticICONS | undefined => {
  if (icon === undefined) {
    return undefined;
  } else if (typeof icon === "string") {
    return icon;
  } else {
    return icon.name;
  }
};

const resolveIconColor = (icon: IconProps | undefined): SemanticCOLORS | undefined => {
  if (icon === undefined) {
    return undefined;
  } else if (typeof icon === "string") {
    return undefined;
  } else {
    return icon.color;
  }
};

const resolveIsInverted = (icon: IconProps | undefined): boolean | undefined => {
  if (icon === undefined) {
    return undefined;
  } else if (typeof icon === "string") {
    return false;
  } else {
    return icon.inverted ?? false;
  }
};

interface SimpleIconButtonProps {
  /**
   * The single icon displayed in this button.
   */
  icon?: IconProps;
}

interface ComplexIconButtonProps {
  /**
   * The icon displayed on the left side of this button.
   */
  leftIcon?: IconProps;
  /**
   * The icon displayed on the right side of this button.
   */
  rightIcon?: IconProps;
}

export type ButtonProps = CommonProps & (SimpleIconButtonProps | ComplexIconButtonProps);

export const Button = (props: ButtonProps) => {
  const {
    id,
    tooltip,
    hidden,
    disabled,
    type,
    basic,
    primary,
    secondary,
    positive,
    text,
    textSize,
    emphasizeText,
    compact,
    onClick,
    formMode,
    size,
    negative,
    color,
    active,
    as,
    inverted,
    floated,
    loading,
    marginRight,
    padding,
    ...standardProps
  } = props;

  const isHidden = hidden ?? (formMode !== undefined && formMode === "view");

  const { className, style } = resolveStandardStyling({
    ...standardProps,
    // For whatever reason, semantic UI seems to add a small right margin to buttons. This makes layouts created using
    // "standard components" a bit off, so we supply a default of 0 here.
    marginRight: marginRight ?? 0,
    padding: padding ?? compact ? 1 : 2,
    conditionalClassNames: [
      [isHidden, styles.hiddenButton],
      [true, styles.baseButton],
    ],
  });

  let leftIcon: IconProps | undefined = undefined;
  let rightIcon: IconProps | undefined = undefined;
  if ("icon" in props) {
    leftIcon = props.icon;
  } else if ("leftIcon" in props) {
    leftIcon = props.leftIcon;
  }

  if ("rightIcon" in props) {
    rightIcon = props.rightIcon;
  }

  let singleIconOnly = leftIcon !== undefined && rightIcon === undefined && (text === undefined || text === "");

  const isDisabled = disabled ?? (formMode !== undefined && formMode !== "edit");

  return (
    <TooltipWrapper tooltip={tooltip}>
      <SemanticButton
        id={id}
        className={className}
        style={style}
        loading={loading}
        floated={floated}
        inverted={inverted}
        as={as?.component}
        {...as?.props}
        active={active}
        color={color}
        disabled={isDisabled || isHidden}
        basic={basic}
        primary={primary}
        secondary={secondary}
        positive={positive}
        negative={negative}
        type={type ?? "button"}
        icon={
          singleIconOnly ? (
            <Icon
              name={resolveIconName(leftIcon)}
              color={resolveIconColor(leftIcon)}
              inverted={resolveIsInverted(leftIcon)}
              className={styles.leftIcon}
            />
          ) : undefined
        }
        size={size}
        onClick={(event) => {
          event.stopPropagation();
          if (onClick) {
            onClick(event);
          }
        }}
      >
        {singleIconOnly ? undefined : (
          <>
            {leftIcon ? (
              <Icon
                className={styles.leftIcon}
                name={resolveIconName(leftIcon)}
                color={resolveIconColor(leftIcon)}
                inverted={resolveIsInverted(leftIcon)}
              />
            ) : undefined}
            {text !== undefined ? (
              <TextDisplay noWrap value={text} size={textSize} emphasized={emphasizeText} />
            ) : undefined}
            {rightIcon ? (
              <Icon
                className={styles.rightIcon}
                name={resolveIconName(rightIcon)}
                color={resolveIconColor(rightIcon)}
                inverted={resolveIsInverted(rightIcon)}
              />
            ) : undefined}
          </>
        )}
      </SemanticButton>
    </TooltipWrapper>
  );
};

export const ButtonGroup = SemanticButtonGroup;
