import React, { useEffect, useState } from "react";

import { Link, useParams } from "react-router-dom";
import { Button, Container, Icon, Placeholder, Popup } from "semantic-ui-react";
import { toNumber } from "lodash";
import { useTranslator } from "../../../../i18n/hooks";
import {
  deleteProxyTarget,
  getProxyTargetById,
  getProxyTargetToken,
  StoreProxyTargetErrorCode,
  undeleteProxyTarget,
  updateProxyTarget,
} from "../../../../api/services/proxyTargets";
import ProxyTargetForm, { ProxyTargetFormFields } from "./ProxyTargetForm";
import UpdateTargetLicenseModal from "./UpdateTargetLicenseModal";
import styles from "./ViewTargetsScreen.module.css";
import { ProxyTarget } from "../../../../api/model/ProxyTarget";
import {
  createEditTitlePrimaryActions,
  createViewTitlePrimaryActions,
  createViewTitleSecondaryActions,
} from "../../../../util/TitleActions";
import { useResult, useTriggerableResult } from "../../../../ts-commons/commons-react-hooks/request/useResult";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { FormMode } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { useFormState } from "../../../../ts-commons/commons-react-hooks/form/useFormState";
import { Title, TitleAction } from "../../../../ts-commons/commons-react-components/title/Title";
import { MonospaceTextSegment } from "../../../../ts-commons/commons-react-components/text-segment/MonospaceTextSegment";
import { SimpleCard } from "../../../../ts-commons/commons-react-components/card/SimpleCard";
import { useCancelModal } from "../../../../ts-commons/commons-react-components/modal/CancelModal";
import { Flex } from "../../../../ts-commons/commons-react-components/flex/Flex";

const targetToFormState = (target: ProxyTarget): Partial<ProxyTargetFormFields> => {
  const { name, status, loginPath, targetUrl, accessControlList } = target;
  return { name, status, loginPath, targetUrl, accessControlList };
};

const ViewProxyTargetScreen: React.FC = () => {
  const { targetId } = useParams();
  const t = useTranslator("targets");
  const ct = useTranslator("common");

  const [targetResult, refreshTarget] = useResult(getProxyTargetById, { id: toNumber(targetId) });
  const [accessTokenResult, refreshToken] = useTriggerableResult(getProxyTargetToken, { id: toNumber(targetId) });
  const deleteSuffix = targetResult.status === "ready" && targetResult.value.deleted != null ? " (deleted)" : "";

  const saveTarget = useRequest(updateProxyTarget);
  const delTarget = useRequest(deleteProxyTarget);
  const unDelTarget = useRequest(undeleteProxyTarget);

  const [copyPopupVisible, setCopyPopupVisible] = useState(false);
  const [formMode, setFormMode] = useState<FormMode>("view");

  const { formState, setFormState, setFormResetState, formErrors, setFormErrors, resetForm, isFormDirty } =
    useFormState<ProxyTargetFormFields, ProxyTarget, StoreProxyTargetErrorCode>(
      {
        name: "",
        status: "ACTIVE",
        targetUrl: "",
        loginPath: "",
        licenseKey: "",
        accessControlList: "",
      },
      targetResult,
      targetToFormState
    );

  const { CancelModal, setCancelModalOpen } = useCancelModal(() => {
    resetForm();
    setFormMode("view");
  });

  const errorMapping = {
    NAME_RESERVED: "view.error.nameReserved",
    INVALID_NAME: "view.error.invalidName",
    INVALID_LOGIN_PATH: "view.error.invalidLoginPath",
    INVALID_TARGET_URL: "view.error.invalidTargetUrl",
    INVALID_LICENSE_KEY: "view.error.invalidLicenseKey",
    INVALID_ACCESS_CONTROL_LIST: "view.error.invalidAccessControlList",
  };

  useEffect(() => {
    let interval: any = null;
    if (copyPopupVisible) {
      interval = setInterval(() => {
        setCopyPopupVisible(false);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [copyPopupVisible]);

  useEffect(() => {
    if (accessTokenResult.status === "ready" && navigator.clipboard) {
      navigator.clipboard.writeText(accessTokenResult.value.token).then(() => {
        setCopyPopupVisible(true);
      });
    }
  }, [accessTokenResult]);

  const onSaveTarget = () => {
    setFormMode("submit");
    setFormErrors([]);
    saveTarget({ id: toNumber(targetId), target: formState }).then(
      (response) => {
        if (response.status === 200) {
          setFormMode("view");
          setFormResetState(formState);
        } else {
          const errorCode = response.body.errorCode;
          setFormErrors([[errorCode, t(errorMapping[errorCode])]]);
          setFormMode("edit");
        }
      },
      () => {
        setFormMode("edit");
      }
    );
  };

  const onCancelEdit = () => {
    if (isFormDirty()) {
      setCancelModalOpen(true);
    } else {
      resetForm();
      setFormMode("view");
    }
  };

  const onDeleteTarget = () => {
    delTarget({ id: toNumber(targetId) }).then((response) => {
      if (response.status === 200) {
        refreshTarget();
      }
    });
  };

  const onUndeleteTarget = () => {
    unDelTarget({ id: toNumber(targetId) }).then((response) => {
      if (response.status === 200) {
        refreshTarget();
      }
    });
  };

  const createTitleActions: () => TitleAction[] = () => {
    if (formMode === "view") {
      return createViewTitlePrimaryActions(ct, () => setFormMode("edit"));
    } else {
      return createEditTitlePrimaryActions(ct, formMode, onSaveTarget, onCancelEdit);
    }
  };

  const createSecondaryActions: () => TitleAction[] = () => {
    if (formMode === "view") {
      if (targetResult.status === "ready" && targetResult.value.deleted != null) {
        return createViewTitleSecondaryActions(ct, () => {}, onUndeleteTarget, true);
      } else {
        return createViewTitleSecondaryActions(ct, onDeleteTarget, () => {}, false);
      }
    } else {
      return [];
    }
  };

  return (
    <Container>
      <Title
        loading={targetResult.status === "fetching"}
        submitting={formMode === "submit"}
        icon="sitemap"
        title={
          formMode === "view"
            ? t("view.titleView", {
                name: targetResult.status === "ready" ? targetResult.value.name + deleteSuffix : "",
              })
            : t("view.titleEdit", { name: targetResult.status === "ready" ? targetResult.value.name : "" })
        }
        primaryActions={createTitleActions()}
        secondaryActions={createSecondaryActions()}
        errorHighlight={formErrors.length > 0}
      />
      {targetResult.status !== "ready" ? (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <Flex gap={2} column>
          <ProxyTargetForm
            formState={formState}
            setFormState={setFormState}
            mode={formMode}
            errors={formErrors}
            enableLicenseKey={false}
          />

          <SimpleCard title={t("view.tokenInfoTitle")}>
            <div className={styles.flexRow}>
              <MonospaceTextSegment
                className={`${styles.accessTokenField} ${
                  accessTokenResult.status !== "ready" ? styles.noTokenField : ""
                }`}
                text={
                  accessTokenResult.status === "ready" ? accessTokenResult.value.token : t("view.noTokenPlaceholder")
                }
              />
              <Popup
                content={t("view.tokenCopiedInfo")}
                open={copyPopupVisible}
                trigger={<Button basic icon="refresh" onClick={refreshToken} />}
              />
            </div>
          </SimpleCard>

          <Flex>
            <UpdateTargetLicenseModal
              trigger={
                <Button basic disabled={formMode !== "view"}>
                  <Icon name="lock" />
                  {t("view.updateLicenseKeyButton")}
                </Button>
              }
            />
            <Button
              disabled={formMode !== "view"}
              as={Link}
              to={`/monitor?proxyTargetId=${targetResult.value.id}`}
              basic
            >
              {t("view.viewEventsLink")}
              <Icon name="angle right" />
            </Button>
          </Flex>

          <CancelModal />
        </Flex>
      )}
    </Container>
  );
};

export default ViewProxyTargetScreen;
