import * as React from "react";
import { ModalHeader as SemanticModalHeader } from "semantic-ui-react";
import { Flex, FlexProps } from "../flex/Flex";

export interface ModalHeaderProps extends Omit<FlexProps, "width"> {
  /** An element type to render as (string or function). */
  as?: any;

  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;
}

/**
 * The header of a {@link Modal}. Currently, a wrapper around Semantic UI `ModalHeader` but so that the children
 * are wrapped inside a {@link Flex} which will receive any props from {@link ModalContentProps} that are defined in
 * {@link FlexProps} (except `width` which will always be `"fill"`).
 *
 * Use this as a child of a {@link Modal}.
 */
export const ModalHeader = (props: ModalHeaderProps) => {
  const { as, className, children, ...flexProps } = props;
  return (
    <SemanticModalHeader as={as} className={className}>
      <Flex width="fill" {...flexProps}>
        {children}
      </Flex>
    </SemanticModalHeader>
  );
};
