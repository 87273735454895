import React from "react";
import { Icon, Popup } from "semantic-ui-react";
import styles from "../form/FormHelpText.module.css";

export interface HelpTextProps {
  text: string;
}

/**
 * This component can be used to display a "help icon". When hovering over the icon, a popup with the `text` attribute
 * is displayed.
 *
 * This component is used internally by different typed form components when `helpText` attribute is given.
 */
export const HelpText = ({ text }: HelpTextProps) => {
  return (
    <Popup trigger={<Icon name="help circle" color="blue" className={styles.formHelpIcon} />} wide="very">
      {text}
    </Popup>
  );
};
