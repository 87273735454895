import React, { KeyboardEvent, useContext, useEffect, useRef, useState } from "react";
import { FormContext, useRelevantErrors } from "./TypedForm";
import styles from "./FormMultiInput.module.css";
import { Icon } from "semantic-ui-react";
import { HelpText } from "../help/HelpText";
import { Flex } from "../flex/Flex";
import { Label } from "../label/Label";

export interface FormMultiInputProps<Fields, Error> {
  formField: keyof Fields & string;
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  helpText?: string;
}

/**
 * This component can be used to add an input-element that can be used to input multiple distinct value to a typed form.
 * A value is inputted by typing it to the input and adding it by pressing enter or clicking the checkmark at the end
 * of the input.
 *
 * The following properties control the behaviour of this component:
 * - formField: the field of the form's state that this input uses, i.e. which member of the form state should be
 *              displayed in the field and modified when a user interacts with this input.
 * - label: a label to display on top of the field, to inform the user what the use for this input is.
 * - disabled: whether the input should be disabled, that is non-interactable for the user.
 * - relatedErrors: which errors of the form state relate to this particular field. If the form state contains one of
 *                  the errors listed here, the input will be highlighted with a warning color and the user-friendly
 *                  error text of the specific error will be displayed next to the field. The input field has been
 *                  implemented so that its size should not grow when errors become visible, but this may cause some
 *                  errors to not show up if many of them are displayed at once.
 * - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *              this text as a popup.
 */
export const FormMultiInput = <Fields, Error>(props: FormMultiInputProps<Fields, Error>) => {
  const formContext = useContext(FormContext);

  const [currentInputtedValue, setCurrentInputtedValue] = useState("");

  const relevantErrors = useRelevantErrors(props.relatedErrors);

  const resolveContainerClass = (): string => {
    if (formContext.mode === "view") {
      return styles.containerViewMode;
    } else if (relevantErrors.length > 0) {
      return styles.containerHasError;
    } else {
      return styles.containerEditMode;
    }
  };

  const resolveBorderClass = (): string => {
    if (formContext.mode === "view") {
      return styles.bordersViewMode;
    } else if (relevantErrors.length > 0) {
      return styles.bordersError;
    } else {
      return styles.bordersEditMode;
    }
  };

  const resolveInputClass = (): string => {
    if (formContext.mode === "view") {
      return styles.inputViewMode;
    } else {
      return "";
    }
  };

  const resolveCurrentSelectedValues = () => {
    if (formContext.state) {
      const fieldValue = formContext.state[props.formField];
      if (fieldValue === undefined || fieldValue === null) {
        return [];
      } else {
        return fieldValue as string[];
      }
    } else {
      return [];
    }
  };

  const updateSelectedValues = (newSelectedValues: string[]) => {
    formContext.onInputChange(props.formField, newSelectedValues);
  };

  const removeSelectedValue = (removedValue: string) => {
    if (!props.disabled) {
      updateSelectedValues(resolveCurrentSelectedValues().filter((value) => value !== removedValue));
      setCurrentInputtedValue("");
    }
  };

  const addSelectedValue = (value: string) => {
    if (value.trim() !== "" && !props.disabled) {
      const selectedValues = resolveCurrentSelectedValues();
      if (selectedValues.indexOf(value) < 0) {
        updateSelectedValues(selectedValues.concat(value));
      }
      setCurrentInputtedValue("");
    }
  };

  // These hooks are used to compute the width of the input when new characters are typed into it.
  const [inputWidth, setInputWidth] = useState(0);
  const sizer = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    const currentSpan = sizer.current;
    if (currentSpan !== null) {
      // Temporarily display the sizer component, so we can compute its width
      currentSpan.style.display = "inline";
      const width = Math.ceil(currentSpan.getBoundingClientRect().width);
      setInputWidth(width);
      currentSpan.style.removeProperty("display");
    }
  }, [currentInputtedValue]);

  const inputRef = useRef<HTMLInputElement>(null);
  const handleInputEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !props.disabled) {
      addSelectedValue(currentInputtedValue);
    }
  };

  const readOnly = props.disabled ? props.disabled : formContext.mode !== "edit";

  return (
    <Flex column alignItems="baseline">
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <Flex gap={1} alignItems="center" width="fill" className={resolveBorderClass()} paddingRight={1}>
        <Flex grow>
          <div className={`${styles.container} ${resolveContainerClass()}`} onClick={() => inputRef.current?.focus()}>
            {resolveCurrentSelectedValues().map((value, index) => {
              return (
                <Label
                  className={styles.selectedValue}
                  size="small"
                  alignSelf="center"
                  interactable={!readOnly}
                  text={value}
                  key={index.toString()}
                  icon={formContext.mode !== "edit" ? "check" : "delete"}
                  onIconClick={readOnly ? undefined : () => removeSelectedValue(value)}
                />
              );
            })}
            <input
              ref={inputRef}
              className={`${styles.input} ${resolveInputClass()}`}
              name={props.formField}
              type="input"
              value={currentInputtedValue}
              readOnly={readOnly}
              disabled={formContext.mode === "submit"}
              onChange={(event) => setCurrentInputtedValue(event.target.value)}
              onBlur={() => addSelectedValue(currentInputtedValue)}
              style={{ width: inputWidth + "px" }}
              onKeyUp={handleInputEnter}
            />
            <span ref={sizer} className={styles.sizer}>
              {currentInputtedValue}
            </span>
          </div>
        </Flex>

        {formContext.mode === "edit" ? (
          <Icon
            className={styles.confirmInputButton}
            basic
            name="checkmark"
            size="small"
            onClick={() => addSelectedValue(currentInputtedValue)}
            disabled={props.disabled}
          />
        ) : (
          ""
        )}
      </Flex>
    </Flex>
  );
};
