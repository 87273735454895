import React, { useContext } from "react";
import { Icon } from "semantic-ui-react";
import styles from "./FormInput.module.css";
import { FormContext, useRelevantErrors } from "./TypedForm";
import { HelpText } from "../help/HelpText";
import { Input } from "../input/Input";
import { Flex } from "../flex/Flex";

interface FormInputCommonProps<Error> {
  label: string;
  disabled?: boolean;
  viewOnly?: boolean;
  relatedErrors: Error[];
  type?: string;
  helpText?: string;
}

export interface FormInputFieldProps<Fields, Error> extends FormInputCommonProps<Error> {
  formField: keyof Fields & string;
}

export interface FormInputControlledProps<Error> extends FormInputCommonProps<Error> {
  value: string;
  onChange: (newValue: string) => void;
}

export type FormInputProps<Fields, Error> = FormInputFieldProps<Fields, Error> | FormInputControlledProps<Error>;

/**
 * This component can be used to add an input-element to a typed form. The following properties control the behaviour
 * of this component.
 * - formField: the field of the form's state that this input uses, i.e. which member of the form state should be
 *              displayed in the field and modified when a user interacts with this input.
 * - value: an optional replcement property for formField, can be used to set the display value of the field and make it
 *          into a controllable component. Intended to be used with the onChange-property.
 * - label: a label to display on top of the field, to inform the user what the use for this input is.
 * - disabled: whether the input should be disabled, that is non-interactable for the user.
 * - relatedErrors: which errors of the form state relate to this particular field. If the form state contains one of
 *                  the errors listed here, the input will be highlighted with a warning color and the user-friendly
 *                  error text of the specific error will be displayed next to the field. The input field has been
 *                  implemented so that its size should not grow when errors become visible, but this may cause some
 *                  errors to not show up if many of them are displayed at once.
 * - type: the type of the input (i.e. "text", "password", and so on). This is passed directly to the underlying
 *         `<input>` element as its `type` attribute.
 * - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *              this text as a popup.
 */
export const FormInput = <Fields, Error>(props: FormInputProps<Fields, Error>) => {
  const formContext = useContext(FormContext);
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  const resolveValue = (): string => {
    if ("value" in props) {
      return props.value;
    } else if (formContext.state) {
      const fieldValue = formContext.state[props.formField];
      if (fieldValue === undefined || fieldValue === null) {
        return "";
      } else {
        return "" + fieldValue;
      }
    } else {
      return "";
    }
  };

  return (
    <Flex alignItems="baseline" column grow>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <Input
        alignSelf="stretch"
        type={props.type}
        value={resolveValue()}
        error={relevantErrors.length > 0}
        viewMode={formContext.mode !== "edit" || props.viewOnly}
        readOnly={(props.disabled ? props.disabled : formContext.mode !== "edit") || props.viewOnly}
        disabled={formContext.mode === "submit"}
        onChange={(newValue) => {
          if ("onChange" in props) {
            props.onChange(newValue);
          } else {
            formContext.onInputChange(props.formField, newValue);
          }
        }}
      />
    </Flex>
  );
};
