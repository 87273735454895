import React from "react";
import { Icon } from "semantic-ui-react";
import styles from "./FormErrors.module.css";
import { useRelevantErrors } from "./TypedForm";

export interface FormErrorsProps<Error> {
  displayedErrors: Error[];
}

/**
 * This view can be used to display errors inside a typed form. It accepts a list of errors to display, and renders
 * each of them on their own row, if they are found in the current form state. Note that the size of this component is
 * dynamic, and therefore its placements should be considered carefully to prevent the form or its components from
 * jumping around.
 */
export const FormErrors = <Error,>(props: FormErrorsProps<Error>) => {
  const relevantErrors = useRelevantErrors(props.displayedErrors);

  if (relevantErrors.length === 0) {
    return <React.Fragment />;
  }

  return (
    <div>
      {relevantErrors.map(([_, errorMessage]) => (
        <div className={styles.formError}>
          <Icon className={"exclamation circle"} />
          {errorMessage}
        </div>
      ))}
    </div>
  );
};
