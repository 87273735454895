import { isNaN, toNumber } from "lodash";

/**
 * Returns `true` if the given value is a number or can be converted into a number. This method is stricter than the
 * number conversion in Javascript or lodash: blank strings or nulls are not numbers (lodash converts them to 0). If
 * the value is `undefined`, `false` is returned.
 * @param value
 */
export function isNumber(value?: any): boolean {
  if (value === undefined || value === null) {
    return false;
  } else if (typeof value === "number") {
    return true;
  } else if (typeof value === "string" && value.trim() === "") {
    return false;
  } else {
    return !isNaN(toNumber(value));
  }
}

/**
 * Returns `true` if the given value is an integer or can be converted into an integer. The check if the value can be
 * converted into an integer is done with {@link isNumber}.
 * @param value
 */
export function isInteger(value?: any): boolean {
  return isNumber(value) && Number.isInteger(toNumber(value));
}

/**
 * This function compares to numbers (that can be undefined). If the first number is smaller, returns -1, if the
 * second number is smaller, return 1, and otherwise returns 0. Undefined is considered larger than all numbers.
 * @param number1
 * @param number2
 */
export function numberComparator(number1: number | undefined, number2: number | undefined): number {
  if (number1 === undefined || number2 === undefined) {
    if (number1 === undefined && number2 !== undefined) {
      return 1;
    } else if (number1 !== undefined && number2 === undefined) {
      return -1;
    } else {
      return 0;
    }
  } else if (number1 < number2) {
    return -1;
  } else if (number1 > number2) {
    return 1;
  } else {
    return 0;
  }
}
