import React, { ReactNode } from "react";
import { useTranslator } from "../i18n/hooks";
import { AuthAction, useAuthReducer } from "../reducer/authReducer";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { RequestGeneratorProps } from "../ts-commons/commons-react-hooks/request/models";
import { RequestContext, GenerateRequest } from "../ts-commons/commons-react-hooks/request/RequestContext";

export type CustomRequestGeneratorProps = RequestGeneratorProps & {
  authDispatch: React.Dispatch<AuthAction>;
  navigate: NavigateFunction;
};

export const getAuthDispatch = (props: RequestGeneratorProps) => {
  return (props as CustomRequestGeneratorProps).authDispatch;
};

export const getNavigate = (props: RequestGeneratorProps) => {
  return (props as CustomRequestGeneratorProps).navigate;
};

const AppRequestContext = ({ children }: { children: ReactNode }) => {
  const [authState, authDispatch] = useAuthReducer();
  const t = useTranslator("common");
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const generateRequest: GenerateRequest = (definition) => {
    const customProps: CustomRequestGeneratorProps = {
      onForbiddenRequest: () => {
        // If there is an authenticated user, then the person using the system has most likely had a browser tab open
        // for a very long time or the server has been restarted. In any case, the session expiring can feel
        // 'unexpected', so we display a toast to inform the user about why they need to log in again. If there is no
        // authenticated user, then most likely the person using the system has opened up a link, and needing to log
        // in is not unexpected.
        if (authState.authenticatedUser) {
          toast.error(t("error.sessionExpired"), {
            toastId: `sessionExpired_error_id`,
            position: toast.POSITION.TOP_CENTER,
          });
        }

        authDispatch({ type: "reset" });
        navigate("/login", {
          replace: true,
          state: {
            returnUrl: pathname + search,
          },
        });
      },
      onUnexpectedError: () => {
        toast.error(t("error.unexpected"), {
          toastId: "unexpected_error_id",
          position: toast.POSITION.TOP_CENTER,
        });
      },
      onConflict: () => {
        toast.error(t("error.conflict"), {
          toastId: "conflict_error_id",
          position: toast.POSITION.TOP_CENTER,
        });
      },
      defaultService: "proxy",
      serviceUrlMapping: {
        proxy: `${process.env.REACT_APP_SERVER_URL}`,
      },
      // Custom props for special requests like login
      authDispatch,
      navigate,
    };

    return definition(customProps);
  };

  return <RequestContext generateRequest={generateRequest}>{children}</RequestContext>;
};
export default AppRequestContext;
