import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";

/**
 * A simple component that can be used to add an empty space into a layout.
 */
export const Space = (props: StandardComponentProps) => {
  const { className, style } = resolveStandardStyling(props);

  return <div className={className} style={style} />;
};
