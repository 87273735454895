import { Card as SemanticCard } from "semantic-ui-react";
import { Flex, FlexProps } from "../flex/Flex";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { resolveSize } from "../../commons-ts-utils/utils/sizing";

interface CardPrivateProps {
  /**
   * If true, the card will grow to take up the available horizontal space. This is different from the grow-property
   * of {@link StandardComponentProps}, as it does not allow weighted growing. This is because the flex-basis applied
   * by the standard styling seems to mess up the semantic-ui card.
   */
  grow?: boolean;
}

export type CardProps = CardPrivateProps &
  Omit<StandardComponentProps, "grow"> &
  Pick<
    FlexProps,
    "children" | "column" | "reverse" | "alignItems" | "justifyContent" | "gap" | "rowGap" | "columnGap" | "wrap"
  >;

/**
 * A wrapper component over semantic-uis card. Contains a flex-layout inside of it.
 */
export const Card = ({
  margin,
  marginTop,
  marginBottom,
  marginRight,
  marginLeft,
  grow,
  children,
  column,
  reverse,
  alignItems,
  justifyContent,
  gap,
  rowGap,
  columnGap,
  wrap,
  ...standardProps
}: CardProps) => {
  const { style, className } = resolveStandardStyling({
    ...standardProps,
    conditionalStyles: [
      [true, () => ["marginTop", resolveSize(marginTop ?? margin ?? 0)]],
      [true, () => ["marginBottom", resolveSize(marginBottom ?? margin ?? 0)]],
      [true, () => ["marginLeft", resolveSize(marginLeft ?? margin ?? 0)]],
      [true, () => ["marginRight", resolveSize(marginRight ?? margin ?? 0)]],
    ],
  });

  return (
    <SemanticCard fluid={grow} style={style} className={className}>
      <SemanticCard.Content>
        <Flex
          column={column}
          reverse={reverse}
          alignItems={alignItems}
          justifyContent={justifyContent}
          gap={gap}
          rowGap={rowGap}
          columnGap={columnGap}
          wrap={wrap}
        >
          {children}
        </Flex>
      </SemanticCard.Content>
    </SemanticCard>
  );
};
