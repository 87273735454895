import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { resolveSize, Size } from "../../commons-ts-utils/utils/sizing";
import styles from "./Grid.module.css";
import { ReactNode } from "react";

/**
 * This interface can be used to define a `minmax` function in a {@link GridRepeat}.
 */
export interface GridMinMax {
  min: Size | number;
  max: Size | number;
}

/**
 * This interface can be used to define a `repeat` expression for {@link Grid} columns or rows. More information about
 * this can be found from e.g. https://developer.mozilla.org/en-US/docs/Web/CSS/repeat
 */
export interface GridRepeat {
  /**
   * How many columns or rows are defined. `"auto-fit"` and `"auto-fill"` create as many columns or rows that can be
   * fitted into the screen.
   */
  repeatCount: number | "auto-fit" | "auto-fill";
  /**
   * Defines the set of tracks that are repeated.
   */
  tracks: Size | GridMinMax | number;
}

export interface GridProps extends StandardComponentProps {
  /**
   * Child elements rendered inside this element. It is recommended that all children are of the type {@link Cell}.
   */
  children?: ReactNode;
  /**
   * An attribute to control the value of the grid-template-rows CSS property. If a {@link Size} or a number, then
   * creates as many rows to the grid as the array has values, which each row's size being equal to the size at its
   * index. If it's a {@link GridRepeat}, the grid will have as many rows as the repeat defines.
   */
  templateRows?: "auto" | (Size | number | "auto")[] | GridRepeat;
  /**
   * An attribute to control the value of the grid-template-columns CSS property. If a {@link Size} or a number, then
   * creates as many columns to the grid as the array has values, which each column's size being equal to the size at
   * its index. If it's a {@link GridRepeat}, the grid will have as many columns as the repeat defines.
   */
  templateColumns?: "auto" | (Size | number | "auto")[] | GridRepeat;
  /**
   * A convenience proprety for applying both {@link columnGap} and {@link rowGap} with one property.
   */
  gap?: Size | number;
  /**
   * The spacing between columns of the grid.
   */
  columnGap?: Size | number;
  /**
   * The spacing between rows of the grid.
   */
  rowGap?: Size | number;
  /**
   * How to align items along the column axis of this grid container.
   */
  alignItems?: "center" | "start" | "end";
  /**
   * How to align items along the row axis of this grid container.
   */
  justifyItems?: "center" | "start" | "end";
  /**
   * How to align the grid inside this grid container along the row axis.
   */
  justifyContent?: "start" | "end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly";
  /**
   * How to align the grid inside this grid container along the column axis.
   */
  alignContent?: "start" | "end" | "center" | "stretch" | "space-around" | "space-between" | "space-evenly";
  /**
   * If non-true, then this component will receive a tabindex of -1, preventing it from being cycleable with the tab
   * key. If true, the tabindex will be set to 0 to explicitly allow focusing.
   */
  focusable?: boolean;
}

export interface CellProps extends Omit<StandardComponentProps, "grow" | "basis" | "width" | "height"> {
  /**
   * The children rendered inside this grid cell.
   */
  children?: ReactNode;
  /**
   * The 0-based index of the row into which this cell should be placed.
   */
  row?: number;
  /**
   * The 0-based index of the column into which this cell should be placed.
   */
  column?: number;
  /**
   * The number of template-cells that this cell is tall, growing downwards from {@link column}. Defaults to 1.
   */
  height?: number;
  /**
   * The number of template-cells that this cell is wide, growing towards right from {@link row}. Defaults to 1.
   */
  width?: number;
  /**
   * Positioning for this cell inside the grid's template slot on the main axis.
   */
  justifySelf?: "start" | "end" | "center" | "stretch";
  /**
   * Positioning for this cell inside the grid's template slot on the cross axis.
   */
  alignSelf?: "start" | "end" | "center" | "stretch";
  /**
   * If true, then this Cell will be a Flex container.
   */
  flex?: boolean;
}

export const Grid = ({
  children,
  templateRows,
  templateColumns,
  gap,
  rowGap,
  columnGap,
  alignItems,
  justifyItems,
  justifyContent,
  alignContent,
  focusable,
  ...standardProps
}: GridProps) => {
  let templateRowValue = "auto";
  if (isGridRepeat(templateRows)) {
    templateRowValue = resolveRepeat(templateRows);
  } else if (templateRows !== undefined && templateRows !== "auto") {
    templateRowValue = templateRows.map((size) => resolveSize(size)).join(" ");
  }
  let templateColumnValue = "auto";
  if (isGridRepeat(templateColumns)) {
    templateColumnValue = resolveRepeat(templateColumns);
  } else if (templateColumns !== undefined && templateColumns !== "auto") {
    templateColumnValue = templateColumns.map((size) => resolveSize(size)).join(" ");
  }

  const gapForRow = rowGap ?? gap;
  const gapForCol = columnGap ?? gap;

  const { className, style } = resolveStandardStyling({
    ...standardProps,
    conditionalStyles: [
      [true, () => ["gridTemplateColumns", templateColumnValue]],
      [true, () => ["gridTemplateRows", templateRowValue]],
      [gapForRow, (rowGap) => ["rowGap", resolveSize(rowGap)]],
      [gapForCol, (colGap) => ["columnGap", resolveSize(colGap)]],
      [alignItems, (alignItems) => ["alignItems", alignItems]],
      [justifyItems, (justifyItems) => ["alignItems", justifyItems]],
      [justifyContent, (justifyContent) => ["justifyContent", justifyContent]],
      [alignContent, (alignContent) => ["alignContent", alignContent]],
    ],
    conditionalClassNames: [[true, styles.grid]],
  });

  return (
    <div tabIndex={focusable ? 0 : -1} className={className} style={style}>
      {children}
    </div>
  );
};

export const Cell = ({
  children,
  row,
  column,
  height,
  width,
  justifySelf,
  alignSelf,
  flex,
  ...otherProps
}: CellProps) => {
  const { className, style } = resolveStandardStyling({
    ...otherProps,
    conditionalStyles: [
      [row, (row) => ["gridRow", `${row + 1} / ${row + 1 + (height ?? 1)}`]],
      [column, (column) => ["gridColumn", `${column + 1} / ${column + 1 + (width ?? 1)}`]],
      [justifySelf, (justifySelf) => ["justifySelf", justifySelf]],
      [alignSelf, (alignSelf) => ["alignSelf", alignSelf]],
      [flex, () => ["display", "flex"]],
    ],
  });
  return (
    <div className={className} style={style}>
      {children}
    </div>
  );
};

const isGridRepeat = (object: any | undefined): object is GridRepeat => {
  return (
    object !== undefined &&
    (object as GridRepeat).repeatCount !== undefined &&
    (object as GridRepeat).tracks !== undefined
  );
};

const isGridMinMax = (object: any | undefined): object is GridMinMax => {
  return object !== undefined && (object as GridMinMax).min !== undefined && (object as GridMinMax).max !== undefined;
};

const resolveRepeat = (repeat: GridRepeat): string => {
  let tracks;
  if (isGridMinMax(repeat.tracks)) {
    tracks = `minmax(${resolveSize(repeat.tracks.min)}, ${resolveSize(repeat.tracks.max)})`;
  } else {
    tracks = resolveSize(repeat.tracks);
  }
  return `repeat(${repeat.repeatCount}, ${tracks})`;
};
