import React, { PropsWithChildren } from "react";
import styles from "./SimpleCard.module.css";
import { Header, SemanticICONS } from "semantic-ui-react";
import { Button } from "../button/Button";
import { Card } from "./Card";
import { Flex } from "../flex/Flex";
import { StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";

export interface SimpleCardProps extends Omit<StandardComponentProps, "grow"> {
  title: string;
  submitting?: boolean;
  actions?: SegmentAction[];
  hideActions?: boolean;
  disableActions?: boolean;
}

/**
 * A button that can be clicked to perform some action that is intended to concern the entire segment. Displayed on the
 * right side of the title.
 */
export interface SegmentAction {
  icon: SemanticICONS;
  tooltip?: string;
  onClick: () => void;
  disabled?: boolean;
}

/**
 * A component that can be used to group some amount of informational content together with a standardized style.
 * @param title A user-visible title to display above the child elements of this component.
 * @param submitting If `true`, then the title/header of this segment is faded out in a similar fashion as other
 *                   components provided by this library do during form submissions.
 * @param actions An optional array of actions that are displayed as buttons on the title row.
 * @param hideActions An optional boolean convenience flag that will hide the actions if true.
 * @param disableActions An optional boolean flag that will disable all actions if true.
 * @param children The child elements contained in this component.
 */
export const SimpleCard = ({
  title,
  submitting,
  children,
  actions,
  hideActions,
  disableActions,
  ...standardProps
}: PropsWithChildren<SimpleCardProps>) => {
  return (
    <Card column gap={1} grow alignItems="stretch" {...standardProps}>
      <Flex gap={1}>
        <Header as="span" className={`${styles.headerText} ${submitting ? styles.submitting : ""}`}>
          {title}
        </Header>
        {actions?.map((action) => (
          <Button
            onClick={action.onClick}
            disabled={disableActions || action.disabled}
            hidden={hideActions}
            compact
            basic
            tooltip={action.tooltip}
            icon={action.icon}
          />
        ))}
      </Flex>

      <Flex column gap={1} alignItems="stretch">
        {children}
      </Flex>
    </Card>
  );
};
