import React, { useContext } from "react";
import { FormContext, useRelevantErrors } from "./TypedForm";
import { Icon } from "semantic-ui-react";
import styles from "./FormToggle.module.css";
import { HelpText } from "../help/HelpText";
import { Flex } from "../flex/Flex";
import { Checkbox } from "../checkbox/Checkbox";

export interface FormToggleProps<Fields, Error> {
  formField: keyof Fields & string;
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  checkedLabel?: string;
  unCheckedLabel?: string;
  helpText?: string;
}

/**
 * This component can be used to add a toggle-type checkbox to a typed form. The following properties control the
 * behavior of this component:
 * - formField: the field of the form's state that this checkbox uses. The type of the state field should be a boolean.
 * - label: a label to display on top of the field, to inform the user what the use for this checkbox is.
 * - disabled: whether this checkbox should be disabled.
 * - relatedErrors: which errors of the form state relate to this particular field. If the form state contains one of
 *                  the errors listed here, the input will be highlighted with a warning color and the user-friendly
 *                  error text of the specific error will be displayed next to the field. The input field has been
 *                  implemented so that its size should not grow when errors become visible, but this may cause some
 *                  errors to not show up if many of them are displayed at once.
 * - checkedLabel: The label displayed next to the toggle checkbox when this checkbox is checked.
 * - unCheckedLabel: The label displayed next to the toggle checkbox when this checkbox is not checked.
 * - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *             this text as a popup.
 * @param props
 * @constructor
 */
export const FormToggle = <Fields, Error>(props: FormToggleProps<Fields, Error>) => {
  const formContext = useContext(FormContext);
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  return (
    <Flex column alignItems="baseline">
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText!!} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <Checkbox
        label={
          formContext.state[props.formField] === true
            ? props.checkedLabel
              ? props.checkedLabel
              : ""
            : props.unCheckedLabel
            ? props.unCheckedLabel
            : ""
        }
        onChange={(checked) => formContext.onInputChange(props.formField, checked)}
        disabled={props.disabled ? props.disabled : formContext.mode !== "edit"}
        checked={formContext.state[props.formField] === true}
        toggle
      />
    </Flex>
  );
};
