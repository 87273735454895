import { LocalDateTime } from "@js-joda/core";
import {
  defaultQueryDeserializer,
  defaultQuerySerializer,
  Query,
} from "../../ts-commons/commons-react-hooks/request/models";

export default interface User {
  id?: number;
  created?: LocalDateTime;
  loginName: string;
  password?: string;
  deleted?: LocalDateTime;
}

export interface UserQuery extends Query {
  loginName: string;
  deleted: boolean;
}

export const userQuerySerializer = (key: string, value: any): string => {
  switch (key) {
    case "deleted":
      return value.toString();
    default:
      return defaultQuerySerializer(key, value);
  }
};

export const userQueryDeserializer = (key: string, value: string): any => {
  switch (key) {
    case "deleted":
      return value === "true";
    default:
      return defaultQueryDeserializer(key, value);
  }
};
