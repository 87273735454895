import React, { useEffect, useRef, useState } from "react";
import { Popup } from "semantic-ui-react";
import styles from "./EllipsizableText.module.css";

export type EllipsizableTextElements = "span" | "a";

export interface EllipsizableTextProps {
  renderAs?: EllipsizableTextElements;
  content: string;
  /**
   * If defined, then the hover-tooltip is always available and displays the content specified here, even if the text
   * would not be ellipsized.
   */
  onHoverContent?: string[] | string;
  [key: string]: any;
}

/**
 * This component can be used for rendering text which should ellipsize when it runs out of space. The CSS of this
 * component truncates the content if it does not fit its bounds, and adds an on-hover popup/tooltip where the full
 * content is viewable.
 */
export const EllipsizableText = ({ renderAs, content, onHoverContent, ...otherProps }: EllipsizableTextProps) => {
  const ref = useRef<HTMLAnchorElement>(null);
  const [tooltipEnabled, setTooltipEnabled] = useState(false);
  const elementType = renderAs ?? "span";

  // Checks if the currently displayed content is being truncated and updates the tooltipEnabled-flag accordingly.
  const compareSize = () => {
    const isEllipsized = ref.current && ref.current.scrollWidth > ref.current.clientWidth;
    setTooltipEnabled(isEllipsized ?? false);
  };

  // Invoke the compareSize once on the initial render, and then register a window-resize listener that invokes it
  // again.
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    // remove resize listener again when component unmounts
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  }, []);

  const renderElement = () => {
    if (elementType === "span") {
      return (
        <span ref={ref} className={styles.ellipsizedText} {...otherProps}>
          {content}
        </span>
      );
    } else if (elementType === "a") {
      return (
        <a ref={ref} className={styles.ellipsizedText} {...otherProps}>
          {content}
        </a>
      );
    } else {
      return <div />;
    }
  };

  return (
    <Popup
      disabled={!tooltipEnabled && onHoverContent === undefined}
      content={
        typeof onHoverContent === "string"
          ? onHoverContent
          : onHoverContent !== undefined
          ? onHoverContent.map((contentLine) => (
              <>
                <span>{contentLine}</span>
                <br />
              </>
            ))
          : content
      }
      trigger={renderElement()}
    />
  );
};
