import User, { UserQuery } from "../model/User";
import { LocalDateTime } from "@js-joda/core";
import {
  PagedResults,
  RequestGenerator,
  RequestGeneratorProps,
} from "../../ts-commons/commons-react-hooks/request/models";
import { makeRequest, makeURL } from "../../ts-commons/commons-react-hooks/request/makeRequest";

export const listUsers: RequestGenerator<UserQuery, PagedResults<User>, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: makeURL("users", params),
      jsonReviver: userReviver,
      props,
    });
  };
};

export const getUserById: RequestGenerator<{ id: number }, User, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "GET",
      url: `users/${params.id}`,
      jsonReviver: userReviver,
      props,
    });
  };
};

export const getCurrentUser: RequestGenerator<{}, User, {}> = (props: RequestGeneratorProps) => {
  return () => {
    return makeRequest({
      method: "GET",
      url: "users/self",
      jsonReviver: userReviver,
      props,
    });
  };
};

export type StoreUserErrorCode = "INVALID_PASSWORD" | "INVALID_LOGIN_NAME" | "LOGIN_NAME_RESERVED";
export interface StoreUserError {
  code: StoreUserErrorCode;
}

export const updateUser: RequestGenerator<{ id: number; loginName: string }, void, StoreUserError> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "PUT",
      url: `users/${params.id}`,
      body: { loginName: params.loginName },
      props,
    });
  };
};

export const createUser: RequestGenerator<{ loginName: string; password: string }, { user: User }, StoreUserError> = (
  props: RequestGeneratorProps
) => {
  return (params) => {
    return makeRequest({
      method: "POST",
      url: "users",
      body: params,
      jsonReviver: userReviver,
      props,
    });
  };
};

const userReviver = (key: string, value: any): any => {
  switch (key) {
    case "created":
      if (typeof value === "string") {
        return LocalDateTime.parse(value);
      } else {
        return undefined;
      }
    case "deleted":
      if (typeof value === "string") {
        return LocalDateTime.parse(value);
      } else {
        return undefined;
      }
    default:
      return value;
  }
};

export type ChangePasswordErrorCode = "EMPTY_PASSWORD" | "WRONG_PASSWORD";
export interface ChangePasswordError {
  code: ChangePasswordErrorCode;
}
export const changePasswordRequest: RequestGenerator<
  { oldPassword: string; newPassword: string },
  {},
  ChangePasswordError
> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "POST",
      url: `users/change_password/`,
      body: params,
      props,
    });
  };
};

export const deleteUser: RequestGenerator<{ id: number }, void, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "DELETE",
      url: `users/${params.id}`,
      props,
    });
  };
};

export const undeleteUser: RequestGenerator<{ id: number }, void, void> = (props: RequestGeneratorProps) => {
  return (params) => {
    return makeRequest({
      method: "PATCH",
      url: `users/undelete/${params.id}`,
      props,
    });
  };
};
