import React from "react";
import { Segment } from "semantic-ui-react";
import styles from "./MonospaceTextSegment.module.css";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";

export interface MonospaceTextSegmentProps extends StandardComponentProps {
  text: string;
  /**
   * If true, the text segment will display an inner horizontal scrollbar. If false/undefined, then text inside the
   * segment will wrap within the horizontal bounds.
   */
  scrollHorizontally?: boolean;
}

/**
 * A utility function commonly used with {@link MonospaceTextSegment}. Returns a function that formats any string into
 * pretty-printed JSON with the specified indent size, or returns the text itself if it is not valid/parseable JSON.
 */
export const jsonFormatter = (indentSize: number): ((text: string) => string) => {
  return (text) => {
    try {
      return JSON.stringify(JSON.parse(text), null, indentSize);
    } catch (_) {
      return text;
    }
  };
};

/**
 * A simple component that can be used to display a block of text with monospace font. Useful for, for example, various
 * human-readable data formats like JSON.
 * @param text the text to be displayed
 * @param className additional classes for adding styling to the segment
 */
export const MonospaceTextSegment = ({
  text,
  scrollHorizontally,
  maxHeight,
  height,
  ...standardProps
}: MonospaceTextSegmentProps) => {
  const { style, className } = resolveStandardStyling({
    margin: 0,
    ...standardProps,
    maxHeight,
    height,
    conditionalClassNames: [
      [!scrollHorizontally, styles.wordWrap],
      [scrollHorizontally, styles.innerHorizontalScroll],
      [maxHeight !== undefined || height !== undefined, styles.innerVerticalScroll],
    ],
  });

  return (
    <Segment as="pre" className={className} style={style}>
      {text}
    </Segment>
  );
};
