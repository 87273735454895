import { FormContext, useRelevantErrors } from "./TypedForm";
import { Icon } from "semantic-ui-react";
import { HelpText } from "../help/HelpText";
import styles from "./FormDateTimeInput.module.css";
import { DateTimeInput } from "../input/DateTimeInput";
import { useContext } from "react";
import { Flex } from "../flex/Flex";

export interface FormDateTimeInputProps<Fields, Error> {
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  type?: string;
  helpText?: string;
  formField: keyof Fields & string;
  emptyTimeMode: "start" | "end";
}

/**
 * Displays a datetime picker in the form. This component is essentially a typed-form wrapper around the behaviour
 * provided by {@link DateTimeInput}, see said component for more information. This wrapper component provides the
 * standard typed-form component behaviour over a plain {@link DateTimeInput}, such as the display of errors in the
 * component and automatically updating the relevant field of the form state.
 */
export const FormDateTimeInput = <Fields, Error>(props: FormDateTimeInputProps<Fields, Error>) => {
  const relevantErrors = useRelevantErrors(props.relatedErrors);
  const formContext = useContext(FormContext);

  return (
    <Flex column>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>

      <DateTimeInput
        error={relevantErrors.length > 0}
        disabled={formContext.mode !== "edit"}
        onChange={(newDateTime) => {
          formContext.onInputChange(props.formField, newDateTime);
        }}
        emptyTimeMode={props.emptyTimeMode}
        value={formContext.state[props.formField]}
      />
    </Flex>
  );
};
