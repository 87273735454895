import React, { useState } from "react";
import { Divider, Form, Header, Input, Popup } from "semantic-ui-react";

import styles from "./StandardBrowsePageHeader.module.css";
import { Button } from "../button/Button";
import { FilterInfo } from "./tableFilters";
import { useCommonsTranslate } from "../message-context/MessageContext";
import { Flex } from "../flex/Flex";

export interface SearchState {
  page: number;
  sort: string;
  searchText: string;
  deleted: boolean;
}

export interface StandardBrowsePageTitleProps {
  type: "title";
  /**
   * The user-visible title text displayed in the component.
   */
  title: string;
}

export interface StandardBrowsePageFormProps {
  type: "form";
  /**
   * A {@link SearchState} object describing the current state in this form.
   */
  state: SearchState;
  /**
   * An update function for changing the current {@link SearchState}.
   */
  updateState: (state: Partial<SearchState>) => void;
  /**
   * The label displayed on top of the search-text edit box.
   */
  searchFieldLabel: string;
  /**
   * A hint value displayed to the user when the search-text edit box contains no inputted values.
   */
  searchFieldHint: string;
}

interface CommonProps {
  /**
   * An optional function that, when defined, causes a refresh-button to appear on the header. Clicking this button
   * invokes this function.
   */
  refresh?: () => void;
  /**
   * An optional list of {@link FilterInfo} objects and user-visible headers for the filters. If defined, then a filter
   * button is added to the header, and clicking it will render a popup for configuring the filters listed here.
   */
  additionalFilters?: [string, FilterInfo][];
  /**
   * An optioanl function that can be passed to add a button to reset all filters to their default values.
   */
  resetAllFilters?: () => void;
}

/**
 * The standard browse page comes in two variants: search form (with a single input field for text-based searches), or
 * a title, showing a user-visible title text for the screen. Both variants allow a refresh-button (i.e. submit search)
 * and any number of additional filters that are not related to any specific columns.
 */
export type StandardBrowsePageHeaderProps = CommonProps & (StandardBrowsePageFormProps | StandardBrowsePageTitleProps);

/**
 * This component displays a header typical for the browse pages of applications. This component is intended to be
 * paired with the results table component.
 */
export const StandardBrowsePageHeader: React.VoidFunctionComponent<StandardBrowsePageHeaderProps> = (
  props: StandardBrowsePageHeaderProps
) => {
  const t = useCommonsTranslate();

  const [popupOpen, setPopupOpen] = useState(false);
  const { refresh, additionalFilters, resetAllFilters } = props;

  const commonComponents = (
    <>
      {additionalFilters ? (
        <Popup
          open={popupOpen}
          position="bottom left"
          onOpen={() => setPopupOpen(true)}
          onClose={() => setPopupOpen(false)}
          trigger={
            <Button
              id="standardBrowsePageHeader-additionalFiltersButton"
              basic={!additionalFilters.some(([, filter]) => filter.hasActiveFilters)}
              color={additionalFilters.some(([, filter]) => filter.hasActiveFilters) ? "blue" : undefined}
              onClick={() => setPopupOpen(true)}
              icon="filter"
              size="large"
            />
          }
        >
          {additionalFilters.map(([label, filter], index) => {
            return (
              <React.Fragment key={`std_browse_header_filter_${index}`}>
                <Header>{label}</Header>
                {filter.renderSelection(t, undefined)}
                {index < additionalFilters?.length - 1 ? <Divider /> : undefined}
              </React.Fragment>
            );
          })}
          {resetAllFilters ? (
            <>
              {additionalFilters.length > 0 ? <Divider /> : undefined}
              <Button
                onClick={() => {
                  resetAllFilters();
                  setPopupOpen(false);
                }}
                className={styles.resetAllButton}
                text={t("standardBrowsePageHeader.buttonResetAllFilters")}
                negative
                icon="undo"
              />
            </>
          ) : undefined}
        </Popup>
      ) : undefined}
      {refresh ? (
        <Button id="standardBrowsePageHeader-refreshButton" onClick={refresh} basic icon="refresh" size="large" />
      ) : undefined}
    </>
  );

  if (props.type === "title") {
    const { title } = props;

    return (
      <div className={styles.root}>
        <Header as="h1" className={styles.grow}>
          {title}
        </Header>
        <Flex gap={1}>{commonComponents}</Flex>
      </div>
    );
  } else {
    const { searchFieldLabel, searchFieldHint, state, updateState } = props;
    return (
      <div className={styles.root}>
        <Form className={styles.grow}>
          <Form.Group>
            <Form.Field className={styles.searchField}>
              <label>{searchFieldLabel}</label>
              <Input
                placeholder={searchFieldHint}
                value={state.searchText}
                onChange={(event) => updateState({ searchText: event.target.value })}
              />
            </Form.Field>
          </Form.Group>
        </Form>

        <Flex gap={1}>{commonComponents}</Flex>
      </div>
    );
  }
};
