import { Icon } from "semantic-ui-react";
import React, { useContext } from "react";
import styles from "./FormDropdown.module.css";
import { FormContext, useRelevantErrors } from "./TypedForm";
import { HelpText } from "../help/HelpText";
import { Dropdown, DropdownItem } from "../dropdown/Dropdown";
import { Flex } from "../flex/Flex";

export type DropdownOption = {
  /**
   * Human-readable text for this dropdown option that will be displayed.
   */
  text?: string;
} & (
  | {
      /**
       * The identifier for a dropdown option. This will be written to the form state when this option is selected.
       */
      key: number | string;
    }
  | {
      /**
       * An alias for the `key` property.
       */
      value: number | string;
    }
);

export interface FormDropdownProps<Fields, Error> {
  formField: keyof Fields & string;
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  options: DropdownOption[];
  placeholder?: string;
  multiple?: boolean;
  search?: boolean;
  helpText?: string;
}

/**
 * This component can be used to add a dropdown-input-element to a typed form. The following properties control the
 * behavior of this component:
 * - formField: the field of the form's state that this input uses, i.e. which member of the form state should be
 *              displayed in the field and modified when a user interacts with this input. The value in the form's
 *              state should be a `key` of an option, or an array of `key`s if `multiple` is `true`.
 * - label: a label to display on top of the field
 * - disabled: whether the input should be disabled, that is non-interactable for the user.
 * - relatedErrors: which errors of the form state relate to this particular field. If the form state contains one of
 *                  the errors listed here, the input will be highlighted with a warning color and the user-friendly
 *                  error text of the specific error will be displayed next to the field. The input field has been
 *                  implemented so that its size should not grow when errors become visible, but this may cause some
 *                  errors to not show up if many of them are displayed at once.
 * - options: all the option values displayed in this dropdown. This should be an array of {@link DropdownOption}
 *            objects
 * - placeholder: a placeholder value to display in this component if nothing is selected
 * - multiple: whether it's possible to select multiple options or not. Default is `false`.
 * - search: whether it's possible to filter this component's options with a text input field. Default is `false`.
 * - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *              this text as a popup.
 */
export const FormDropdown = <Fields, Error>(props: FormDropdownProps<Fields, Error>) => {
  const formContext = useContext(FormContext);
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  const isEditable = formContext.mode === "edit" && !props.disabled;

  const resolveInputClass = (): string => {
    if (!isEditable) {
      return "";
    } else if (relevantErrors.length > 0) {
      return "";
    } else {
      return "";
    }
  };

  const mappedOptions = props.options.map((option) => {
    let key: string | number;
    if ("key" in option) {
      key = option.key;
    } else {
      key = option.value;
    }

    return {
      key,
      text: option.text,
    };
  });

  let selection: DropdownOption | DropdownOption[] | undefined;
  if (props.multiple) {
    let optionByKey: { [key: string | number]: DropdownOption } = {};
    mappedOptions.forEach((option) => {
      optionByKey[option.key] = option;
    });
    if (formContext.state[props.formField] === undefined) {
      console.error(
        `The value of the field '${props.formField}' is undefined in the form state. An empty array ` +
          "should be used instead."
      );
    }
    selection = (formContext.state[props.formField] as (number | string)[]).map((key) => optionByKey[key]);
  } else {
    selection = mappedOptions.find((option) => {
      return option.key === formContext.state[props.formField];
    });
  }

  return (
    <Flex column>
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <Dropdown
        alignSelf="stretch"
        className={resolveInputClass()}
        viewMode={formContext.mode !== "edit"}
        error={relevantErrors.length > 0}
        placeholder={props.placeholder}
        multiple={props.multiple}
        search={props.search}
        options={mappedOptions}
        selection={selection as any}
        onChange={(newValue: undefined | DropdownItem<number | string> | DropdownItem<number | string>[]) => {
          if (newValue === undefined) {
            formContext.onInputChange(props.formField, undefined);
          } else if ("key" in newValue) {
            formContext.onInputChange(props.formField, newValue.key);
          } else {
            formContext.onInputChange(
              props.formField,
              newValue.map((item) => item.key)
            );
          }
        }}
        disabled={!isEditable}
      />
    </Flex>
  );
};
