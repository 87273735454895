import React from "react";
import { Link, To } from "react-router-dom";
import styles from "./LabeledValue.module.css";

export interface LabeledValueProps {
  label: string;
  value: string;
  valueClass?: string;
  linkTo?: To;
}

/**
 * A simple widget that can be used to display a value to the user, together with a label explaining what value is
 * being displayed.
 * @param label The user-visible label shown above the value
 * @param value The user-visible value shown by this component.
 * @param valueClass A class-name that is passed to the value-span.
 * @param linkTo If defined, the value becomes a `Link` element using this value.
 */
export const LabeledValue = ({ label, value, valueClass, linkTo }: LabeledValueProps) => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>{label}</span>
      {linkTo ? (
        <Link to={linkTo} className={valueClass}>
          {value}
        </Link>
      ) : (
        <span className={valueClass}>{value}</span>
      )}
    </div>
  );
};
