import * as React from "react";
import { ModalActions as SemanticModalActions } from "semantic-ui-react";
import { Flex, FlexProps } from "../flex/Flex";

export interface ModalActionsProps extends Omit<FlexProps, "width"> {
  /** An element type to render as (string or function). */
  as?: any;
  /** Primary content. */
  children?: React.ReactNode;

  /** Additional classes. */
  className?: string;
}

/**
 * The actions of a {@link Modal}. Usually a set of buttons that do something. The children of this component are put
 * inside a {@link Flex} where content is justified to the end. The properties of this component extend
 * {@link FlexProps} (`width` cannot be set, however) and the props that have been defined in that interface are passed
 * on to the {@link Flex} used as the container of the actions. `justifyContent` will get a default value of `"end"`,
 * if it's not given.
 *
 * Use this as a child of a {@link Modal}.
 */
export const ModalActions = (props: ModalActionsProps) => {
  const { as, className, children, justifyContent, ...flexProps } = props;
  return (
    <SemanticModalActions as={as} className={className}>
      <Flex justifyContent={justifyContent ?? "end"} width="fill" {...flexProps}>
        {children}
      </Flex>
    </SemanticModalActions>
  );
};
