import { convert, DateTimeFormatter, Instant, LocalDate, nativeJs } from "@js-joda/core";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import i18n from "i18next";
import { LocaleOptions } from "react-semantic-ui-datepickers/dist/types";
import styles from "./DateInput.module.css";

export interface DateInputProps extends StandardComponentProps {
  id?: string;
  onChange: (newDate?: LocalDate) => void;
  value?: LocalDate;
  disabled?: boolean;
  error?: boolean;
}

/**
 * A component that can be used to show a datepicker. The field allows both manual input using a keyboard, as well as
 * picking the date from a floating calendar widget.
 */
export const DateInput = ({ id, value, onChange, error, disabled, ...standardProps }: DateInputProps) => {
  const { style, className } = resolveStandardStyling({
    ...standardProps,
    conditionalClassNames: [
      [error, styles.hasError],
      [true, styles.datePickerWrapper],
    ],
  });

  const formattedValue = value?.format(DateTimeFormatter.ISO_LOCAL_DATE) ?? "";

  const [state, setState] = useState({
    date: value,
    propValue: formattedValue,
  });

  // The effect below will update the state of this component if the value-prop is changed from the outside. The
  // component needs its on internal state due to the value being split into a separate date and time component, but
  // without this effect it would only be possible to set the initial value and not update it afterwards. This would
  // cause issues with, for example, pages that read query parameters from the page URL: the default/set value for
  // a component can change without the form being recreated.
  useEffect(() => {
    if (state.propValue !== formattedValue) {
      setState({
        date: value,
        propValue: formattedValue,
      });
    }
  }, [formattedValue, state.propValue, value]);

  return (
    <div className={className} style={style}>
      <SemanticDatepicker
        disabled={disabled}
        id={id}
        value={state.date ? convert(state.date).toDate() : undefined}
        locale={toLocaleOptions(i18n.resolvedLanguage)}
        onChange={(_, data) => {
          let date: LocalDate | undefined;
          if (data.value instanceof Date) {
            date = LocalDate.ofInstant(Instant.from(nativeJs(data.value)));
          } else {
            date = undefined;
          }
          setState({
            propValue: state.propValue,
            date,
          });
          onChange(date);
        }}
      />
    </div>
  );
};

const toLocaleOptions = (locale: string): LocaleOptions => {
  switch (locale) {
    case "fi":
      return "fi-FI";
    default:
      return "en-US";
  }
};
