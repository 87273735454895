import React from "react";

import { StoreUserErrorCode } from "../../../../api/services/users";
import { useTranslator } from "../../../../i18n/hooks";
import { createTypedForm, FormMode } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { SimpleCard } from "../../../../ts-commons/commons-react-components/card/SimpleCard";

export interface UserFormProps {
  formState: UserFormFields;
  setFormState: (newValue: UserFormFields) => void;
  errors: [StoreUserErrorCode, string][];
  enablePassword?: boolean;
  mode: FormMode;
}

export interface UserFormFields {
  loginName: string;
  password: string;
}

const Form = createTypedForm<UserFormFields, StoreUserErrorCode>();

export const UserForm = ({ formState, setFormState, errors, enablePassword, mode }: UserFormProps) => {
  const t = useTranslator("users.view");

  return (
    <Form state={formState} setState={setFormState} mode={mode} errors={errors}>
      <SimpleCard title={t("segmentTitle")} submitting={mode === "submit"}>
        <Form.Input
          formField="loginName"
          relatedErrors={["INVALID_LOGIN_NAME", "LOGIN_NAME_RESERVED"]}
          label={t("labelLoginName")}
        />
        {enablePassword && (
          <Form.Input
            formField="password"
            relatedErrors={["INVALID_PASSWORD"]}
            label={t("labelPassword")}
            type="password"
          />
        )}
      </SimpleCard>
    </Form>
  );
};
