import React, { useEffect, useState } from "react";
import { DateTimeFormatter, LocalTime } from "@js-joda/core";
import { isFinite, toNumber } from "lodash";
import { Input } from "./Input";
import { StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { Size } from "../../commons-ts-utils/utils/sizing";

export interface TimeFormInputProps extends Omit<StandardComponentProps, "width"> {
  id?: string;
  value?: LocalTime;
  onChange: (newTime?: LocalTime) => void;
  emptyTimeMode: "start" | "end";
  disabled?: boolean;
  error?: boolean;
  width?: number | Size | "fluid";
}

/**
 * An input field for entering a time value. Has default styling and size that is suitable for the purpose.
 */
export const TimeInput = ({
  id,
  value,
  onChange,
  emptyTimeMode,
  disabled,
  error,
  width,
  ...standardProps
}: TimeFormInputProps) => {
  const formattedValue = value?.format(DateTimeFormatter.ofPattern("H:mm")) ?? "";
  const [state, setState] = useState({
    inputtedValue: formattedValue,
    propValue: formattedValue,
  });

  // The effect below will update the state of this component if the value-prop is changed from the outside. The
  // component needs its on internal state due to the props-value being a datetime object, but the internal state being
  // a possibly partial input for a time. Without this effect, it would only be possible to set the initial value
  // and not update it afterwards. This would cause issues with, for example, pages that read query parameters from the
  // page URL: the default/set value for a component can change without the form being recreated.
  // In addition to handling updates from the outside, this effect also applies some minor formatting to the value,
  // setting the value as blank if it matches the value that a blank value would produce for the current time mode.
  useEffect(() => {
    if (formattedValue !== state.propValue) {
      let inputtedValue = formattedValue;
      if (formattedValue === "23:59" && emptyTimeMode === "end" && state.inputtedValue.length === 0) {
        inputtedValue = "";
      } else if (formattedValue === "0:00" && emptyTimeMode === "start" && state.inputtedValue.length === 0) {
        inputtedValue = "";
      }
      setState({
        inputtedValue,
        propValue: formattedValue,
      });
    }
  }, [value, emptyTimeMode, formattedValue, state.inputtedValue.length, state.propValue]);

  return (
    <Input
      // Limit the width to 8em (enough for a typical time input), unless this component has been specified explicitly
      // to have a fluid width.
      width={width === "fluid" ? undefined : width ?? { unit: "em", value: 8 }}
      id={id}
      disabled={disabled}
      icon="clock outline"
      placeholder="HH:mm"
      value={state.inputtedValue}
      onChange={(value) => {
        setState({
          ...state,
          inputtedValue: value,
        });
        if (isValidTime(value)) {
          onChange(LocalTime.parse(value, DateTimeFormatter.ofPattern("H:mm")));
        } else {
          onChange(undefined);
        }
      }}
      error={error ?? state.inputtedValue ? !isValidTime(state.inputtedValue) : false}
      {...standardProps}
    />
  );
};

const isValidTime = (timeString: string): boolean => {
  const parts = timeString.split(":");
  if (parts.length === 0) {
    return false;
  } else if (parts.length === 2 && parts[0] && parts[1]) {
    return isValidHours(parts[0]) && isValidMinutes(parts[1]);
  } else {
    return false;
  }
};

const isValidHours = (hourString: string): boolean => {
  const hours = toNumber(hourString);
  return isFinite(hours) && hours >= 0 && hours <= 23;
};

const isValidMinutes = (minuteString: string): boolean => {
  const minutes = toNumber(minuteString);
  return minuteString.length === 2 && isFinite(minutes) && minutes >= 0 && minutes <= 59;
};
