import React from "react";
import { ProxyTargetStatus } from "../../../../api/model/ProxyTarget";
import { StoreProxyTargetErrorCode } from "../../../../api/services/proxyTargets";
import { useTranslator } from "../../../../i18n/hooks";
import { createTypedForm, FormMode } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { RadioButtonChoice } from "../../../../ts-commons/commons-react-components/form/FormRadioButtons";
import { SimpleCard } from "../../../../ts-commons/commons-react-components/card/SimpleCard";

export interface ProxyTargetFormProps {
  formState: ProxyTargetFormFields;
  setFormState: (newValue: ProxyTargetFormFields) => void;
  errors: [StoreProxyTargetErrorCode, string][];
  enableLicenseKey: boolean;
  mode: FormMode;
}

export interface ProxyTargetFormFields {
  name: string;
  status: ProxyTargetStatus;
  targetUrl: string;
  loginPath: string;
  licenseKey: string;
  accessControlList: string;
}

const Form = createTypedForm<ProxyTargetFormFields, StoreProxyTargetErrorCode>();

const ProxyTargetForm = ({ formState, setFormState, mode, errors, enableLicenseKey }: ProxyTargetFormProps) => {
  const t = useTranslator("targets.view");
  const st = useTranslator("targets.status");

  const statusChoices: RadioButtonChoice[] = [
    {
      label: st("ACTIVE"),
      value: "ACTIVE",
      color: "blue",
      tooltip: st("explanation.ACTIVE"),
    },
    {
      label: st("TRANSIENT"),
      value: "TRANSIENT",
      color: "yellow",
      tooltip: st("explanation.TRANSIENT"),
    },
    {
      label: st("BLOCKED"),
      value: "BLOCKED",
      color: "red",
      tooltip: st("explanation.BLOCKED"),
    },
  ];

  return (
    <Form state={formState} setState={setFormState} mode={mode} errors={errors}>
      <SimpleCard title={t("basicInfoTitle")} submitting={mode === "submit"}>
        <Form.Input formField="name" relatedErrors={["INVALID_NAME", "NAME_RESERVED"]} label={t("nameLabel")} />
        <Form.RadioButtons choices={statusChoices} formField="status" relatedErrors={[]} label={t("statusLabel")} />
        <Form.Input formField="targetUrl" relatedErrors={["INVALID_TARGET_URL"]} label={t("targetUrlLabel")} />
        <Form.Input formField="loginPath" relatedErrors={["INVALID_LOGIN_PATH"]} label={t("loginPathLabel")} />
        <Form.TextArea
          formField="accessControlList"
          relatedErrors={["INVALID_ACCESS_CONTROL_LIST"]}
          label={t("accessControlListLabel")}
          rows={5}
          helpText={t("accessControlListHelp")}
        />
        {enableLicenseKey ? (
          <Form.Input formField="licenseKey" relatedErrors={["INVALID_LICENSE_KEY"]} label={t("licenseKeyLabel")} />
        ) : (
          ""
        )}
      </SimpleCard>
    </Form>
  );
};

export default ProxyTargetForm;
