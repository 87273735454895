import i18n from "i18next";
import en_translations from "./en/translations.json";
import component_en_translations from "../ts-commons/commons-react-components/i18n/en/commonComponentTranslations.json";
import { DefaultNamespace, initReactI18next, TFunction } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const resources = {
  en: {
    translations: {
      ...en_translations,
      tsCommons: {
        ...component_en_translations,
      },
    },
  },
} as const;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["navigator"],
      caches: [],
    },
    fallbackLng: "en",
    cleanCode: true,
    interpolation: {
      escapeValue: false,
    },
    defaultNS: "translations",
    resources,
  });

/**
 * A simplified type alias for the 't' property returned by react-i18next's `useTranslate`-hook.
 */
export type TranslateFunction = TFunction<DefaultNamespace, any>;
