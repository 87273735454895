import React from "react";
import { useTranslation } from "react-i18next";
import packageJson from "../../../package.json";
import { useLocation } from "react-router-dom";
import { StandardSideBar, SidebarItem } from "../../ts-commons/commons-react-components/sidebar/StandardSideBar";

export interface AppSideBarProps {
  children: React.ReactNode;
}

const AppSideBar = ({ children }: AppSideBarProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "sideBar" });
  const { pathname } = useLocation();

  const items: SidebarItem[] = [
    {
      type: "link",
      label: t("monitor"),
      icon: "exchange",
      link: "/monitor",
    },
    {
      type: "link",
      label: t("targets"),
      icon: "sitemap",
      link: "/targets",
    },
    {
      type: "link",
      label: t("users"),
      icon: "user",
      link: "/users",
    },
  ];

  return (
    <StandardSideBar
      mode="desktop"
      visible
      children={children}
      items={items}
      currentPath={pathname}
      versionInfo={t("version", { number: packageJson.version })}
    />
  );
};

export default AppSideBar;
