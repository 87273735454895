import React from "react";
import { Breadcrumb, Image, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styles from "./StandardTopBar.module.css";
import { TopBarDropdown, TopBarDropdownProps } from "./TopBarDropdown";
import { BreadcrumbSection } from "./UrlHierarchy";
import { useIsMobileScreen } from "../../commons-react-hooks/util/useIsMobileScreen";
import { Button } from "../button/Button";

export interface StandardTopBarProps {
  /**
   * Whether the topbar should be rendered in mobile or desktop mode. Defaults to 'responsive', which uses the
   * {@link useIsMobileScreen} to determine which mode to render the component in.
   */
  mode?: "mobile" | "desktop" | "responsive";
  breadcrumbs: BreadcrumbSection[];
  logo: string;
  mobileLogo?: string;
  dropdown?: TopBarDropdownProps;
  hamburgerButtonClicked?: () => void;
}

const createSectionProps = (section: BreadcrumbSection, index: number, active: boolean) => {
  return {
    as: !active ? Link : undefined,
    to: !active ? section.link : undefined,
    key: `topbar_breadcrumb_${index}`,
    className: active ? styles.activeSection : undefined,
  };
};

/**
 * A component that can be used to display an application topbar with standardized styling.
 * @param breadcrumbs A list of breadcrumbs to indicate the current page's context to the user. These can be created by
 *                    any means, the easiest of which might be using {@link createBreadcrumbs} to automatically generate
 *                    the breadcrumbs from the current path and a definition of the page's hierarchy. Note that
 *                    breadcrumbs are intended for displaying the current position in a hierarchy, not for showing the
 *                    user's navigation history.
 * @param logo A logo for the application, to be displayed in the left-side corner of the top bar.
 * @param mobileLogo A logo for the application that is used when using a mobile device to view this top bar. If this is
 *                   undefined, the `logo` will be used also in mobile view.
 * @param dropdown A properties object for configuring a dropdown in the right-side corner of the top bar. This dropdown
 *                 is typically used to provide session/self related actions, such as links for logging out or changing
 *                 your password.
 * @param hamburgerButtonClicked
 */
export const StandardTopBar = ({
  breadcrumbs,
  logo,
  mobileLogo,
  dropdown,
  mode,
  hamburgerButtonClicked,
}: StandardTopBarProps) => {
  const isMobileScreen = useIsMobileScreen();
  const isMobile = mode === "mobile" || (mode !== "desktop" && isMobileScreen);
  return (
    <Menu className={styles.topBar} fluid borderless size="small">
      {isMobile ? (
        <Button
          className={styles.hamburgerButton}
          icon="bars"
          onClick={() => {
            if (hamburgerButtonClicked !== undefined) {
              hamburgerButtonClicked();
            }
          }}
        />
      ) : (
        ""
      )}
      <div
        style={{
          width: isMobile ? "42px" : "160px",
        }}
        className={styles.logoWrapper}
      >
        <Image src={isMobile ? (mobileLogo ? mobileLogo : logo) : logo} size={"small"} as={Link} to="/" />
      </div>
      {!isMobile ? (
        <Breadcrumb size="large">
          {breadcrumbs.map((crumb, index) => (
            <React.Fragment key={`breadcrumb_section_wrapper_${index}`}>
              <Breadcrumb.Section key={`breadcrumb_section_${index}`}>
                <Menu.Item {...createSectionProps(crumb, index, index === breadcrumbs.length - 1)}>
                  {crumb.label}
                </Menu.Item>
              </Breadcrumb.Section>
              {index !== breadcrumbs.length - 1 && (
                <Breadcrumb.Divider icon={"chevron right"} key={`divider_${index}`} />
              )}
            </React.Fragment>
          ))}
        </Breadcrumb>
      ) : (
        <div className={`${styles.activeSection} ${styles.activeMobileSelection}`}>
          {breadcrumbs[breadcrumbs.length - 1].label}
        </div>
      )}
      {dropdown ? <TopBarDropdown {...dropdown} className="right item" /> : undefined}
    </Menu>
  );
};
