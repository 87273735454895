import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { CustomResponse } from "./models";
import { useAsync, UseAsyncReturn } from "react-async-hook";
import { useEffect, useState } from "react";
import { flattenToStringArray, unflattenToObject } from "./objectProcessingUtils";
import { pick } from "lodash";

/**
 * A helper function for creating a submittable search. I.e. a search that must be triggered by e.g. clicking a button.
 * While the search state is maintained in memory to make view interaction easier, the current URL is used as the
 * "source of truth": changes to the query parameters trigger an update to the search state, and automatically submit
 * the search. This makes it possible to both have the browser history remember the searches, and to share links to
 * specific queries.
 *
 * See {@link UseSubmittableSearchArgs} and {@link UseSubmittableSearchReturn} for the arguments and return values,
 * respectively
 */
export function useSubmittableSearch<F extends { [key: string]: any }, S, R>({
  defaultState,
  searchFunction,
  stateSerializer,
  stateDeserializer,
  formToSearchStateMapper,
  onSearchFinished,
}: UseSubmittableSearchArgs<F, S, R>): UseSubmittableSearchReturn<F, R> {
  const { search, pathname } = useLocation();
  const navigate = useNavigate();
  // This function returns a function to submit the search. When that function is invoked, we increase this counter,
  // which triggers the asynchronous search defined below. Without this counter, the search would only trigger on URL
  // (=parameter) changes, which is not ideal.
  const [submitCounter, setSubmitCounter] = useState(0);
  const [searchState, setSearchState] = useState<F>(
    createSearchStateFromQuery(search, defaultState, stateDeserializer)
  );

  const updateSearchState = (partialState: Partial<F>) => {
    setSearchState((searchState) => {
      return {
        ...searchState,
        ...partialState,
      };
    });
  };

  const resetSearchState = (...retainProps: string[]) => {
    setSearchState({
      ...defaultState,
      ...pick(searchState, retainProps),
    });
  };

  const submitSearch = (partialState?: Partial<F>) => {
    if (partialState) {
      updateSearchState(partialState);
    }

    // Construct the URL query parameters from the current search state
    let searchParams = createSearchParams();
    flattenToStringArray(
      {
        ...searchState,
        ...partialState,
      },
      stateSerializer
    ).forEach(([path, value]) => {
      searchParams.append(path, value);
    });

    // Determine the new URL search params that this search page should use
    const newSearch = `?${searchParams}`;

    // If the URL is to be changed, then we should navigate to the changed address. This will re-trigger the search.
    if (newSearch !== search) {
      // Navigate to  location but replace the query parameters with new ones
      navigate(
        { pathname, search: newSearch },
        {
          // Replace the current navigation entry. Otherwise multiple searches within the same view will flood the user's
          // navigation history with the same page.
          replace: true,
        }
      );
    } else {
      // Update the search counter: even if the URL does not change, this forces the useAsync below to re-trigger the
      // search, which is the expected behaviour for a submitSearch() function.
      setSubmitCounter(submitCounter + 1);
    }
  };

  // Update the search state if the URL changes, because the searchState is what is being used by the form to display
  // the selected values to the user
  useEffect(
    () => {
      // We use setSearchState instead of updateSearchState here so that missing query parameters correctly reset fields
      // that may have been previously populated if opening the URL from the same page
      setSearchState(createSearchStateFromQuery(search, defaultState, stateDeserializer));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  // The query results as an async result. The search is re-triggered any time the URL changes (=the user arrives to the
  // page via a link, or submits the query which changes the URL), or the submission counter is changed (which is done
  // every time the form is submitted).
  let mapper: (formState: F) => S;
  if (formToSearchStateMapper) {
    mapper = formToSearchStateMapper;
  } else {
    mapper = (formState: F) => {
      return formState as unknown as S;
    };
  }
  const searchResults = useAsync(async () => {
    const response = await searchFunction(mapper(createSearchStateFromQuery(search, defaultState, stateDeserializer)));
    if (onSearchFinished) {
      onSearchFinished(response.status === 200);
    }
    if (response.status === 200) {
      return response.body;
    } else {
      return Promise.reject("Non-OK status code");
    }
  }, [search, submitCounter]);

  return [searchState, updateSearchState, submitSearch, searchResults, resetSearchState];
}

const createSearchStateFromQuery = <S>(
  search: string,
  defaultState: S,
  queryDeserializer: (path: string, value: string) => any
) => {
  let arraySearchState: [string, string][] = [];
  createSearchParams(search).forEach((value, key) => {
    arraySearchState.push([key, value]);
  });

  let stateFromQuery = unflattenToObject(arraySearchState, queryDeserializer);
  return {
    ...defaultState,
    ...stateFromQuery,
  };
};

/**
 * Arguments for the {@link useSubmittableSearch}-function.
 */
export interface UseSubmittableSearchArgs<F extends { [key: string]: any }, S, R> {
  /**
   * The default "form state" that is used with this search: i.e. the data that is encoded into the
   * current URL. If {@link formToSearchStateMapper} is not given, this state is also supplied to the
   * {@link searchFunction} when the search is triggered.
   */
  defaultState: F;
  /**
   * A function that accepts the current search parameters, and returns a promise yielding the
   * searched results.
   */
  searchFunction: (state: S) => Promise<CustomResponse<R, void>>;
  /**
   * A function that serializes fields of the state as strings. This is used to encode the current
   * search state into the query parameters. These are NOT the query parameters that are sent to
   * the backend, but simply something the frontend and browser uses to remember the search page.
   */
  stateSerializer: (key: string, value: any) => string;
  /**
   * The counterpart to the state serializer, this function should reconstruct the real values
   * of the state when given values created by the serializer. Used to re-create the form state
   * when the page is accessed via a URL.
   */
  stateDeserializer: (key: string, value: string) => any;
  /**
   * An optional mapper function that maps a "form state" to a "search state" that is
   * submitted to the search function when this search is triggered. If this is not given
   * the two first type parameters used in this function must be the same.
   */
  formToSearchStateMapper?: (formState: F) => S;
  /**
   * An optional callback function that can be supplied in order to react to the asynchronously-loaded results'
   * completion. The boolean argument is `true` if the query was successful (http 200), and `false` otherwise.
   */
  onSearchFinished?: (ok: boolean) => void;
}

/**
 * The return type of {@link useSubmittableSearch}. Contains the following 5 values.
 *  1. The current search state,
 *  2. a function to update the search state,
 *  3. a function to submit the query (and update the search state before doing so), and
 *  4. the search results inside a {@link UseAsyncReturn}
 *  5. a function to reset the search state back to its default values, retaining only the properties passed as a vararg
 *     parameter.
 */
export type UseSubmittableSearchReturn<F, R> = [
  F,
  (partialState: Partial<F>) => void,
  (partialState?: Partial<F>) => void,
  UseAsyncReturn<R>,
  (...retainProps: string[]) => void
];
