import { FetchResult, RequestGenerator } from "./models";
import { useEffect, useState } from "react";
import { useRequest } from "./RequestContext";

/**
 * A convenience function for the common case of "fetch this entity for the current view". Accepts a single request
 * definition and the parameters used to call the resulting request, and returns a `FetchResult` object. This wrapper
 * object contains the return value for the request (if any), and a status indicating whether the request has completed
 * successfully, failed, or is still processing.
 *
 * Once the result has finished, the given `onFinished` is called if it's defined. If the fetch was successful (i.e. it
 * returned HTTP status 200), a `true` and the fetched body will be passed to the function in the second parameter. If
 * the fetch was not successful, `false`, `undefined` (2nd parameter), and the response error body (3rd parameter), if
 * available, will be passed to the function.
 */
export function useResult<P, T, E>(
  request: RequestGenerator<P, T, E>,
  params: P,
  onFinished?: (success: boolean, result?: T, errorResult?: E) => void
): [FetchResult<T>, () => void] {
  const [entityState, setEntityState] = useState({ status: "fetching" } as FetchResult<T>);
  const fetchEntity = useRequest(request);
  const [triggerCounter, setTriggerCounter] = useState(0);

  useEffect(
    () => {
      setEntityState({ status: "fetching" });
      fetchEntity(params).then(
        (response) => {
          if (response.status === 200) {
            setEntityState({
              status: "ready",
              value: response.body,
            });
            if (onFinished !== undefined) {
              onFinished(true, response.body);
            }
          } else {
            setEntityState({ status: "error" });
            if (onFinished !== undefined) {
              onFinished(false, undefined, response.body);
            }
          }
        },
        () => {
          setEntityState({ status: "error" });
          if (onFinished !== undefined) {
            onFinished(false);
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerCounter, JSON.stringify(params)]
  );

  // Create the refresh-entity using useState so that it does not change during re-renders.
  const [refreshEntity] = useState(() => {
    return () => {
      setTriggerCounter((triggerCounter) => triggerCounter + 1);
    };
  });

  return [entityState, refreshEntity];
}

/**
 * A convenience function for the use case of a view wanting to perform some staticly parameterized fetch, but needing
 * it to be triggerable by the user. This could be, for example, fetching some file contents from the server to display
 * only if the user chooses to do so by pressing a button.
 *
 * Returns two values: a FetchResult parameterized by the given request, and a function to trigger the fetch. The
 * trigger-function can be reused.
 */
export function useTriggerableResult<P, T, E>(
  request: RequestGenerator<P, T, E>,
  params: P
): [FetchResult<T>, () => void] {
  const [entityState, setEntityState] = useState({ status: "fetching" } as FetchResult<T>);
  const fetchEntity = useRequest(request);

  const triggerFetch = () => {
    fetchEntity(params).then(
      (response) => {
        if (response.status === 200) {
          setEntityState({
            status: "ready",
            value: response.body,
          });
        } else {
          setEntityState({ status: "error" });
        }
      },
      () => {
        setEntityState({ status: "error" });
      }
    );
  };

  return [entityState, triggerFetch];
}
