import React, { useState } from "react";

import { useParams } from "react-router-dom";
import { Container, Placeholder } from "semantic-ui-react";
import { deleteUser, getUserById, StoreUserErrorCode, undeleteUser, updateUser } from "../../../../api/services/users";
import { toNumber } from "lodash";
import { useTranslator } from "../../../../i18n/hooks";
import { UserForm, UserFormFields } from "./UserForm";
import {
  createEditTitlePrimaryActions,
  createViewTitlePrimaryActions,
  createViewTitleSecondaryActions,
} from "../../../../util/TitleActions";
import User from "../../../../api/model/User";
import { useResult } from "../../../../ts-commons/commons-react-hooks/request/useResult";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { useFormState } from "../../../../ts-commons/commons-react-hooks/form/useFormState";
import { FormMode } from "../../../../ts-commons/commons-react-components/form/TypedForm";
import { Title, TitleAction } from "../../../../ts-commons/commons-react-components/title/Title";
import { useCancelModal } from "../../../../ts-commons/commons-react-components/modal/CancelModal";

const userToFormState = (user: User): Partial<UserFormFields> => {
  const { loginName } = user;
  return { loginName };
};

const ViewUserScreen = () => {
  const { userId } = useParams();
  const [userResult, refreshUser] = useResult(getUserById, { id: toNumber(userId) });
  const t = useTranslator("users.view");
  const ct = useTranslator("common");
  const deleteSuffix = userResult.status === "ready" && userResult.value.deleted != null ? " (deleted)" : "";

  const saveUser = useRequest(updateUser);
  const delUser = useRequest(deleteUser);
  const unDelUser = useRequest(undeleteUser);

  const { formState, setFormState, setFormResetState, formErrors, setFormErrors, resetForm, isFormDirty } =
    useFormState<UserFormFields, User, StoreUserErrorCode>(
      {
        loginName: "",
        password: "",
      },
      userResult,
      userToFormState
    );

  const { CancelModal, setCancelModalOpen } = useCancelModal(() => {
    resetForm();
    setFormMode("view");
  });
  const [formMode, setFormMode] = useState<FormMode>("view");

  const errorMapping = {
    INVALID_PASSWORD: "error.invalidPassword",
    INVALID_LOGIN_NAME: "error.invalidLoginName",
    LOGIN_NAME_RESERVED: "error.loginNameReserved",
  };

  const onSaveUser = () => {
    setFormMode("submit");
    setFormErrors([]);
    saveUser({ id: toNumber(userId), loginName: formState.loginName }).then(
      (response) => {
        if (response.status === 200) {
          setFormResetState(formState);
          setFormMode("view");
        } else {
          const errorCode = response.body.code;
          setFormErrors([[errorCode, t(errorMapping[errorCode])]]);
          setFormMode("edit");
        }
      },
      () => {
        setFormMode("edit");
      }
    );
  };

  const onCancelEdit = () => {
    if (isFormDirty()) {
      setCancelModalOpen(true);
    } else {
      resetForm();
      setFormMode("view");
    }
  };

  const onDeleteUser = () => {
    delUser({ id: toNumber(userId) }).then((response) => {
      if (response.status === 200) {
        refreshUser();
      }
    });
  };

  const onUndeleteUser = () => {
    unDelUser({ id: toNumber(userId) }).then((response) => {
      if (response.status === 200) {
        refreshUser();
      }
    });
  };

  const createPrimaryActions: () => TitleAction[] = () => {
    if (formMode === "view") {
      return createViewTitlePrimaryActions(ct, () => setFormMode("edit"));
    } else {
      return createEditTitlePrimaryActions(ct, formMode, onSaveUser, onCancelEdit);
    }
  };

  const createSecondaryActions: () => TitleAction[] = () => {
    if (formMode === "view") {
      if (userResult.status === "ready" && userResult.value.deleted != null) {
        return createViewTitleSecondaryActions(ct, () => {}, onUndeleteUser, true);
      } else {
        return createViewTitleSecondaryActions(ct, onDeleteUser, () => {}, false);
      }
    } else {
      return [];
    }
  };

  return (
    <Container>
      <Title
        loading={userResult.status === "fetching"}
        submitting={formMode === "submit"}
        icon="user"
        errorHighlight={formErrors.length > 0}
        title={
          formMode === "view"
            ? t("titleView", { name: userResult.status === "ready" ? userResult.value.loginName + deleteSuffix : "" })
            : t("titleEdit", { name: userResult.status === "ready" ? userResult.value.loginName : "" })
        }
        primaryActions={createPrimaryActions()}
        secondaryActions={createSecondaryActions()}
      />
      {userResult.status !== "ready" ? (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <UserForm formState={formState} setFormState={setFormState} errors={formErrors} mode={formMode} />
      )}

      <CancelModal />
    </Container>
  );
};

export default ViewUserScreen;
