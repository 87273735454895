import { DateTimeFormatter, Duration, LocalDateTime } from "@js-joda/core";
import { languageToLocale } from "../../i18n/languageUtils";
import i18n from "i18next";
import { ProxyTarget } from "./ProxyTarget";
import {
  defaultQueryDeserializer,
  defaultQuerySerializer,
  Query,
} from "../../ts-commons/commons-react-hooks/request/models";
import { deserializeArray, serializeArray } from "../../ts-commons/commons-react-hooks/request/utils";

export interface RequestEvent {
  id?: number;
  created?: LocalDateTime;
  httpMethod: HttpMethod;
  completed?: LocalDateTime;
  path: string;
  requestQueryParams: { [key: string]: string[] };
  requestHeaders: Header[];
  requestBody?: HttpBody;
  responseBody?: HttpBody;
  responseHeaders?: Header[];
  responseHttpStatus?: number;
  stackTrace?: string;
  status: RequestState;
  username?: string;
  proxyTarget: ProxyTarget;
  requestSent: LocalDateTime;
}

export type RequestState =
  | "IN_PROGRESS"
  | "COMPLETED"
  | "FAILURE_ERROR"
  | "FAILURE_TIMEOUT"
  | "FAILURE_WRITE"
  | "FAILURE_CONNECT";

export const requestStates: RequestState[] = [
  "IN_PROGRESS",
  "COMPLETED",
  "FAILURE_ERROR",
  "FAILURE_TIMEOUT",
  "FAILURE_WRITE",
  "FAILURE_CONNECT",
];

export type HttpMethod = "GET" | "HEAD" | "POST" | "PUT" | "PATCH" | "DELETE" | "OPTIONS" | "TRACE";

export const httpMethods: HttpMethod[] = ["DELETE", "GET", "HEAD", "OPTIONS", "PATCH", "POST", "PUT", "TRACE"];

export interface TextHttpBody {
  type: "text";
  value: string;
}

export interface BinaryHttpBody {
  type: "binary";
  base64Value: string;
}

export interface HashedHttpBody {
  type: "hash";
  hash: string;
  algorithm: string;
}

export interface HiddenHttpBody {
  type: "hidden";
}

export interface NotFetchedHttpBody {
  type: "notFetched";
}

export type HttpBody = TextHttpBody | BinaryHttpBody | HashedHttpBody | HiddenHttpBody | NotFetchedHttpBody;

export interface RequestEventQuery extends Query {
  createdStart?: LocalDateTime;
  createdEnd?: LocalDateTime;
  statuses?: RequestState[];
  username: string;
  httpMethods?: HttpMethod[];
  httpStatus?: number;
  path: string;
  proxyTargetId: string;
}

export const requestEventQuerySerializer = (key: string, value: any): string => {
  switch (key) {
    case "createdStart":
    case "createdEnd":
      if (value instanceof LocalDateTime) {
        return value.format(DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.S"));
      } else {
        return "";
      }
    case "statuses":
    case "httpMethods":
      return serializeArray(value);
    case "deleted":
      return value.toString();
    default:
      return defaultQuerySerializer(key, value);
  }
};

export const requestEventQueryDeserializer = (key: string, value: string): any => {
  switch (key) {
    case "createdStart":
    case "createdEnd":
      return LocalDateTime.parse(value, DateTimeFormatter.ofPattern("yyyy-MM-dd'T'HH:mm:ss.S"));
    case "statuses":
    case "httpMethods":
      return deserializeArray(value);
    case "deleted":
      return value === "true";
    default:
      return defaultQueryDeserializer(key, value);
  }
};

/**
 * Returns how long in the given {@link RequestEvent} the backend server processed the request (i.e. time between
 * "completed" and "requestSent") as a string that includes the unit at the end
 */
export const serverDurationToString = (event: RequestEvent): string => {
  if (event.requestSent && event.completed) {
    const durationSeconds = Duration.between(event.requestSent, event.completed).toMillis() / 1000;
    return (
      durationSeconds.toLocaleString(languageToLocale(i18n.resolvedLanguage), {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }) + " s"
    );
  } else {
    return "";
  }
};

/**
 * Returns how long in the given {@link RequestEvent} the proxy server processed the request (i.e. time between
 * "requestSent" and "created") as a string that includes the unit at the end
 */
export const proxyDurationToString = (event: RequestEvent): string => {
  if (event.requestSent && event.created) {
    const durationSeconds = Duration.between(event.created, event.requestSent).toMillis() / 1000;
    return (
      durationSeconds.toLocaleString(languageToLocale(i18n.resolvedLanguage), {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      }) + " s"
    );
  } else {
    return "";
  }
};

export interface SensitiveHeaderValue {
  type: "sensitive";
}

export interface NormalHeaderValue {
  type: "normal";
  values: string[];
}

export interface IgnoredHeaderValue {
  type: "ignored";
}

export type HeaderValue = SensitiveHeaderValue | NormalHeaderValue | IgnoredHeaderValue;

export type Header = {
  key: string;
  original: HeaderValue;
  proxied: HeaderValue;
};
