import React, { ReactNode } from "react";

export interface AuthState {
  authenticatedUser?: string;
}

interface AuthenticateAction {
  type: "authenticate";
  user: string;
}
interface ResetAuthenticationAction {
  type: "reset";
}

export const authReducer: (state: AuthState, action: AuthAction) => AuthState = (
  state: AuthState,
  action: AuthAction
) => {
  if (action.type === "authenticate") {
    return {
      authenticatedUser: action.user,
    };
  } else if (action.type === "reset") {
    return {
      authenticatedUser: undefined,
    };
  } else {
    return state;
  }
};

const ReducerContext = React.createContext<[AuthState, React.Dispatch<AuthAction>]>([{}, () => {}]);

export function useAuthReducer(): [AuthState, React.Dispatch<AuthAction>] {
  return React.useContext(ReducerContext);
}

const AuthReducerContext = ({ children }: { children: ReactNode }) => {
  const reducer = React.useReducer(authReducer, {});

  return <ReducerContext.Provider value={reducer}>{children}</ReducerContext.Provider>;
};

export type AuthAction = AuthenticateAction | ResetAuthenticationAction;
export default AuthReducerContext;
