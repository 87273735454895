import { isArray, isEqual, isObject, isString, mapValues, pickBy } from "lodash";
import { useEffect, useRef, useState } from "react";

/**
 * Sanitizes the given value into a new value, removing any empty/undefined values from it. This process can be
 * described as recursively applying the following set of transformations into the value and all of its children (and
 * their children recursively, and so on).
 * - Transform empty strings -> undefined
 * - Transform empty arrays -> undefined
 * - Transform empty objects -> undefined
 * - Remove any properties or elements with the value of `undefined`
 * The returned value is an object created from the given value by applying only the above rules, and is such an object
 * that it cannot be further modifying by applying any of the rules.
 */
const sanitize = (value: any): any => {
  if (isArray(value)) {
    const newArray: any = (value as any[]).map(sanitize).filter((element) => element !== undefined);
    if (newArray.length === 0) {
      return undefined;
    } else {
      return newArray;
    }
  } else if (isString(value)) {
    if ((value as string).length === 0) {
      return undefined;
    } else {
      return value;
    }
  } else if (isObject(value)) {
    const sanitezedObj = pickBy(mapValues(value, sanitize), (element) => element !== undefined);
    if (Object.keys(sanitezedObj).length === 0) {
      return undefined;
    } else {
      return sanitezedObj;
    }
  } else {
    return value;
  }
};

/**
 * Creates a debounce effect, invoking the given effect-function if the given state is modified and then remains
 * unmodified for a number of milliseconds equal to the delay parameter. For the purposes of determining whether the
 * state has changed, the value is always sanitized using {@link sanitize}. That is, adding/removing a property that has
 * a value of an empty string, array, or object does not cause the debounce to trigger.
 */
export const useDebounce = (effect: () => void, state: any, delay?: number): (() => void) => {
  const [counter, setCounter] = useState(0);
  const [lastState, setLastState] = useState([state, counter]);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const delayMillis = delay ?? 300;

  useEffect(() => {
    const currentState = [state, counter];

    if (!isEqual(sanitize(currentState), sanitize(lastState))) {
      setLastState(currentState);

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }

      // If the counter increments, a refresh has been requested. Immediately trigger the search
      if (lastState[1] !== counter) {
        effect();
      }
      // If the counter has remained the same, then the search state has been changed. Debounce the refresh
      else {
        timeoutRef.current = setTimeout(effect, delayMillis);
      }
    }
  }, [delayMillis, timeoutRef, effect, lastState, counter, state]);

  return () => {
    setCounter((counter) => counter + 1);
  };
};
