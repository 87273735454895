import { LocalDateTime } from "@js-joda/core";
import {
  defaultQueryDeserializer,
  defaultQuerySerializer,
  Query,
} from "../../ts-commons/commons-react-hooks/request/models";

export type ProxyTargetStatus = "ACTIVE" | "TRANSIENT" | "BLOCKED";

export interface ProxyTarget {
  id?: number;
  created?: LocalDateTime;
  deleted?: LocalDateTime;
  name: string;
  targetUrl: string;
  loginPath: string;
  status: ProxyTargetStatus;
  accessControlList: string;
}

export interface ProxyTargetAccessToken {
  token: string;
}

export interface ProxyTargetLicenseKey {
  licenseKey: string;
}

export interface ProxyTargetQuery extends Query {
  deleted?: boolean;
  name?: string;
  targetUrl?: string;
  status?: ProxyTargetStatus;
}

export const proxyTargetQuerySerializer = (key: string, value: any): string => {
  switch (key) {
    case "deleted":
      return value.toString();
    default:
      return defaultQuerySerializer(key, value);
  }
};

export const proxyTargetQueryDeserializer = (key: string, value: string): any => {
  switch (key) {
    case "deleted":
      return value === "true";
    default:
      return defaultQueryDeserializer(key, value);
  }
};
