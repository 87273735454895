import { createContext, PropsWithChildren, useContext } from "react";

/**
 * This type defines a translation function for the commons library. It should turn the given translation key and any
 * potential arguments into a localized string that can be shown to users. The commons-library itself does not contain
 * any translations, but instead requires that applications using the library feed these translations to it by creating
 * a context that supplies this function.
 *
 * @see MessageContext
 */
export type CommonsTranslateFunction = (key: string, ...args: any) => string;

export interface MessageContextProps {
  translateMessage: CommonsTranslateFunction;
}

const MessageContextInternal = createContext<Partial<MessageContextProps>>({});

/**
 * Applications using certain commons-library components should wrap themselves in this context, and provide a value for
 * the translation function.
 *
 * @see CommonsTranslateFunction
 */
export const MessageContext = (props: PropsWithChildren<MessageContextProps>) => {
  return <MessageContextInternal.Provider value={props}>{props.children}</MessageContextInternal.Provider>;
};

/**
 * A hook for components in the commons library to access and use an instance of the commons-translate-function provided
 * by the application.
 */
export const useCommonsTranslate = (keyPrefix?: string): CommonsTranslateFunction => {
  const { translateMessage } = useContext(MessageContextInternal);
  if (!translateMessage) {
    throw Error(
      "No message translator found. This component requires that the application defines a MessageContext and " +
        "supplies a translator function for resolving user-visible messages."
    );
  }

  return (key: string, ...args: any): string => {
    return translateMessage(keyPrefix !== undefined ? `${keyPrefix}.${key}` : key, ...args);
  };
};
