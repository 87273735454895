import React from "react";
import {
  httpMethods,
  proxyDurationToString,
  RequestEvent,
  RequestEventQuery,
  requestEventQueryDeserializer,
  requestEventQuerySerializer,
  requestStates,
  serverDurationToString,
} from "../../../../api/model/RequestEvent";
import { listRequestEvents } from "../../../../api/services/requestEvents";
import { DateTimeFormatter } from "@js-joda/core";
import { Link } from "react-router-dom";
import { useTranslator } from "../../../../i18n/hooks";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { useSubmittableSearch } from "../../../../ts-commons/commons-react-hooks/request/useSubmittableSearch";
import {
  ResultsTable,
  ResultsContainer,
} from "../../../../ts-commons/commons-react-components/results-table/ResultsTable";
import { useDebounce } from "../../../../ts-commons/commons-react-hooks/util/useDebounce";
import {
  createDateTimeRangeFilter,
  createMultiChoiceFilter,
  createSingleChoiceFilter,
  createTextInputFilter,
} from "../../../../ts-commons/commons-react-components/results-table/tableFilters";
import { listAllProxyTargets } from "../../../../api/services/proxyTargets";
import { useResult } from "../../../../ts-commons/commons-react-hooks/request/useResult";
import { isNumber } from "../../../../ts-commons/commons-ts-utils/utils/numberUtils";
import { toNumber } from "lodash";
import { PagedResults } from "../../../../ts-commons/commons-react-hooks/request/models";
import { StandardBrowsePageHeader } from "../../../../ts-commons/commons-react-components/results-table/StandardBrowsePageHeader";

export type SearchState = Omit<RequestEventQuery, "httpStatus"> & { httpStatus: string };

const BrowseRequestEventsScreen = () => {
  const t = useTranslator("requestEvents");
  const ct = useTranslator("common");

  const request = useRequest(listRequestEvents);
  const [allTargets] = useResult(listAllProxyTargets, {});
  const [searchState, updateSearchState, performSearch, searchResults, resetSearch] = useSubmittableSearch<
    SearchState,
    RequestEventQuery,
    PagedResults<RequestEvent>
  >({
    defaultState: {
      proxyTargetId: "",
      path: "",
      username: "",
      httpStatus: "",
      page: 1,
      sort: "created desc, id desc",
    },
    searchFunction: request,
    formToSearchStateMapper: (searchState) => {
      const { httpStatus, ...other } = searchState;
      let httpStatusNumber: number | undefined = undefined;
      if (isNumber(httpStatus)) {
        httpStatusNumber = toNumber(httpStatus);
      }
      return { httpStatus: httpStatusNumber, ...other };
    },
    stateSerializer: requestEventQuerySerializer,
    stateDeserializer: requestEventQueryDeserializer,
  });
  const triggerDebounce = useDebounce(performSearch, searchState);
  const submitSearch = (searchState: Partial<SearchState>) => {
    updateSearchState(searchState);
    triggerDebounce();
  };

  return (
    <ResultsContainer>
      <StandardBrowsePageHeader
        type="title"
        refresh={triggerDebounce}
        additionalFilters={[]}
        resetAllFilters={() => resetSearch("sort", "page")}
        title={t("browse.title")}
      />
      <ResultsTable<RequestEvent>
        memoizeRows
        id="requestEventsTable"
        noResultsInfo={ct("search.noResultsInfo")}
        items={searchResults.result?.pageElements}
        pagination={searchResults.result}
        columns={() => [
          {
            header: t("browse.columnTarget"),
            width: { type: "weighted", value: 1 },
            renderCell: (event) => <Link to={`/targets/${event.proxyTarget.id}`}>{event.proxyTarget.name}</Link>,
            ascending: "proxyTargetId, id",
            descending: "proxyTargetId desc, id desc",
            columnKey: "target",
            filter:
              allTargets.status === "ready"
                ? createSingleChoiceFilter({
                    type: "dropdown",
                    defaultChoice: "",
                    options: [""].concat(allTargets.value.map((target) => target.id!!.toString())),
                    currentChoice: searchState.proxyTargetId,
                    choiceToLabel: (targetId) => {
                      if (targetId === "") {
                        return ct("anyChoice");
                      } else {
                        return allTargets.value.find((target) => target.id!!.toString() === targetId)?.name ?? "";
                      }
                    },
                    updateChoice: (newChoice) => {
                      updateSearchState({
                        proxyTargetId: newChoice,
                      });
                    },
                  })
                : "loading",
          },
          {
            header: t("browse.columnCreated"),
            width: { type: "static-em", value: 12 },
            renderCell: (event) => event.created?.format(DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")),
            ascending: "created, id",
            descending: "created desc, id desc",
            columnKey: "created",
            filter: createDateTimeRangeFilter({
              start: searchState.createdStart,
              end: searchState.createdEnd,
              update: (newStart, newEnd) => {
                updateSearchState({
                  createdStart: newStart,
                  createdEnd: newEnd,
                });
              },
            }),
          },
          {
            header: t("browse.columnHttpMethod"),
            width: { type: "static-em", value: 7 },
            renderCell: (event) => event.httpMethod?.toString(),
            columnKey: "httpMethod",
            filter: createMultiChoiceFilter({
              options: httpMethods,
              currentChoices: searchState.httpMethods,
              choiceToLabel: (method) => method,
              updateChoices: (httpMethods) => updateSearchState({ httpMethods }),
            }),
          },
          {
            header: t("browse.columnPath"),
            width: { type: "weighted", value: 3 },
            renderCell: (event) => event.path,
            ascending: "path, created, id",
            descending: "path desc, created desc, id desc",
            columnKey: "path",
            filter: createTextInputFilter({
              currentValue: searchState.path,
              update: (path) => updateSearchState({ path }),
            }),
          },
          {
            header: t("browse.columnUsername"),
            width: { type: "weighted", value: 1 },
            renderCell: (event) => event.username,
            ascending: "username, created, id",
            descending: "username desc, created desc, id desc",
            columnKey: "username",
            filter: createTextInputFilter({
              currentValue: searchState.username,
              update: (username) => updateSearchState({ username }),
            }),
          },
          {
            header: t("browse.columnStatus"),
            width: { type: "static-em", value: 8 },
            renderCell: (event) => t("requestState." + event.status),
            ascending: "status, created, id",
            descending: "status desc, created desc, id desc",
            columnKey: "status",
            filter: createMultiChoiceFilter({
              options: requestStates,
              currentChoices: searchState.statuses,
              choiceToLabel: (status) => t("requestState." + status),
              updateChoices: (statuses) => updateSearchState({ statuses }),
            }),
          },
          {
            header: t("browse.columnResponseHttpStatus"),
            width: { type: "static-em", value: 9 },
            renderCell: (event) => event.responseHttpStatus ?? "",
            columnKey: "httpStatus",
            filter: createTextInputFilter({
              currentValue: searchState.httpStatus,
              update: (httpStatus) => updateSearchState({ httpStatus }),
            }),
          },
          {
            header: t("browse.columnServerDuration"),
            width: { type: "static-em", value: 10 },
            renderCell: (event) => serverDurationToString(event),
            ascending: "serverDuration, created, id",
            descending: "serverDuration desc, created desc, id desc",
            columnKey: "serverDuration",
          },
          {
            header: t("browse.columnProxyDuration"),
            width: { type: "static-em", value: 10 },
            renderCell: (event) => proxyDurationToString(event),
            ascending: "proxyDuration, created, id",
            descending: "proxyDuration desc, created desc, id desc",
            columnKey: "proxyDuration",
          },
          {
            header: "",
            width: { type: "static-em", value: 5 },
            columnKey: "actions",
            textAlign: "right",
            renderCell: (event) => {
              return <Link to={`/monitor/${event.id}`}>{ct("browse.viewLink")}</Link>;
            },
          },
        ]}
        onSort={(newSort) => {
          submitSearch({ sort: newSort });
        }}
        currentSort={searchState.sort}
        onPageChanged={(newActivePage) => {
          submitSearch({ page: newActivePage });
        }}
      />
    </ResultsContainer>
  );
};

export default BrowseRequestEventsScreen;
