import { Icon, Loader, SemanticICONS } from "semantic-ui-react";
import { resolveConditionalClasses, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { AbakusCOLORS } from "../../commons-ts-utils/utils/colors";
import { useFloating } from "@floating-ui/react";
import {
  FocusEventHandler,
  KeyboardEvent as ReactKeyboardEvent,
  ReactElement,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { Flex } from "../flex/Flex";
import { TextDisplay } from "../text/TextDisplay";
import styles from "./Dropdown.module.css";
import { Label } from "../label/Label";
import { Cell, Grid } from "../grid/Grid";
import { max, min, sum } from "lodash";
import { useCommonsTranslate } from "../message-context/MessageContext";
import { Space } from "../space/Space";
import { TooltipWrapper } from "../tooltip/TooltipWrapper";

export type DropdownProps<KeyType extends number | string> = StandardComponentProps & {
  /**
   * The options/items displayed in this dropdown when it is opened.
   */
  options: DropdownItem<KeyType>[];
  /**
   * A text-value shown in this dropdown if the `selection` property is an empty array or undefined.
   */
  placeholder?: string;
  /**
   * If true, the dropdown indicates that it is loading the items to display. The {@link options} will not be shown
   * when this is `true`.
   */
  loading?: boolean;
  /**
   * A tooltip for this component, shown when the user hovers a mouse over it for some time.
   */
  tooltip?: string;
  /**
   * If true, the component becomes grayed out and uninteractable.
   */
  disabled?: boolean;
  /**
   * If true, then the component shows a delete-icon on the right side whenever the current `selection` is defined and
   * a non-empty array. Clicking this icon will clear the current selection.
   */
  clearable?: boolean;
  /**
   * If `true`, then the component becomes searchable. It maintains its own state for the user-inputted search string.
   * If a string, then the component also becomes searchable, but instead uses the supplied string as the value of the
   * currently inputted search.
   */
  search?: boolean | string;
  /**
   * Invoked whenever the component is searchable and the user updates their current search input.
   */
  onSearchChanged?: (search: string) => void;
  /**
   * A convenience property that can be used to filter the items based on the search, when using a non-controlled
   * search mode. Should return `true` for every item that the dropdown should display for the given search.
   */
  filter?: (option: DropdownItem<KeyType>, search: string) => boolean;
  /**
   * If true, the field will be disabled but not grayed out, effectively becoming a display of the currently selected
   * information.
   */
  viewMode?: boolean;
  /**
   * If `true`, the dropdown will receive a red highlight to indicate an error.
   */
  error?: boolean;
  /**
   * The text that will be displayed when the dropdown is searchable, and the current search did not result in any
   * matching items.
   */
  noResultsText?: string;
  /**
   * A callback invoked when the dropdown part of the dropdown is closed.
   */
  onClose?: () => void;
  /**
   * A callback invoked when the dropdown part of the dropdown is opened.
   */
  onOpen?: () => void;
  /**
   * A custom renderer for drawing items in the dropdown list. Currently only applies to items in the list, but not
   * the actual selections.
   */
  customItemRenderer?: (item: DropdownItem<KeyType>) => ReactElement;
  /**
   * A function returning the size of the given item in pixels. Must be specified if {@link customItemRenderer} is
   * specified. Used to estimate item sizes for the virtual scrolling used by the dropdown.
   */
  estimateItemSize?: (item: DropdownItem<KeyType>) => number;
} & (MultiSelectDropdownProps<KeyType> | SingleSelectDropdownProps<KeyType>);

export interface MultiSelectDropdownProps<T extends number | string> {
  /**
   * The currently selected items for a multi-selection dropdown. Displayed as chips inside the dropdown. Not all
   * properties (like the icon or bubble) for the items are necessarily rendered.
   */
  selection?: DropdownItem<T>[];
  /**
   * Invoked when the selection changes for any reason. The parameter given is the new array of selected values.
   */
  onChange: (newValue: DropdownItem<T>[]) => void;
  multiple: true;
}

export interface SingleSelectDropdownProps<T extends number | string> {
  /**
   * The currently selected item for a single-selection dropdown. Single-selection is the default behaviour, and is
   * used unless {@link multiple} is given as `true`.
   */
  selection?: DropdownItem<T>;
  /**
   * A callback that's invoked when the currently selected item changes for any reason.
   */
  onChange: (newValue: DropdownItem<T> | undefined) => void;
  multiple?: false;
}

export interface DropdownItem<KeyType extends number | string> {
  /**
   * The main identifier for this item. Often an id of some entity.
   */
  key: KeyType;
  /**
   * The text displayed for this item.
   */
  text?: string;
  /**
   * An icon rendered for this item.
   */
  icon?: SemanticICONS;
  /**
   * If specified, them rendering of this item will show a small bubble of the given color on its left side.
   */
  bubble?: AbakusCOLORS;
  /**
   * Any data that code using the dropdown wants to attach into an item, so that it can be accessed in the selection
   * -change callback.
   */
  payload?: any;
}

/**
 * The default item height when a custom renderer is not supplied.
 */
const ITEM_HEIGHT_PX = 36;

export const Dropdown = <KeyType extends number | string>({
  options: allOptions,
  search,
  onSearchChanged,
  loading,
  filter,
  placeholder,
  disabled,
  viewMode,
  error,
  selection,
  onChange,
  multiple,
  clearable,
  tooltip,
  noResultsText,
  onOpen,
  onClose,
  customItemRenderer,
  estimateItemSize,
  ...standardProps
}: DropdownProps<KeyType>) => {
  const { x, y, strategy, refs } = useFloating({});
  const [isOpen, setOpen] = useState(false);
  // Used when search is not controlled from the outside.
  const [internalSearchText, setInternalSearchText] = useState("");
  const t = useCommonsTranslate("dropdown");

  const dropdownRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const parentOffsetRef = useRef(0);
  const interactable = !viewMode && !disabled;

  // If the search-text is controlled, then force the internal state to match
  let searchText = typeof search === "string" ? search : internalSearchText;
  let hasSearch = typeof search === "string" || search;
  const setSearchText = onSearchChanged ? onSearchChanged : setInternalSearchText;

  // If, using some combination of keyboard/mouse inputs that has not been accounted for, the user manages to open
  // the dropdown when not interactable, just immediately set the dropdown back to closed.
  if (isOpen && !interactable) {
    setOpen(false);
    if (onClose !== undefined) {
      onClose();
    }
  }

  // If a custom renderer is defined, then the size-estimator must also be given. The size estimator should not be
  // given alone, as that doesn't really make sense.
  if (customItemRenderer !== undefined || estimateItemSize !== undefined) {
    if (customItemRenderer === undefined || estimateItemSize === undefined) {
      throw Error("Both customItemRenderer and estimateItemSize must be both defined if either prop is given");
    }
  }

  let displayedOptions: DropdownItem<KeyType>[] = [];
  if (loading) {
    displayedOptions = [];
  } else if (!hasSearch || searchText.length === 0) {
    displayedOptions = allOptions;
  } else if (filter) {
    displayedOptions = allOptions.filter((option) => filter(option, searchText));
  } else {
    displayedOptions = allOptions.filter((option) => {
      return option.text?.toLowerCase().includes(searchText.toLowerCase());
    });
  }

  // In multi-selection mode, we should not show the items the user has already selected in the dropdown list. A
  // selected item "moves" from the list of items into the area containing the items.
  if (multiple) {
    displayedOptions = displayedOptions.filter((item) => !selection?.some((value) => value.key === item.key));
  }

  useLayoutEffect(() => {
    parentOffsetRef.current = parentRef.current?.offsetTop ?? 0;
  }, []);

  const virtualizer = useVirtualizer({
    count: max([displayedOptions.length, 1])!!,
    getScrollElement: () => parentRef.current,
    estimateSize:
      displayedOptions.length === 0 || estimateItemSize === undefined
        ? () => ITEM_HEIGHT_PX
        : (index) => estimateItemSize(displayedOptions[index]),
  });
  const items = virtualizer.getVirtualItems();

  const dropdownClassName = resolveConditionalClasses([
    [true, styles.dropdown],
    [isOpen, styles.dropdownOpen],
    [!isOpen, styles.dropdownClosed],
    [!hasSearch !== undefined && interactable, styles.dropdownOpenable],
    [hasSearch && interactable, styles.dropdownSearchable],
    [viewMode, styles.viewMode],
    [error, styles.hasError],
  ]);

  const updateOpen = (open: boolean) => {
    if (!interactable && open) {
      return;
    }
    if (open !== isOpen) {
      setOpen(open);
      // For uncontrolled search components, we should reset the search whenever the open-state is changed
      if (typeof search !== "string") {
        setSearchText("");
      }
      if (open && onOpen !== undefined) {
        onOpen();
        // It may be possible to open the dropdown without focusing the input field if clicking at the right position.
        // Manually invoke focus() to ensure the field is focused in searchable dropdowns.
        if (inputRef.current && (search === true || typeof search === "string")) {
          inputRef.current.focus();
        }
      } else if (!open && onClose !== undefined) {
        onClose();
      }
    }
  };

  const handleItemSelected = (selectedItem: DropdownItem<KeyType>) => {
    if (multiple) {
      if (onChange) {
        if (selection === undefined) {
          onChange([selectedItem]);
        } else {
          let currentIndex = selection?.findIndex((item) => item.key === selectedItem.key);
          if (currentIndex >= 0) {
            onChange(selection.slice(0, currentIndex).concat(selection.slice(currentIndex + 1)));
          } else {
            onChange(selection.concat(selectedItem));
          }
        }
      }

      // Keep the dropdown open and focus the text field
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      if (onChange) {
        onChange(selectedItem);
      }
      updateOpen(false);
    }
  };

  const handleSearchInputKeyDown = (event: ReactKeyboardEvent<HTMLInputElement>) => {
    // If backspace is pressed with an empty search field, then we should remove the last selected value
    if (multiple && selection && selection.length > 0 && searchText.length === 0 && event.key === "Backspace") {
      onChange(selection.slice(0, selection.length - 1));
    }
  };

  const handleOnBlur: FocusEventHandler<any> = (event) => {
    // If the element-to-be-focused is a "dropdown-selectable" element, then we disregard the blur event altogether.
    // This custom attribute should only be found from elements created within this component, and will help
    // differentiate between blur events caused by unfocusing the component by clicking or tab navigating elsewhere,
    // and events caused by selecting an item from the list. If we were to close the component on the latter, then the
    // click event and subsequent selection of a value would be canceled.
    const selectable = event.relatedTarget?.attributes?.getNamedItem("x-dropdown-selectable")?.value;
    if (selectable === "true") {
      return;
    }

    updateOpen(false);
  };

  return (
    <TooltipWrapper tooltip={tooltip}>
      <Flex {...standardProps} column ref={dropdownRef}>
        <Flex
          faded={disabled && !viewMode}
          alignItems="stretch"
          onClick={() => {
            if (hasSearch) {
              updateOpen(true);

              if (inputRef.current) {
                inputRef.current.focus();
              }
            } else {
              updateOpen(!isOpen);
            }
          }}
          className={dropdownClassName}
          grow
          ref={refs.setReference}
        >
          <Flex
            grow
            gap={1}
            focusable={!hasSearch || !isOpen}
            onFocus={hasSearch ? () => updateOpen(true) : undefined}
            onBlur={(event) => {
              if (!hasSearch) {
                handleOnBlur(event);
              }
            }}
          >
            {multiple ? (
              <Flex grow gap={1} wrap alignSelf="stretch">
                {allOptions
                  .map((item) => {
                    return {
                      item,
                      order: selection?.findIndex((it) => it.key === item.key) ?? -1,
                    };
                  })
                  .filter(({ order }) => order >= 0)
                  .sort(({ order: left }, { order: right }) => {
                    return left - right;
                  })
                  .map(({ item }) => {
                    return (
                      <Label
                        size="small"
                        alignSelf="center"
                        interactable={interactable}
                        text={item.text}
                        key={item.key.toString()}
                        icon={viewMode ? "check" : "delete"}
                        onIconClick={viewMode ? undefined : () => handleItemSelected(item)}
                      />
                    );
                  })}
                {hasSearch && (isOpen || typeof search === "string") ? (
                  <input
                    onBlur={handleOnBlur}
                    disabled={disabled || viewMode}
                    readOnly={viewMode}
                    type="text"
                    ref={inputRef}
                    value={searchText}
                    onKeyDown={handleSearchInputKeyDown}
                    onChange={(event) => setSearchText(event.target.value)}
                    className={`${styles.searchField} ${styles.singleSelectContentElement} ${
                      selection === undefined || selection.length === 0 ? styles.searchFieldNoSelections : ""
                    }`}
                    autoFocus
                  />
                ) : (
                  <Space paddingTop={{ unit: "px", value: 27 }} />
                )}
                {hasSearch && !isOpen && typeof search === "string" && search.length > 0 ? (
                  <TextDisplay
                    marginTop={{ unit: "px", value: 5 }}
                    marginBottom={{ unit: "px", value: 5 }}
                    faded
                    value={search}
                  />
                ) : undefined}
              </Flex>
            ) : (
              <Flex grow gap={1} wrap alignSelf="stretch">
                {selection?.bubble ? (
                  <Label
                    marginRight={{ unit: "px", value: -5 }}
                    alignSelf="center"
                    className={styles.singleSelectContentElement}
                    faded={isOpen}
                    circular
                    color={selection.bubble}
                  />
                ) : undefined}
                {selection?.icon ? (
                  <Icon className={styles.singleSelectContentElement} faded={isOpen} name={selection.icon} />
                ) : undefined}
                <Grid grow templateRows={[{ unit: "px", value: 26 }]} templateColumns={["auto"]}>
                  {selection?.text && searchText.length === 0 ? (
                    <Cell flex row={0} column={0}>
                      <TextDisplay className={styles.singleSelectValue} faded={isOpen} value={selection.text} />
                    </Cell>
                  ) : placeholder && searchText.length === 0 && !isOpen ? (
                    <Cell flex row={0} column={0}>
                      <TextDisplay className={styles.singleSelectValue} faded value={placeholder} />
                    </Cell>
                  ) : undefined}

                  {hasSearch && (isOpen || typeof search === "string") ? (
                    <Cell row={0} column={0}>
                      <input
                        onBlur={handleOnBlur}
                        disabled={disabled || viewMode}
                        readOnly={viewMode}
                        type="text"
                        ref={inputRef}
                        value={searchText}
                        onChange={(event) => setSearchText(event.target.value)}
                        className={`${styles.searchField} ${styles.singleSelectContentElement}`}
                        autoFocus
                      />
                    </Cell>
                  ) : undefined}
                </Grid>
              </Flex>
            )}
          </Flex>

          {clearable && ((multiple && selection && selection.length > 0) || (!multiple && selection !== undefined)) ? (
            <Icon
              name="delete"
              onClick={
                interactable
                  ? (event: MouseEvent) => {
                      // Do not propagate the event. Otherwise the dropdown-div will see it and open itself, but this is not
                      // the behaviour that a user is likely to want when pressing the x-button.
                      event.stopPropagation();
                      if (multiple) {
                        onChange([]);
                      } else {
                        onChange(undefined);
                      }
                    }
                  : undefined
              }
              className={`${styles.clearIcon} ${interactable ? styles.interactableIcon : styles.hiddenIcon}`}
            />
          ) : (
            <Icon name="dropdown" className={`${styles.dropdownIcon} ${!interactable ? styles.hiddenIcon : ""}`} />
          )}
        </Flex>

        {isOpen ? (
          <div
            style={{
              position: "relative",
              flexGrow: 1,
            }}
            tabIndex={-1}
          >
            <div
              tabIndex={-1}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: "100%",
                zIndex: 1000,
              }}
            >
              <div
                {...{ "x-dropdown-selectable": "true" }}
                tabIndex={-1}
                ref={parentRef}
                className={styles.dropdownList}
                style={{
                  height: `${
                    displayedOptions.length === 0
                      ? ITEM_HEIGHT_PX
                      : displayedOptions.length <= 10
                      ? min([sum(displayedOptions.map(estimateItemSize ?? (() => ITEM_HEIGHT_PX))), 350])
                      : 350
                  }px`,
                  width: "100%",
                  overflowY: "auto",
                  contain: "strict",
                }}
              >
                <div
                  {...{ "x-dropdown-selectable": "true" }}
                  tabIndex={-1}
                  style={{
                    height: virtualizer.getTotalSize(),
                    width: "100%",
                    position: "relative",
                  }}
                >
                  <div
                    {...{ "x-dropdown-selectable": "true" }}
                    tabIndex={-1}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      transform: `translateY(${items[0].start}px)`,
                    }}
                  >
                    {items.map((virtualItem) => {
                      const index = virtualItem.index;

                      if (loading) {
                        return (
                          <div key={index.toString()} data-index={index} ref={virtualizer.measureElement}>
                            <Flex padding={1} alignItems="center" justifyContent="center">
                              <Loader active inline size="small" />
                            </Flex>
                          </div>
                        );
                      } else if (displayedOptions.length === 0) {
                        return (
                          <div key={index.toString()} data-index={index} ref={virtualizer.measureElement}>
                            <Flex padding={1}>
                              <TextDisplay faded value={noResultsText ?? t("noResultsInfo")} grow />
                            </Flex>
                          </div>
                        );
                      } else {
                        const element = displayedOptions[virtualItem.index];
                        const isActive =
                          selection !== undefined && "key" in selection && element.key === selection?.key;
                        const elementClasses = resolveConditionalClasses([
                          [true, styles.item],
                          [isActive, styles.itemActive],
                          [!isActive, styles.itemInactive],
                        ]);

                        if (customItemRenderer !== undefined) {
                          return (
                            <div key={index.toString()} data-index={index} ref={virtualizer.measureElement}>
                              <Flex
                                customAttributes={{
                                  "x-dropdown-selectable": "true",
                                }}
                                onClick={() => handleItemSelected(element)}
                                key={element.key}
                                gap={1}
                                className={elementClasses}
                                instantFadeTransition
                              >
                                {customItemRenderer(displayedOptions[index])}
                              </Flex>
                            </div>
                          );
                        } else {
                          return (
                            <div key={index.toString()} data-index={index} ref={virtualizer.measureElement}>
                              <Flex
                                customAttributes={{
                                  "x-dropdown-selectable": "true",
                                }}
                                onClick={() => handleItemSelected(element)}
                                key={element.key}
                                gap={1}
                                className={elementClasses}
                                instantFadeTransition
                              >
                                {element.bubble ? (
                                  <Label circular color={element.bubble} alignSelf="center" />
                                ) : undefined}
                                {element.icon ? <Icon name={element.icon} /> : undefined}
                                {element.text ? <TextDisplay value={element.text} grow /> : undefined}
                              </Flex>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
      </Flex>
    </TooltipWrapper>
  );
};
