import { isEqual } from "lodash";

/**
 * Returns `true` if an only if all the properties of the given partial object are present with equal values in the full
 * object. Equality for properties is checked using lodash's {@link isEqual}, which is a nested/recursive property
 * comparison itself.
 */
export const isPartialEqual = <T extends { [key: string]: any }>(object: T, partial: Partial<T>): boolean => {
  return !Object.keys(partial).some((property) => {
    if (!(property in object)) {
      return true;
    }

    return !isEqual(object[property], partial[property]);
  });
};
