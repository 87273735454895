import { Container } from "semantic-ui-react";
import { Flex, FlexProps } from "../flex/Flex";
import { useIsMobileScreen } from "../../commons-react-hooks/util/useIsMobileScreen";

export interface ResponsiveContainerProps extends FlexProps {
  /**
   * If defined, overrides the container-using selection from "is mobile screen" into the value given here.
   */
  when?: boolean;
  /**
   * If given, this container gets a height equal to the full height of its parent.
   */
  fullHeight?: boolean;
}

/**
 * A container element for a page. If on a wide-enough desktop screen, then this limits the available horizontal space
 * so that the elements are not spread out over too wide of an area. If on a mobile screen, this adds no additional
 * margin to the sides. The container is also a flex-container.
 */
export const ResponsiveContainer = ({
  when,
  column,
  children,
  fullHeight,
  height,
  ...flexProps
}: ResponsiveContainerProps) => {
  const isMobileScreen = useIsMobileScreen();

  if ((when === undefined && !isMobileScreen) || when) {
    return (
      <Container
        style={{
          height: fullHeight ? "100%" : undefined,
        }}
      >
        <Flex column={column ?? true} height={fullHeight ? "fill" : height} {...flexProps}>
          {children}
        </Flex>
      </Container>
    );
  } else {
    return (
      <Flex column={column ?? true} height={fullHeight ? "fill" : height} {...flexProps}>
        {children}
      </Flex>
    );
  }
};
