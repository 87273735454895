import React from "react";
import { Button, ButtonGroup } from "../button/Button";
import { max, min, range, toNumber } from "lodash";
import { Form, Input, Popup } from "semantic-ui-react";
import { isNumber } from "../../commons-ts-utils/utils/numberUtils";
import styles from "./Pagination.module.css";

export interface PaginationProps {
  currentPage: number | undefined;
  firstPage: number;
  lastPage: number;
  surroundingPages: number;
  onPageChanged: (newPage: number) => void;
}

const AnyPageButton = ({
  currentPage,
  onPageChanged,
}: {
  currentPage: number;
  onPageChanged: (newPage: number) => void;
}) => {
  const [pagePopupOpen, setPagePopupOpen] = React.useState(false);
  const [pagePopupValue, setPagePopupValue] = React.useState("0");

  const togglePaginationPopup = () => {
    if (!pagePopupOpen) {
      setPagePopupValue("");
    }
    setPagePopupOpen((open) => !open);
  };

  const closePaginationPopup = () => {
    setPagePopupOpen(false);
  };

  const handlePopupNavigation = () => {
    if (!isNumber(pagePopupValue)) {
      return;
    }
    onPageChanged(toNumber(pagePopupValue));
    closePaginationPopup();
  };

  return (
    <Popup
      trigger={<Button className={styles.paginationButton} onClick={togglePaginationPopup} text="…" />}
      on="click"
      content={
        <Form className={styles.paginationPopup} onSubmit={handlePopupNavigation}>
          <Input
            autoFocus
            className={styles.paginationPopupInput}
            value={pagePopupValue}
            placeholder={currentPage.toString()}
            onChange={(event) => setPagePopupValue(event.target.value)}
          />
          <Button primary icon="arrow right" disabled={!isNumber(pagePopupValue)} type="submit" />
        </Form>
      }
      open={pagePopupOpen}
      onClose={closePaginationPopup}
      onOpen={() => {}}
      position="top center"
    />
  );
};

/**
 * A component for displaying pagination controls for search results. Shows the page the user is currently viewing,
 * plus some surrounding pages, first and final pages, buttons for navigating to the previous and next page. The
 * component also displays a button (or multiple buttons) that allow jumping to any page of the results, but these are
 * only displayed if there are pages for which there's otherwise no button available in the bar.
 *
 * The main difference between this component and that provided by `semantic-ui-react` is that this component allows
 * displaying a current page that is out of bounds for the result set, i.e. over the last page. This is a scenario that
 * can easily occur if the user changes the query's filters while on the non-first page of the results.
 * @param currentPage The page currently being displayed in a results container
 * @param firstPage The first page of the query results. This is typically 1.
 * @param lastPage The last page of the query results. Should be at least equal to firstPage.
 * @param surroundingPages The number of buttons to display on either side of the current page. For example, with a
 *                         value of 3 for this and a value of 14 for currentPage, the component would display pages
 *                         11-17 in addition to the first and last pages.
 * @param onPageChanged A callback that is invoked when the user changes a page in some way using this component.
 */
export const Pagination = ({ currentPage, firstPage, lastPage, surroundingPages, onPageChanged }: PaginationProps) => {
  const surroundingBefore = range(
    max([firstPage + 1, (currentPage ?? 1) - surroundingPages]) ?? 1,
    currentPage ?? 1
  ).filter((page) => page <= min([lastPage, currentPage ?? 1])!!);
  const surroundingAfter = range(
    min([(currentPage ?? 1) + 1, lastPage])!!,
    min([lastPage, (currentPage ?? 1) + surroundingPages + 1]) ?? 1
  ).filter((page) => page > (currentPage ?? 1));

  return (
    <ButtonGroup basic compact>
      <Button
        className={styles.paginationButton}
        onClick={() => onPageChanged((currentPage ?? 1) - 1)}
        icon="chevron left"
        disabled={currentPage === undefined || currentPage <= firstPage}
      />
      {currentPage === undefined ? (
        <Button className={styles.paginationButton} text="-" disabled />
      ) : (
        <>
          {firstPage < currentPage ? (
            <Button
              className={styles.paginationButton}
              onClick={() => onPageChanged(firstPage)}
              text={firstPage.toString()}
            />
          ) : undefined}

          {(min(surroundingBefore) === undefined && currentPage > firstPage + 1) ||
          min(surroundingBefore)!! > firstPage + 1 ? (
            <>
              <AnyPageButton currentPage={currentPage} onPageChanged={onPageChanged} />
            </>
          ) : undefined}

          {surroundingBefore.map((page) => {
            return (
              <Button
                key={`pagination_${page}`}
                className={styles.paginationButton}
                onClick={() => onPageChanged(page)}
                text={page.toString()}
              />
            );
          })}

          <Button
            key={`pagination_${currentPage}`}
            active
            className={styles.currentPageButton}
            text={currentPage.toString()}
          />

          {surroundingAfter.map((page) => {
            return (
              <Button
                key={`pagination_${page}`}
                className={styles.paginationButton}
                onClick={() => onPageChanged(page)}
                text={page.toString()}
              />
            );
          })}

          {(min(surroundingAfter) === undefined && currentPage < lastPage - 1) ||
          min(surroundingAfter)!! < lastPage - 1 ? (
            <>
              <AnyPageButton currentPage={currentPage} onPageChanged={onPageChanged} />
            </>
          ) : undefined}
          {lastPage > currentPage ? (
            <Button
              className={styles.paginationButton}
              onClick={() => onPageChanged(lastPage)}
              text={lastPage.toString()}
            />
          ) : undefined}
        </>
      )}
      <Button
        onClick={() => onPageChanged((currentPage ?? 1) + 1)}
        icon="chevron right"
        disabled={currentPage === undefined || currentPage >= lastPage}
      />
    </ButtonGroup>
  );
};
