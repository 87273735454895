import styles from "./TextDisplay.module.css";
import { SemanticSIZES } from "semantic-ui-react";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { MouseEventHandler } from "react";
import { TooltipWrapper } from "../tooltip/TooltipWrapper";

export interface TextDisplayProps extends StandardComponentProps {
  /**
   * If true, then the text cannot be highlighted
   */
  noSelect?: boolean;
  /**
   * The textual value displayed in this component.
   */
  value: string;
  /**
   * If true, then the text will be emphasized by giving it a bold font-weight.
   */
  emphasized?: boolean;
  /**
   * A value to control the font size of the element. Defaults to 'medium'.
   */
  size?: TextSize;
  /**
   * If true, the text will not wrap to multiple lines.
   */
  noWrap?: boolean;
  /**
   * A callback that is called when this element is clicked.
   */
  onClick?: MouseEventHandler<HTMLSpanElement>;
  /**
   * If true, then the text will be displayed in a monospaced font.
   */
  monospace?: boolean;
  /**
   * A popup-tooltip for this component.
   */
  tooltip?: string;
  /**
   * How to align the text inside this display.
   */
  textAlign?: "start" | "end" | "center";
}

export type TextSize = SemanticSIZES;

export const TextDisplay = ({
  noSelect,
  value,
  size,
  monospace,
  emphasized,
  noWrap,
  onClick,
  tooltip,
  textAlign,
  ...other
}: TextDisplayProps) => {
  const { className, style } = resolveStandardStyling({
    ...other,
    conditionalStyles: [[true, () => ["textAlign", textAlign ?? "start"]]],
    conditionalClassNames: [
      [noSelect, styles.noSelect],
      [emphasized, styles.emphasized],
      [true, styles[`size-${size ?? "medium"}`]],
      [noWrap, styles.noWrap],
      [monospace, styles.monospace],
    ],
  });

  return (
    <TooltipWrapper tooltip={tooltip}>
      <span className={className} style={style} onClick={onClick}>
        {value}
      </span>
    </TooltipWrapper>
  );
};
