import React, { ReactNode, useState } from "react";
import { Icon, Menu, Segment, SemanticICONS, Sidebar } from "semantic-ui-react";
import { Link } from "react-router-dom";
import styles from "./StandardSideBar.module.css";
import { useIsMobileScreen } from "../../commons-react-hooks/util/useIsMobileScreen";

export interface SidebarItemCommon {
  /**
   * The user-visible label/text for this item.
   */
  label: string;
  /**
   * A convenience property which, if true, causes the sidebar to act as if the item was not in its list in the first
   * place.
   */
  hidden?: boolean;
  /**
   * Should this link be available in desktop view, mobile view, or both views. Default is both.
   */
  availableIn?: "desktop" | "mobile" | "both";
  /**
   * A function that is invoked during render time to resolve any additional styling information related to this sidebar
   * item.
   */
  resolveClassName?: (active: boolean) => string | undefined;
}

export interface SidebarLink extends SidebarItemCommon {
  type: "link";
  /**
   * The unique icon for this item, from the group of semantic icons.
   */
  icon: SemanticICONS;
  /**
   * The link that should be navigated to when this is clicked.
   */
  link: string;
}

export interface SidebarContainer extends SidebarItemCommon {
  type: "container";
  /**
   * A list of child-items that should be shown when this item is clicked. Support for nested containers has not been
   * implemented.
   */
  children: SidebarLink[];
}

export type SidebarItem = SidebarLink | SidebarContainer;

export interface StandardSideBarProps {
  items: SidebarItem[];
  versionInfo?: string;
  copyrightInfo?: string;
  currentPath: string;
  visible: boolean;
  onHide?: () => void;
  children: ReactNode;
  /**
   * Determines whether to render the system in mobile or desktop mode. If undefined or 'responsive', then the sidebar
   * will use its own heuristics to decide which mdoe to use.
   */
  mode?: "mobile" | "desktop" | "responsive";
}

interface ContainerMenuItemProps {
  item: SidebarContainer;
  index: number;
  currentPath: string;
}

const ContainerMenuItem = ({ item, index, currentPath }: ContainerMenuItemProps) => {
  const visibleChildren = item.children.filter((child) => child.hidden !== true);
  const containerActive = visibleChildren.some((child) => currentPath.startsWith(child.link));
  const [open, setOpen] = useState(containerActive);

  return (
    <Menu.Item
      className={item.resolveClassName ? item.resolveClassName(containerActive) : undefined}
      key={`sidebar_item_${index}`}
      active={containerActive}
      onClick={(event) => {
        if (event.target === event.currentTarget) {
          setOpen(!open);
        }
      }}
    >
      {item.label}
      <Icon key={`sidebar_item_${index}_icon`} name={open ? "triangle down" : "triangle right"} />
      {open ? (
        <Menu.Menu>
          {visibleChildren.map((childItem, childIndex) => (
            <Menu.Item
              className={
                childItem.resolveClassName
                  ? childItem.resolveClassName(currentPath.startsWith(childItem.link))
                  : undefined
              }
              key={`sidebar_item_${index}_child_${childIndex}`}
              as={Link}
              to={childItem.link}
              active={currentPath.startsWith(childItem.link)}
            >
              <Icon name={childItem.icon} />
              {childItem.label}
            </Menu.Item>
          ))}
        </Menu.Menu>
      ) : (
        ""
      )}
    </Menu.Item>
  );
};

const isItemAvailableIn = (item: SidebarItem, viewType: "mobile" | "desktop"): boolean => {
  if (item.availableIn === undefined || item.availableIn === "both") {
    return true;
  } else {
    return item.availableIn === viewType;
  }
};

const renderItem = (
  item: SidebarItem,
  index: number,
  currentPath: string,
  viewType: "mobile" | "desktop"
): JSX.Element | null => {
  if (item.hidden === true || !isItemAvailableIn(item, viewType)) {
    return null;
  } else if (item.type === "link") {
    return (
      <Menu.Item
        key={`sidebar_item_${index}`}
        as={Link}
        className={item.resolveClassName ? item.resolveClassName(currentPath.startsWith(item.link)) : undefined}
        to={item.link}
        active={currentPath.startsWith(item.link)}
      >
        {item.label}
        <Icon key={item.icon} name={item.icon} />
      </Menu.Item>
    );
  } else if (item.type === "container") {
    const visibleChildren = item.children.filter((child) => child.hidden !== true);

    if (visibleChildren.length === 0) {
      return null;
    } else {
      return <ContainerMenuItem key={`sidebar_item_${index}`} item={item} index={index} currentPath={currentPath} />;
    }
  } else {
    return null;
  }
};

/**
 * A component that can be used to add a sidebar-navigation component with standard styling to an application.
 * @param items An array of items to display in the sidebar.
 * @param versionInfo A string describing the current version of the application that is to be displayed.
 * @param copyrightInfo A string displayed under the version, intended to show a copyright message.
 * @param currentPath The currently active path for the application, used to determine which of the items is currently
 *                    active.
 */
export const StandardSideBar = ({
  items,
  versionInfo,
  copyrightInfo,
  currentPath,
  visible,
  onHide,
  children,
  mode,
}: StandardSideBarProps) => {
  const isMobileScreen = useIsMobileScreen();
  const isMobile = mode === "mobile" || (mode !== "desktop" && isMobileScreen);
  return (
    <>
      {isMobile ? (
        <MobileSidebar
          items={items}
          versionInfo={versionInfo}
          copyrightInfo={copyrightInfo}
          currentPath={currentPath}
          visible={visible}
          onHide={onHide}
        >
          {children}
        </MobileSidebar>
      ) : (
        <DesktopSidebar
          items={items}
          versionInfo={versionInfo}
          copyrightInfo={copyrightInfo}
          currentPath={currentPath}
          visible={visible}
          onHide={onHide}
        >
          {children}
        </DesktopSidebar>
      )}
    </>
  );
};

const MobileSidebar = ({
  items,
  versionInfo,
  copyrightInfo,
  currentPath,
  visible,
  onHide,
  children,
}: StandardSideBarProps) => {
  return (
    <Sidebar.Pushable as={Segment} className={styles.mobileSidebarContainer}>
      <Sidebar
        as="div"
        className={styles.sidebarContainer}
        animation="overlay"
        onHide={onHide}
        vertical
        visible={visible}
        width="thin"
      >
        <Menu className={styles.sideBar} vertical visible="true">
          {items.map((item, index) => renderItem(item, index, currentPath, "mobile"))}
        </Menu>
        <div className={styles.versionNumberContainer}>
          {versionInfo ? <div className={styles.versionNumberText}>{versionInfo}</div> : undefined}
          {copyrightInfo ? <div className={styles.versionNumberText}>{copyrightInfo}</div> : undefined}
        </div>
      </Sidebar>
      <Sidebar.Pusher dimmed={visible} className={styles.mobileSidebarContentArea}>
        {children}
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
};

const DesktopSidebar = ({
  items,
  versionInfo,
  copyrightInfo,
  currentPath,
  visible,
  onHide,
  children,
}: StandardSideBarProps) => {
  return (
    <>
      <div className={styles.sidebarContainer}>
        <Menu className={styles.sideBar} vertical visible="true">
          {items.map((item, index) => renderItem(item, index, currentPath, "desktop"))}
        </Menu>
        <div className={styles.versionNumberContainer}>
          {versionInfo ? <div className={styles.versionNumberText}>{versionInfo}</div> : undefined}
          {copyrightInfo ? <div className={styles.versionNumberText}>{copyrightInfo}</div> : undefined}
        </div>
      </div>
      <>{children}</>
    </>
  );
};
