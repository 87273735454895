import React, { ReactNode } from "react";

import styles from "./Title.module.css";
import { Header, Icon, Placeholder, SemanticCOLORS, SemanticICONS } from "semantic-ui-react";
import { Link, To } from "react-router-dom";
import { resolveStandardStyling, StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { Flex } from "../flex/Flex";
import { Button } from "../button/Button";
import { PopupMenuButton } from "../button/PopupMenuButton";
import { useIsMobileScreen } from "../../commons-react-hooks/util/useIsMobileScreen";

interface CommonAction {
  /**
   * The icon that should be displayed in this action's button.
   */
  icon: SemanticICONS;
  /**
   * The text label that should be displayed in this action's button.
   */
  label: string;
  /**
   * Should the button of this action be disabled.
   */
  disabled?: boolean;
  /**
   * The color of this action's button.
   */
  color?: SemanticCOLORS;
  /**
   * React key for this action.
   */
  key?: string;
  /**
   * Should this action be hidden (by default it's not hidden).
   */
  hidden?: boolean;
}

/**
 * This type of action acts as a link when the action's button is clicked.
 */
export type LinkAction = CommonAction & {
  type: "link";
  /**
   * The target where the user is navigated to when this action's button is clicked.
   */
  linkTo: To;
};

/**
 * This type of action acts as button with a callback when the button is clicked.
 */
export type ButtonAction = CommonAction & {
  type: "button";
  /**
   * Callback that is called when this action's button is clicked.
   */
  onClick: () => void;
};

export type TitleAction = LinkAction | ButtonAction;

type ButtonType = "basic" | "primary" | "secondary" | "positive" | "negative";

/**
 * Actions that have this type appear as buttons in the title and are visible at all times.
 */
export type PrimaryAction = TitleAction & {
  /**
   * What types should the action's button have.
   */
  buttonTypes?: ButtonType[];
  /**
   * If this is `true`, a loading icon is displayed in the button instead of the icon and label.
   */
  submitting?: boolean;
};

/**
 * Actions that have this type appear behind another button with three dots.
 */
export type SecondaryAction = TitleAction;

export interface TitleProps extends StandardComponentProps {
  /**
   * The user-visible title that is displayed by this component.
   */
  title: string | ReactNode;
  /**
   * A semanticICONS icon that is displayed next to the table.
   */
  icon?: SemanticICONS;
  /**
   * A semanticCOLORS color definition that is used to change the displayed icon's color. Only has an effect if `icon`
   * is also given.
   */
  iconColor?: SemanticCOLORS;
  /**
   * Whether the primary actions should be displayed on a separate row under the main title text.
   */
  separateActionRow?: boolean;
  /**
   * The action(s) to be displayed on the right side of the title. These actions are displayed as buttons on the title
   * row.
   */
  primaryActions?: PrimaryAction[];
  /**
   * The action(s) to be displayed on the right side of the title, inside their own submenu.
   */
  secondaryActions?: TitleAction[];
  /**
   * Whether to display a loading placeholder in place of the title. Note that this should typically only be used if a
   * page cannot display the title due to a lack of information, i.e. it is still fetching the related entity which's
   * name it wishes to show in the title. It is usually not a good idea to indicate general "the page is loading
   * something" with this property.
   */
  loading?: boolean;
  /**
   * Can be used to fade out the title and display a loader in place of the icon in the action. Intended to be used
   * during the loading time of a form-submission on the page this title is for.
   */
  submitting?: boolean;
  /**
   * Whether the title should show a highlight for an error. This can be used on pages with forms to inform the user
   * that the form has some error somewhere.
   */
  errorHighlight?: boolean;
  /**
   * Make the secondary actions popup controlled: the popup is open when this is `true` and closed when this is `false`.
   * If this is defined, `setSecondaryActionsOpen` must also be defined. That function will be called when the button
   * for secondary actions is clicked and the negation of the current value of this property will be passed as an
   * argument.
   */
  secondaryActionsOpen?: boolean;
  /**
   * This function is called when secondary actions' popup is controlled (i.e. `secondaryActionsOpen` is defined) and
   * the secondary actions' button is clicked or user clicks outside the popup. Negation of the current
   * `secondaryActionsOpen` will be passed as a parameter.
   */
  setSecondaryActionsOpen?: (secondaryActionsOpen: boolean) => void;
}

/**
 * A component that can be used to add titles with a standardized styling to pages. The Title-component additionally
 * supports some commonly used utilities, such as highlighting the title with a different color to indicate an error, or
 * displaying a button for an action on the right side of the title. See the {@link TitleProps} for more information.
 */
export const Title = ({
  icon,
  title,
  iconColor,
  primaryActions,
  secondaryActions,
  loading,
  submitting,
  errorHighlight,
  separateActionRow,
  secondaryActionsOpen,
  setSecondaryActionsOpen,
  ...otherProps
}: TitleProps) => {
  const { className, style } = resolveStandardStyling(otherProps);
  const isMobileScreen = useIsMobileScreen();

  if (loading) {
    return (
      <Placeholder className={`${styles.placeholder} ${className ?? ""}`} style={style}>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    );
  }

  const includesButtonType = (types: ButtonType[] | undefined, testedType: ButtonType): boolean => {
    if (types === undefined) {
      return false;
    } else {
      return types.includes(testedType);
    }
  };

  const WrapperComponent = ({ children }: { children: ReactNode }) => {
    if (separateActionRow ?? isMobileScreen) {
      return (
        <Flex column grow rowGap={0.5}>
          {children}
        </Flex>
      );
    } else {
      return <>{children}</>;
    }
  };

  const visiblePrimaryActions = primaryActions?.filter((action) => action.hidden !== true);
  const visibleSecondaryActions = secondaryActions?.filter((action) => action.hidden !== true);

  return (
    <Header as="h2" className={`${styles.container} ${className ?? ""}`} style={style}>
      <WrapperComponent>
        <Flex grow>
          {icon && <Icon name={icon} color={iconColor} className={submitting ? styles.submitting : ""} />}
          <Header.Content
            className={`${styles.title} ${submitting ? styles.submitting : ""} ${
              errorHighlight ? styles.errorHighlight : ""
            }`}
          >
            {title}
          </Header.Content>
        </Flex>
        <Flex gap={0.5} alignItems="center" justifyContent="end">
          {visiblePrimaryActions &&
            visiblePrimaryActions.map((action) => (
              <Button
                grow={(separateActionRow ?? isMobileScreen) && visiblePrimaryActions.length > 1}
                key={action.key}
                as={
                  action.type === "link"
                    ? {
                        component: Link,
                        props: {
                          to: action.linkTo,
                        },
                      }
                    : undefined
                }
                leftIcon={action.icon}
                text={action.label}
                onClick={action.type === "button" ? action.onClick : undefined}
                loading={action.submitting ? action.submitting : false}
                basic={
                  action.buttonTypes === undefined ||
                  action.buttonTypes.length === 0 ||
                  includesButtonType(action.buttonTypes, "basic")
                }
                primary={includesButtonType(action.buttonTypes, "primary")}
                secondary={includesButtonType(action.buttonTypes, "secondary")}
                positive={includesButtonType(action.buttonTypes, "positive")}
                negative={includesButtonType(action.buttonTypes, "negative")}
                disabled={action.disabled}
                color={action.color}
              />
            ))}
          {visibleSecondaryActions && visibleSecondaryActions.length > 0 && (
            <PopupMenuButton
              items={visibleSecondaryActions.map((action) => {
                if (action.type === "link") {
                  const { label, icon, linkTo, disabled } = action;
                  return {
                    to: linkTo,
                    label,
                    icon,
                    disabled,
                  };
                } else {
                  const { label, icon, onClick, disabled } = action;
                  return {
                    label,
                    icon,
                    onClick,
                    disabled,
                  };
                }
              })}
              popupPosition="bottom left"
              popupOpen={secondaryActionsOpen}
              setPopupOpen={setSecondaryActionsOpen}
            />
          )}
        </Flex>
      </WrapperComponent>
    </Header>
  );
};
