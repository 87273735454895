import React from "react";

import { listUsers } from "../../../../api/services/users";
import User, { UserQuery, userQueryDeserializer, userQuerySerializer } from "../../../../api/model/User";
import { Button, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { useTranslator } from "../../../../i18n/hooks";
import { DateTimeFormatter } from "@js-joda/core";
import { useRequest } from "../../../../ts-commons/commons-react-hooks/request/RequestContext";
import { useSubmittableSearch } from "../../../../ts-commons/commons-react-hooks/request/useSubmittableSearch";
import {
  ResultsTable,
  ResultsContainer,
} from "../../../../ts-commons/commons-react-components/results-table/ResultsTable";
import {
  createBooleanDeletedFilter,
  createTextInputFilter,
} from "../../../../ts-commons/commons-react-components/results-table/tableFilters";
import { PagedResults } from "../../../../ts-commons/commons-react-hooks/request/models";
import { useDebounce } from "../../../../ts-commons/commons-react-hooks/util/useDebounce";
import { StandardBrowsePageHeader } from "../../../../ts-commons/commons-react-components/results-table/StandardBrowsePageHeader";

const BrowseUsersScreen: React.FC = () => {
  const t = useTranslator("users.browse");
  const ct = useTranslator("common");

  const performListUsers = useRequest(listUsers);

  const [searchState, updateSearchState, performSearch, results, resetSearch] = useSubmittableSearch<
    UserQuery,
    UserQuery,
    PagedResults<User>
  >({
    defaultState: {
      page: 1,
      pageSize: 50,
      loginName: "",
      deleted: false,
      sort: "loginName",
    },
    searchFunction: performListUsers,
    stateSerializer: userQuerySerializer,
    stateDeserializer: userQueryDeserializer,
  });
  const triggerDebounce = useDebounce(performSearch, searchState);
  const submitSearch = (searchState: Partial<UserQuery>) => {
    updateSearchState(searchState);
    triggerDebounce();
  };

  return (
    <ResultsContainer>
      <StandardBrowsePageHeader
        type="title"
        refresh={triggerDebounce}
        additionalFilters={[
          [
            t("labelDeletedFilter"),
            createBooleanDeletedFilter({
              currentChoice: searchState.deleted,
              updateChoice: (deleted) => updateSearchState({ deleted }),
            }),
          ],
        ]}
        resetAllFilters={() => resetSearch("sort", "page")}
        title={t("title")}
      />
      <ResultsTable<User>
        noResultsInfo={ct("search.noResultsInfo")}
        items={results.result?.pageElements}
        pagination={results.result}
        columns={[
          {
            header: t("columnUsername"),
            width: { type: "weighted", value: 8 },
            renderCell: (user) => user.loginName,
            ascending: "loginName",
            descending: "loginName desc",
            filter: createTextInputFilter({
              currentValue: searchState.loginName,
              update: (newValue) => updateSearchState({ loginName: newValue }),
            }),
          },
          {
            header: t("columnCreationDate"),
            width: { type: "weighted", value: 7 },
            renderCell: (user) => user.created?.format(DateTimeFormatter.ofPattern("yyyy-MM-dd HH:mm:ss")),
            ascending: "created, id",
            descending: "created desc, id desc",
          },
          {
            header: "",
            width: { type: "static-em", value: 5 },
            textAlign: "right",
            renderCell: (user) => {
              return <Link to={`/users/${user.id}`}>{ct("browse.viewLink")}</Link>;
            },
          },
        ]}
        renderFooter={() => (
          <Button icon labelPosition="left" floated="right" primary as={Link} to="/users/create">
            <Icon name="add" />
            {t("createNew")}
          </Button>
        )}
        onSort={(newSort) => submitSearch({ sort: newSort })}
        currentSort={searchState.sort ?? ""}
        onPageChanged={(newActivePage) => submitSearch({ page: newActivePage })}
      />
    </ResultsContainer>
  );
};

export default BrowseUsersScreen;
