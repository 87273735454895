import { useParams } from "react-router-dom";
import { getRequestEventById } from "../../../../api/services/requestEvents";
import { toNumber } from "lodash";
import { useTranslation } from "react-i18next";
import React from "react";
import {
  Header,
  HttpBody,
  proxyDurationToString,
  RequestEvent,
  serverDurationToString,
} from "../../../../api/model/RequestEvent";
import { Checkbox, List, Menu, Segment, Tab } from "semantic-ui-react";
import { DateTimeFormatter, LocalDateTime } from "@js-joda/core";
import styles from "./ViewRequestEventScreen.module.css";
import { useResult } from "../../../../ts-commons/commons-react-hooks/request/useResult";
import { Title } from "../../../../ts-commons/commons-react-components/title/Title";
import { LabeledValue } from "../../../../ts-commons/commons-react-components/label/LabeledValue";
import { CopyButton } from "../../../../ts-commons/commons-react-components/copy-button/CopyButton";
import { SimpleCard } from "../../../../ts-commons/commons-react-components/card/SimpleCard";
import { Flex } from "../../../../ts-commons/commons-react-components/flex/Flex";
import { ResponsiveContainer } from "../../../../ts-commons/commons-react-components/conditional-container/ResponsiveContainer";
import { TextDisplay } from "../../../../ts-commons/commons-react-components/text/TextDisplay";

const ViewRequestEventScreen = () => {
  const { requestEventId } = useParams();
  const [eventResult] = useResult(getRequestEventById, { id: toNumber(requestEventId) });
  const { t } = useTranslation("translations", { keyPrefix: "requestEvents" });

  return (
    <ResponsiveContainer gap={2}>
      <Title
        marginBottom={0}
        loading={eventResult.status === "fetching"}
        icon="exchange"
        title={t("view.title", {
          httpMethod: eventResult.status === "ready" ? eventResult.value.httpMethod : "",
          path: eventResult.status === "ready" ? urlWithRequestParameters(eventResult.value) : "",
        })}
      />

      {eventResult.status === "ready" ? (
        <React.Fragment>
          <SimpleCard title={t("view.basicInfoTitle")}>
            <LabeledValue
              label={t("view.proxyTarget")}
              value={eventResult.value.proxyTarget.name ?? ""}
              linkTo={`/targets/${eventResult.value.proxyTarget.id}`}
            />
            <LabeledValue label={t("view.created")} value={formatDateTime(eventResult.value.created)} />
            <LabeledValue label={t("view.requestSent")} value={formatDateTime(eventResult.value.requestSent)} />
            <LabeledValue label={t("view.completed")} value={formatDateTime(eventResult.value.completed)} />
            <LabeledValue label={t("view.serverDuration")} value={serverDurationToString(eventResult.value)} />
            <LabeledValue label={t("view.proxyDuration")} value={proxyDurationToString(eventResult.value)} />
            <LabeledValue label={t("view.status")} value={t("requestState." + eventResult.value.status)} />
            <LabeledValue label={t("view.username")} value={eventResult.value.username ?? ""} />
          </SimpleCard>

          <SimpleCard title={t("view.requestTitle")}>
            <div className={styles.segmentContent}>
              <HttpHeaders
                headers={eventResult.value.requestHeaders}
                originalTabName={t("view.originalRequestHeaders")}
                proxiedTabName={t("view.proxiedRequestHeaders")}
              />
              <Flex column gap={1}>
                <Flex alignItems="center">
                  <TextDisplay value={t("view.requestQueryParams")} size="large" emphasized />
                  <CopyButton
                    size="small"
                    dataToCopy={urlWithRequestParameters(eventResult.value)}
                    afterCopyLabel={t("view.requestQueryParamsCopied")}
                    tooltip={t("view.copyPathAndQueryParameters")}
                  />
                </Flex>
                <List className={styles.queryParameterList}>
                  {eventResult.value.requestQueryParams
                    ? Object.keys(eventResult.value.requestQueryParams).map((name) => {
                        return (
                          <List.Item>
                            <List.Header>{name}</List.Header>
                            <List.Content>{eventResult.value.requestQueryParams[name]}</List.Content>
                          </List.Item>
                        );
                      })
                    : t("view.noRequestQueryParams")}
                </List>
              </Flex>
              <HttpBodyArea
                body={eventResult.value.requestBody}
                title={t("view.requestBody")}
                copyButtonLabel={t("view.copyRequestBody")}
              />
            </div>
          </SimpleCard>

          <SimpleCard title={t("view.responseTitle")}>
            <div className={styles.segmentContent}>
              <LabeledValue
                label={t("view.responseHttpStatus")}
                value={eventResult.value.responseHttpStatus?.toString() ?? ""}
              />
              <HttpHeaders
                headers={eventResult.value.responseHeaders ? eventResult.value.responseHeaders : []}
                originalTabName={t("view.originalResponseHeaders")}
                proxiedTabName={t("view.proxiedResponseHeaders")}
              />
              <HttpBodyArea
                body={eventResult.value.responseBody}
                title={t("view.responseBody")}
                copyButtonLabel={t("view.copyResponseBody")}
              />
            </div>
          </SimpleCard>
        </React.Fragment>
      ) : (
        ""
      )}
    </ResponsiveContainer>
  );
};

const sortHeadersArray = (headers: Header[]) => {
  return headers.sort((n1, n2) => {
    if (n1.key > n2.key) {
      return 1;
    }
    if (n1.key < n2.key) {
      return -1;
    }
    return 0;
  });
};

interface TabPaneProps {
  headers: { [name: string]: string };
  headersKeysArray: string[];
  sensitiveHeadersKeysArray?: string[];
}
const TabPane = ({ headers, headersKeysArray, sensitiveHeadersKeysArray }: TabPaneProps) => {
  return (
    <Tab.Pane>
      <List className={styles.headerList}>
        {headersKeysArray.map((headerKey) => (
          <List.Item>
            <List.Header className={sensitiveHeadersKeysArray?.includes(headerKey) ? styles.headerName : ""}>
              {headerKey}
            </List.Header>
            <List.Content>{headers[headerKey]}</List.Content>
          </List.Item>
        ))}
      </List>
    </Tab.Pane>
  );
};

interface HttpHeadersProps {
  headers: Header[];
  originalTabName: string;
  proxiedTabName: string;
}

const HttpHeaders = ({ headers, originalTabName, proxiedTabName }: HttpHeadersProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "requestEvents" });
  const sortedHeaders = sortHeadersArray(headers);

  const originalHeadersKeysArray: string[] = [];
  const proxiedHeadersKeysArray: string[] = [];
  const sensitiveHeadersKeysArray: string[] = [];
  const originalHeaders: { [name: string]: string } = {};
  const proxiedHeaders: { [name: string]: string } = {};

  sortedHeaders.forEach((header) => {
    const originalValue = header.original;
    const proxiedValue = header.proxied;
    if (originalValue.type === "normal") {
      if (originalValue.values.length > 0) {
        originalHeadersKeysArray.push(header.key);
        originalHeaders[header.key] = originalValue.values.join("\n");
      }
    } else if (originalValue.type === "sensitive") {
      sensitiveHeadersKeysArray.push(header.key);
      originalHeadersKeysArray.push(header.key);
      originalHeaders[header.key] = t("view.contentNotStored");
    }

    if (proxiedValue.type === "normal") {
      if (proxiedValue.values.length > 0) {
        proxiedHeadersKeysArray.push(header.key);
        proxiedHeaders[header.key] = proxiedValue.values.join("\n");
      }
    } else if (proxiedValue.type === "sensitive") {
      proxiedHeadersKeysArray.push(header.key);
      proxiedHeaders[header.key] = t("view.contentNotStored");
    }
  });

  const originalHeadersAsString = originalHeadersKeysArray
    .map((headerKey) => {
      return `${headerKey}: ${originalHeaders[headerKey]}`;
    })
    .join("\n");
  const proxiedHeadersAsString = proxiedHeadersKeysArray
    .map((headerKey) => {
      return `${headerKey}: ${proxiedHeaders[headerKey]}`;
    })
    .join("\n");

  const panes = [
    {
      menuItem: (
        <Menu.Item className={styles.headerTabTitle} key="tab_1">
          <Flex alignItems="center" height={6}>
            <TextDisplay value={originalTabName} />
            <CopyButton dataToCopy={originalHeadersAsString} size="small" tooltip={t("view.copyRequestHeaders")} />
          </Flex>
        </Menu.Item>
      ),
      render: () => (
        <TabPane
          headers={originalHeaders}
          headersKeysArray={originalHeadersKeysArray}
          sensitiveHeadersKeysArray={sensitiveHeadersKeysArray}
        />
      ),
    },
    {
      menuItem: (
        <Menu.Item className={styles.headerTabTitle} key="tab_2">
          <Flex alignItems="center" height={6}>
            <TextDisplay value={proxiedTabName} />
            <CopyButton dataToCopy={proxiedHeadersAsString} tooltip={t("view.copyRequestHeaders")} />
          </Flex>
        </Menu.Item>
      ),
      render: () => (
        <TabPane
          headers={proxiedHeaders}
          headersKeysArray={proxiedHeadersKeysArray}
          sensitiveHeadersKeysArray={sensitiveHeadersKeysArray}
        />
      ),
    },
  ];

  return <Tab panes={panes} />;
};

interface HttpBodyProps {
  body?: HttpBody;
  title: string;
  copyButtonLabel: string;
}

const HttpBodyArea = ({ body, title, copyButtonLabel }: HttpBodyProps) => {
  const { t } = useTranslation("translations", { keyPrefix: "requestEvents" });
  const [isFormatted, setFormatted] = React.useState(false);

  let displayData: string | undefined = undefined;
  let formattedData: string | undefined = undefined;
  let canBeFormatted = false;

  if (body?.type === "text") {
    displayData = body.value;
    try {
      formattedData = JSON.stringify(JSON.parse(displayData), null, 2);
      canBeFormatted = true;
    } catch (_) {
      // This is fine, it just means that the response maybe just isn't valid JSON so we cannot format it
    }
  } else if (body?.type === "binary") {
    displayData = body.base64Value;
  } else if (body?.type === "hash") {
    displayData = `${body.algorithm}: ${body.hash}`;
  }

  let displayType;
  switch (body?.type) {
    case "text":
      displayType = t("view.bodyType.text");
      break;
    case "binary":
      displayType = t("view.bodyType.binary");
      break;
    case "hash":
      displayType = t("view.bodyType.hash");
      break;
    case "hidden":
      displayType = t("view.bodyType.hidden");
      break;
    case undefined:
      displayType = t("view.bodyType.none");
      break;
    default:
      displayType = "???";
  }

  // If the body changes, then reset the formatted state to the default value
  React.useEffect(() => {
    setFormatted(canBeFormatted);
  }, [body, canBeFormatted]);

  if (isFormatted) {
    displayData = formattedData;
  }

  const BodyDisplay = () => {
    if (body === undefined) {
      return <div>{t("view.noBodyInfo")}</div>;
    } else if (body.type === "hidden") {
      return <div>{t("view.bodyHiddenInfo")}</div>;
    } else {
      return (
        <Segment as="pre" className={styles.httpBody}>
          {
            // Don't display the body if it has 1 MB of characters or more...
            (displayData?.length ?? 0) < 1024 * 1024 ? displayData : t("view.veryLongContent")
          }
        </Segment>
      );
    }
  };

  return (
    <Flex column>
      <Flex alignItems="center">
        <TextDisplay value={`${title} (${displayType})`} emphasized size="large" />
        <CopyButton tooltip={copyButtonLabel} dataToCopy={displayData ?? ""} afterCopyLabel={t("view.bodyCopied")} />
      </Flex>
      {canBeFormatted ? (
        <Checkbox
          checked={isFormatted}
          onChange={(_, data) => setFormatted(canBeFormatted && data.checked === true)}
          disabled={!canBeFormatted}
          className={styles.httpBodyFormatCheckbox}
          label={t("view.bodyFormattedLabel")}
        />
      ) : (
        ""
      )}
      <div className={styles.httpBodyContent}>
        <BodyDisplay />
      </div>
    </Flex>
  );
};

const urlWithRequestParameters = (event: RequestEvent) => {
  let queryString: string;
  if (event.requestQueryParams) {
    const searchParams = new URLSearchParams();
    Object.keys(event.requestQueryParams).forEach((key) => {
      event.requestQueryParams[key].forEach((element) => {
        searchParams.append(key, element);
      });
    });
    queryString = "&" + searchParams.toString();
  } else {
    queryString = "";
  }
  return event.path + queryString;
};

const formatDateTime = (date: LocalDateTime | undefined): string => {
  return date?.format(DateTimeFormatter.ofPattern("yyyy-MM-dd H:mm:ss.SSS")) ?? "";
};

export default ViewRequestEventScreen;
