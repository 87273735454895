import React, { useContext } from "react";
import { FormContext, useRelevantErrors } from "./TypedForm";
import styles from "./FormInput.module.css";
import { Icon } from "semantic-ui-react";
import { HelpText } from "../help/HelpText";
import { Flex } from "../flex/Flex";

export interface FormTextAreaProps<Fields, Error> {
  formField: keyof Fields & string;
  label: string;
  disabled?: boolean;
  relatedErrors: Error[];
  rows: number;
  columns?: number;
  helpText?: string;
}

/**
 * This component can be used to add a multiline textarea to a typed form. The following properties control the behavior
 * of this component:
 * - formField: the field of the form's state that this textarea uses, i.e. which member of the form state should be
 *              displayed in the field and modified when a user interacts with this textarea.
 * - label: a label to display on top of the field, to inform the user what the use for this textarea is.
 * - disabled: whether the textarea should be disabled, that is non-interactable for the user.
 * - relatedErrors: which errors of the form state relate to this particular field. If the form state contains one of
 *                  the errors listed here, the input will be highlighted with a warning color and the user-friendly
 *                  error text of the specific error will be displayed next to the field. The input field has been
 *                  implemented so that its size should not grow when errors become visible, but this may cause some
 *                  errors to not show up if many of them are displayed at once.
 * - rows: the number of rows displayed in this textarea. This must be given.
 * - columns: the number of columns used in this textarea. If this is not given, the textarea will use all horizontal
 *            space.
 *  - helpText: if this is given, display a help icon next to this field's label. Hovering over the icon will display
 *              this text as a popup.
 * @param props
 * @constructor
 */
export const FormTextArea = <Fields, Error>(props: FormTextAreaProps<Fields, Error>) => {
  const formContext = useContext(FormContext);
  const relevantErrors = useRelevantErrors(props.relatedErrors);

  const resolveValue = (): string => {
    if (formContext.state) {
      const fieldValue = formContext.state[props.formField];
      if (fieldValue === undefined || fieldValue === null) {
        return "";
      } else {
        return "" + fieldValue;
      }
    } else {
      return "";
    }
  };

  const resolveInputClass = (): string => {
    if (formContext.mode === "view") {
      return styles.viewMode;
    } else if (relevantErrors.length > 0) {
      return styles.hasError;
    } else {
      return "";
    }
  };

  return (
    <Flex column alignItems="baseline">
      <div className={styles.labelWrapper}>
        <label className={styles.label}>{props.label}</label>
        {props.helpText ? <HelpText text={props.helpText} /> : ""}
        {relevantErrors.map(([_, errorMessage]) => (
          <div className={styles.formError}>
            <Icon className={"exclamation circle"} />
            {errorMessage}
          </div>
        ))}
      </div>
      <textarea
        className={resolveInputClass()}
        name={props.formField}
        readOnly={props.disabled ? props.disabled : formContext.mode !== "edit"}
        disabled={formContext.mode === "submit"}
        onChange={(event) => formContext.onInputChange(props.formField, event.target.value)}
        rows={props.rows}
        cols={props.columns}
        value={resolveValue()}
      ></textarea>
    </Flex>
  );
};
