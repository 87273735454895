import { Dropdown as SemanticDropdown, Form, Icon, Input as SemanticInput, Menu } from "semantic-ui-react";
import { ReactNode } from "react";
import { LocalDateTime } from "@js-joda/core";
import { Button } from "../button/Button";
import { DateTimeInput } from "../input/DateTimeInput";
import styles from "./tableFilters.module.css";
import { CommonsTranslateFunction } from "../message-context/MessageContext";
import { Flex } from "../flex/Flex";
import { TextDisplay } from "../text/TextDisplay";
import { Size } from "../../commons-ts-utils/utils/sizing";
import { max, min } from "lodash";
import { Input } from "../input/Input";
import { Dropdown } from "../dropdown/Dropdown";

/**
 * Defines a filter for a results table/browse screen. For most common cases, it is recommended to use the
 * create*-functions in this file to instantiate filters.
 */
export interface FilterInfo {
  /**
   * The render-function for when the filter is being shown. Typically a filter needs to be toggled by the user by
   * clicking a button, after which a popup appears. The intention is that the popup contains whatever is being
   * rendered by this function, although the actual implementation may vary.
   * @param t An instance of the [CommonsTranslateFunction], used by some filters to render user-visible values into
   *          labels.
   * @param close A function that can be invoked to close the popup where the filter is displayed. If the filter is not
   *              being displayed in a closeable element, or the close-controls should not be rendered next to the
   *              filter, then this value should be passed as undefined.
   */
  renderSelection: (t: CommonsTranslateFunction, close?: () => void) => ReactNode;
  /**
   * A property which should be true if and only if this filter's state contains active values. Generally speaking, a
   * filter is active if it produces a reduced result-set from the theoretical maximum. However, some filters may always
   * reduce the result-set in some way. In these cases, the filter is considered active if its filter differs from the
   * default selection.
   */
  hasActiveFilters: boolean;
}

export interface MultiChoiceFilterArgs<T> {
  options: T[];
  currentChoices: T[] | undefined;
  choiceToLabel: (choice: T) => string;
  updateChoices: (newChoices: T[] | undefined) => void;
}

export interface SingleChoiceFilterArgs<T> {
  /**
   * The type of selection widget that is uses. Defaults to "list", which is better for a small amount of items. Large
   * amounts of items should probably use "dropdown".
   */
  type?: "list" | "dropdown";
  options: T[];
  currentChoice: T;
  defaultChoice: T;
  choiceToLabel: (choice: T) => string;
  updateChoice: (newChoice: T) => void;
}

export interface BooleanDeletedFilterArgs {
  currentChoice: boolean;
  updateChoice: (newChoice: boolean) => void;
}

export interface DateTimeRangeFilterArgs {
  start?: LocalDateTime;
  end?: LocalDateTime;
  update: (start: LocalDateTime | undefined, end: LocalDateTime | undefined) => void;
}

export interface TagValueFilterArgs {
  label: string;
  selectableTags: { tag: string; label: string }[];
  currentValues: { tag: string; value: string }[];
  update: (selected: { tag: string; value: string }[]) => void;
  width?: Size;
}

export interface TextInputFilterArgs {
  label?: string;
  currentValue: string | undefined;
  update: (newValue: string) => void;
}

const ResetFilterButton = ({
  performUpdate,
  hasChanges,
  close,
}: {
  performUpdate: () => void;
  hasChanges: boolean;
  close: (() => void) | undefined;
}) => {
  return (
    <Button
      onClick={() => {
        performUpdate();
        if (close) {
          close();
        }
      }}
      icon="eraser"
      negative={close !== undefined}
      basic={close === undefined}
      compact
      size="tiny"
      disabled={!hasChanges}
    />
  );
};

/**
 * A utility function that can be used to instantiate a multi-choice filter, that is a list where 0-n values can be
 * selected.
 * @param options The available choices for the filter, in order of being displayed.
 * @param currentChoices The currently selected choices.
 * @param choiceToLabel A mapping function from a choice into a user-visible string.
 * @param updateChoices A function to update the selection-state based on user-interactions with the rendered controls.
 */
export const createMultiChoiceFilter = <T,>({
  options,
  currentChoices,
  choiceToLabel,
  updateChoices,
}: MultiChoiceFilterArgs<T>): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <Flex column minWidth={min([80, max(options.map((option) => choiceToLabel(option).length + 8))])}>
        <div className={styles.filterLabelWrapper}>
          <p className={styles.topLabel}>{t("tableFilters.labelMultiChoice")}</p>
          <ResetFilterButton
            close={close}
            performUpdate={() => updateChoices([])}
            hasChanges={currentChoices !== undefined && currentChoices.length > 0}
          />
        </div>
        <Menu vertical fluid className={styles.noMargin}>
          {options.map((option) => {
            const isActive = currentChoices && currentChoices.includes(option);
            const choiceIndex = currentChoices?.indexOf(option) ?? 0;

            return (
              <Menu.Item
                key={`${option}`}
                onClick={() => {
                  if (!isActive) {
                    const current = currentChoices ?? [];
                    updateChoices(current.concat(option));
                  } else {
                    updateChoices(currentChoices?.slice(0, choiceIndex).concat(currentChoices?.slice(choiceIndex + 1)));
                  }
                }}
              >
                {isActive ? <Icon name="check" /> : undefined}
                {choiceToLabel(option)}
              </Menu.Item>
            );
          })}
        </Menu>
      </Flex>
    ),
    hasActiveFilters: currentChoices !== undefined && currentChoices.length > 0,
  };
};

/**
 * A utility function that can be used to create a single-choice filter, that is a list of 1-n values where exactly one
 * value can be selected at a time. This filter is considered active if the selection differs from the default choice.
 * Functions otherwise similarly to {@link createMultiChoiceFilter}.
 */
export const createSingleChoiceFilter = <T,>({
  type,
  options,
  defaultChoice,
  currentChoice,
  choiceToLabel,
  updateChoice,
}: SingleChoiceFilterArgs<T>): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <>
        <div className={styles.filterLabelWrapper}>
          <p className={styles.topLabel}>{t("tableFilters.labelSingleChoice")}</p>
          <ResetFilterButton
            close={close}
            performUpdate={() => updateChoice(defaultChoice)}
            hasChanges={currentChoice !== defaultChoice}
          />
        </div>
        {type === "dropdown" ? (
          <Flex alignItems="stretch" minWidth={{ unit: "em", value: 20 }} grow>
            <Dropdown
              grow
              selection={{ key: options.indexOf(currentChoice), text: choiceToLabel(currentChoice) }}
              onChange={(newValue) => {
                if (newValue !== undefined) {
                  updateChoice(options[newValue.key]);
                }
              }}
              options={options.map((option, index) => {
                return {
                  key: index,
                  text: choiceToLabel(option),
                };
              })}
            />
          </Flex>
        ) : (
          <Menu vertical>
            {options.map((option) => {
              const isActive = currentChoice === option;

              return (
                <Menu.Item key={`${option}`} disabled={isActive} onClick={() => updateChoice(option)}>
                  {isActive ? <Icon name="check" /> : undefined}
                  {choiceToLabel(option)}
                </Menu.Item>
              );
            })}
          </Menu>
        )}
      </>
    ),
    hasActiveFilters: currentChoice !== defaultChoice,
  };
};

/**
 * A utility function to create a filter for the common "search deleted" selection for many entity-search forms. The
 * filter is only a yes-no choice, with the no-choice corresponding to "show only non-deleted entities", and the
 * yes-choice corresponding to "show only deleted entities". The filter is not considered active when in its default
 * mode, i.e. "show only non-deleted entities".
 */
export const createBooleanDeletedFilter = ({ currentChoice, updateChoice }: BooleanDeletedFilterArgs): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <>
        <div className={styles.filterLabelWrapper}>
          <p className={styles.topLabel}>{t("tableFilters.booleanDeletedChoice.label")}</p>
          <ResetFilterButton close={close} performUpdate={() => updateChoice(false)} hasChanges={currentChoice} />
        </div>
        <Menu vertical>
          <Menu.Item key="non-deleted" disabled={!currentChoice} onClick={() => updateChoice(false)}>
            {!currentChoice ? <Icon name="check" /> : undefined}
            {t("tableFilters.booleanDeletedChoice.nonDeletedChoice")}
          </Menu.Item>
          <Menu.Item key="deleted" disabled={currentChoice} onClick={() => updateChoice(true)}>
            {currentChoice ? <Icon name="check" /> : undefined}
            {t("tableFilters.booleanDeletedChoice.deletedChoice")}
          </Menu.Item>
        </Menu>
      </>
    ),
    hasActiveFilters: currentChoice,
  };
};

/**
 * A utility function that can be used to create a datetime-range filter.
 * @param start The currently selected start-point for the filter
 * @param end The currently selected end-point for the filter.
 * @param update A function that the filter can invoke to update the start & end values based on user interactions made
 *               in the rendered controls.
 */
export const createDateTimeRangeFilter = ({ start, end, update }: DateTimeRangeFilterArgs): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <Form>
        <div className={styles.filterLabelWrapper}>
          <p className={styles.topLabel}>{t("tableFilters.labelDateTime")}</p>
          <ResetFilterButton
            close={close}
            performUpdate={() => update(undefined, undefined)}
            hasChanges={start !== undefined || end !== undefined}
          />
        </div>
        <DateTimeInput value={start} onChange={(newDateTime) => update(newDateTime, end)} emptyTimeMode="start" />
        <p className={styles.separatorLabel}>{t("tableFilters.labelDateTimeSeparator")}</p>
        <DateTimeInput value={end} onChange={(newDateTime) => update(start, newDateTime)} emptyTimeMode="end" />
      </Form>
    ),
    hasActiveFilters: start !== undefined || end !== undefined,
  };
};

/**
 * Create a filter that allows adding any number of key-value pairs as filters, where the key must be one from a
 * predetermined list, and the value can be any string inputted by the user.
 */
export const createTagValueFilter = ({
  width,
  selectableTags,
  label,
  currentValues,
  update,
}: TagValueFilterArgs): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <Flex column width={width ?? { value: 40, unit: "em" }} gap={1}>
        <Flex grow alignItems="center" gap={1} marginBottom={1}>
          <TextDisplay value={label} emphasized grow />
          <ResetFilterButton close={close} performUpdate={() => update([])} hasChanges={currentValues.length > 0} />
        </Flex>

        {currentValues.map(({ tag, value }, index) => {
          return (
            <Flex gap={1} key={index.toString()}>
              <Flex grow={1}>
                <SemanticDropdown
                  selection
                  value={tag}
                  options={selectableTags.map(({ tag, label }) => {
                    return {
                      key: tag,
                      value: tag,
                      text: label,
                    };
                  })}
                  onChange={(_, data) => {
                    update(
                      currentValues
                        .slice(0, index)
                        .concat({
                          tag: data.value?.toString() ?? "",
                          value: value,
                        })
                        .concat(currentValues.slice(index + 1))
                    );
                  }}
                  fluid
                />
              </Flex>
              <Flex grow={1}>
                <SemanticInput
                  value={value}
                  onChange={(_, data) => {
                    update(
                      currentValues
                        .slice(0, index)
                        .concat({
                          tag: tag,
                          value: data.value,
                        })
                        .concat(currentValues.slice(index + 1))
                    );
                  }}
                  fluid
                  className={styles.grow}
                />
              </Flex>
              <Button
                basic
                icon="trash"
                onClick={() => update(currentValues.slice(0, index).concat(currentValues.slice(index + 1)))}
              />
            </Flex>
          );
        })}

        <Flex gap={1} key={currentValues.length.toString()}>
          <Flex grow={1}>
            <SemanticDropdown
              value=""
              placeholder={t("tableFilters.tagValueFilter.dropdownPlaceholder")}
              selection
              options={selectableTags.map(({ tag, label }) => {
                return {
                  key: tag,
                  value: tag,
                  text: label,
                };
              })}
              selectOnBlur={false}
              onChange={(_, data) => {
                update(
                  currentValues.concat({
                    tag: data.value?.toString() ?? "",
                    value: "",
                  })
                );
              }}
              fluid
            />
          </Flex>
          <Flex grow={1}>
            <SemanticInput value="" disabled fluid className={styles.grow} />
          </Flex>
          <Button basic disabled icon="trash" />
        </Flex>
      </Flex>
    ),
    hasActiveFilters: currentValues.length > 0,
  };
};

/**
 * Create a filter that allows filtering with a freeform text input. The user-inputted value is always passed to the
 * {@link TextInputFilterArgs.update}-function as is, without trimming or any other transformation. Even though server
 * side implementations often treat blank values the same as empty ones (meaning that they are not used in the
 * filtering), this filter will consider itself to have changes if it has a non-empty blank input.
 */
export const createTextInputFilter = ({ currentValue, label, update }: TextInputFilterArgs): FilterInfo => {
  return {
    renderSelection: (t, close) => (
      <Flex column gap={1}>
        <Flex grow alignItems="center" gap={1}>
          <TextDisplay value={label ?? t("tableFilters.textInputFilter.defaultLabel")} emphasized grow />
          <ResetFilterButton
            close={close}
            performUpdate={() => update("")}
            hasChanges={(currentValue ?? "").length > 0}
          />
        </Flex>

        <Flex grow>
          <Input
            value={currentValue ?? ""}
            onChange={(newValue) => {
              update(newValue);
            }}
            grow
          />
        </Flex>
      </Flex>
    ),
    hasActiveFilters: (currentValue ?? "").length > 0,
  };
};
