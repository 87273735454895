import React, { useState } from "react";
import { DateTimeFormatter, LocalDate, LocalDateTime, LocalTime } from "@js-joda/core";
import { Flex } from "../flex/Flex";
import { StandardComponentProps } from "../../commons-ts-utils/utils/resolveClassName";
import { TimeInput } from "./TimeInput";
import { DateInput } from "./DateInput";

export interface DateTimeFormInputProps extends StandardComponentProps {
  id?: string;
  value?: LocalDateTime;
  onChange: (newDateTime?: LocalDateTime) => void;
  emptyTimeMode: "start" | "end";
  disabled?: boolean;
  error?: boolean;
}

interface DateAndTime {
  date?: LocalDate;
  time?: LocalTime;
}

/**
 * <p>A component to input date and time. The date is inputted with a component that displays a clickable calendar. The
 * time is inputted with a text input which validates that the time is correct (it needs to be in format `H:mm`). Once
 * something is inputted the date and time is passed to the given `onChange` method as a {@link LocalDateTime}. If the
 * time is not inputted (or is not a valid time), the given `emptyTimeMode` controls whether the time is at the start
 * of a day or at the end of a day.</p>
 * <p>The fields to input date and time are returned inside a `Form.Group` component.</p>
 */
export const DateTimeInput = ({
  id,
  value,
  onChange,
  emptyTimeMode,
  disabled,
  error,
  ...standardProps
}: DateTimeFormInputProps) => {
  const formattedValue = value?.format(DateTimeFormatter.ISO_LOCAL_DATE_TIME) ?? "";

  const [state, setState] = useState({
    dateTime: {
      date: value?.toLocalDate(),
      time: value?.toLocalTime(),
    },
    propValue: formattedValue,
  });

  // The effect below will update the state of this component if the value-prop is changed from the outside. The
  // component needs its on internal state due to the value being split into a separate date and time component, but
  // without this effect it would only be possible to set the initial value and not update it afterwards. This would
  // cause issues with, for example, pages that read query parameters from the page URL: the default/set value for
  // a component can change without the form being recreated.
  React.useEffect(() => {
    if (state.propValue !== formattedValue) {
      setState({
        dateTime: {
          date: value?.toLocalDate(),
          time: value?.toLocalTime(),
        },
        propValue: formattedValue,
      });
    }
  }, [formattedValue, state.propValue, value]);

  return (
    <Flex gap={1} {...standardProps} alignItems="start">
      <DateInput
        disabled={disabled}
        error={error}
        id={id ? `${id}-datePicker` : undefined}
        value={state.dateTime.date}
        onChange={(date: LocalDate | undefined) => {
          let dateTime = {
            ...state.dateTime,
            date,
          };
          setState({
            propValue: state.propValue,
            dateTime,
          });
          onChange(toLocalDateTime(dateTime, emptyTimeMode));
        }}
      />
      <TimeInput
        disabled={disabled}
        error={error}
        id={id ? `${id}-timePicker` : undefined}
        emptyTimeMode={emptyTimeMode}
        value={state.dateTime.time}
        onChange={(time) => {
          let dateTime = {
            ...state.dateTime,
            time,
          };
          setState({
            propValue: state.propValue,
            dateTime,
          });
          onChange(toLocalDateTime(dateTime, emptyTimeMode));
        }}
      />
    </Flex>
  );
};

const toLocalDateTime = (dateAndTime: DateAndTime, emptyTimeMode: "start" | "end"): LocalDateTime | undefined => {
  if (dateAndTime.date === undefined) {
    return undefined;
  }
  let time: LocalTime;
  if (dateAndTime.time !== undefined) {
    time = dateAndTime.time;
  } else if (emptyTimeMode === "start") {
    time = LocalTime.of(0, 0, 0, 0);
  } else {
    time = LocalTime.of(23, 59, 59, 999999999);
  }
  return LocalDateTime.of(dateAndTime.date, time);
};
