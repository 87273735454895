import { TranslateFunction } from "../i18n/config";
import { PrimaryAction, SecondaryAction } from "../ts-commons/commons-react-components/title/Title";
import { FormMode } from "../ts-commons/commons-react-components/form/TypedForm";

export const createViewTitlePrimaryActions = (
  commonTranslate: TranslateFunction,
  onEdit: () => void
): PrimaryAction[] => {
  return [
    {
      label: commonTranslate("title.editButton"),
      icon: "edit",
      type: "button",
      onClick: onEdit,
    },
  ];
};

export const createEditTitlePrimaryActions = (
  commonTranslate: TranslateFunction,
  formMode: FormMode,
  onSave: () => void,
  onCancel: () => void
): PrimaryAction[] => {
  return [
    {
      label: commonTranslate("title.saveButton"),
      icon: "save",
      type: "button",
      buttonTypes: ["primary", "basic"],
      onClick: onSave,
      submitting: formMode === "submit",
      disabled: formMode === "submit",
    },
    {
      label: commonTranslate("title.cancelButton"),
      icon: "undo",
      type: "button",
      onClick: onCancel,
      disabled: formMode === "submit",
    },
  ];
};

export const createViewTitleSecondaryActions = (
  commonTranslate: TranslateFunction,
  onDelete: () => void,
  onUndelete: () => void,
  entityIsDeleted: Boolean
): SecondaryAction[] => {
  const icon = entityIsDeleted ? "undo" : "trash alternate";
  const onClick = entityIsDeleted ? onUndelete : onDelete;
  return [
    {
      label: commonTranslate(entityIsDeleted ? "title.undeleteButton" : "title.deleteButton"),
      icon: icon,
      type: "button",
      onClick: onClick,
    },
  ];
};
